import { useStyles } from '../../hooks/useStyles';
import { Breakpoint } from '../../theme/customTheme';
import { FlexibleBox, GenericComponent } from '../../types/components';

type Props = {
  inversed?: boolean;
  breakAt?: Breakpoint;
};

export function FlexResponsive({
  children,
  centered,
  gap,
  classNames,
  breakAt = 'large',
  inversed = false,
}: GenericComponent & Omit<FlexibleBox, 'padding'> & Props) {
  const { css, classes, theme, concatenateClasses } = useStyles();
  if (gap === undefined) gap = theme.sizing.scale600;
  const styles = css({
    gap,
    display: 'flex',
    flexDirection: inversed ? 'row' : 'column',
    width: '100%',
    [theme.mediaQuery[breakAt]]: {
      flexDirection: inversed ? 'column' : 'row',
    },
  });
  return (
    <div className={concatenateClasses(centered ? classes.centeredFlex : '', styles, classNames ?? '')}>{children}</div>
  );
}
