import { useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { useStyles } from '../../hooks/useStyles';
import { postAsset, updateAsset } from '../../services/assetService';
import { Asset, AssetMinimal } from '../../types/asset';
import { Container } from '../../types/container';
import { Vehicle } from '../../types/vehicle';
import { FlexColumn } from '../ui/FlexColumn';
import { FlexRow } from '../ui/FlexRow';
import { StyledButton } from '../ui/StyledButton';
import { AssetFormSteps } from './AssetFormSteps';

type Props = {
  asset?: Asset;
  onCancel: () => void;
  afterSave: () => void;
  clientId?: string;
  subClientId?: string;
};
export function AssetForm({ asset, onCancel, afterSave, clientId, subClientId }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [editingAsset, setEditingAsset] = useState<AssetMinimal>({
    _id: '',
    label: '',
    assetType: 'vehicle',
    deleted: false,
    monitoring: true,
  });
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const requireAssetData = editingAsset?.assetType === 'container' || editingAsset?.assetType === 'vehicle';
  const totalSteps = requireAssetData ? 2 : 1;
  const [currentStep, setCurrentStep] = useState(0);
  const isLastStep = currentStep + 1 === totalSteps;

  const hasUpdates = !areEqual(asset ?? {}, editingAsset);

  const validateVehicle = (vehicle?: Vehicle) => {
    if (!vehicle) return false;
    if (
      !vehicle.brand?._id ||
      !vehicle.subBrand?._id ||
      !vehicle.year ||
      !vehicle.color ||
      !vehicle.licensePlate ||
      !vehicle.vehicleType ||
      !vehicle.vin
    )
      return false;
    return true;
  };

  const validateContainer = (container?: Container) => {
    if (!container) return false;
    if (!container.identifier) return false;
    return true;
  };

  const canContinue = !!editingAsset?.label && !!editingAsset?.client?._id && !!editingAsset?.subClient?._id;

  const canSubmit = useMemo(() => {
    let errors = 0;
    if (!canContinue) errors += 1;
    if (editingAsset?.assetType === 'vehicle') errors += validateVehicle(editingAsset?.vehicle) ? 0 : 1;
    else if (editingAsset?.assetType === 'container') errors += validateContainer(editingAsset?.container) ? 0 : 1;
    return errors === 0;
  }, [editingAsset]);

  const onSave = async () => {
    startLoading();
    const { id, error } = asset ? await updateAsset(editingAsset) : await postAsset(editingAsset);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        asset ? FEEDBACK.edited(FEEDBACK_PREFIXES.asset, editingAsset.label) : FEEDBACK.created(FEEDBACK_PREFIXES.asset)
      );
    } else {
      showFailFeedback(
        error || asset
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.asset, editingAsset.label)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.asset)
      );
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          height: '100%',
        })}
      >
        <AssetFormSteps
          currentStep={currentStep}
          asset={asset}
          clientId={clientId}
          subClientId={subClientId}
          onAssetChange={setEditingAsset}
        />
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          {currentStep > 0 && (
            <StyledButton
              kind="tertiary"
              onClick={() => setCurrentStep((prev) => prev - 1)}
            >
              Regresar
            </StyledButton>
          )}
          {asset?._id && (
            <StyledButton
              kind="tertiary"
              onClick={onCancel}
            >
              Cancelar
            </StyledButton>
          )}
          {!isLastStep && (
            <StyledButton
              onClick={() => setCurrentStep((prev) => prev + 1)}
              disabled={!canContinue}
            >
              Siguiente
            </StyledButton>
          )}
          {isLastStep && (
            <StyledButton
              onClick={onSave}
              isLoading={loading}
              disabled={!canSubmit || !hasUpdates}
            >
              Guardar
            </StyledButton>
          )}
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
