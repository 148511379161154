import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { VERSIONS_TABLE_COLUMNS_NAME } from '../../constants/version';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import { getVersionsList } from '../../services/vehicleService';
import { Version } from '../../types/version';
import { ListWrapper } from '../shared/ListWrapper';
import { EllipsedText } from '../ui/EllipsedText';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledTooltip } from '../ui/StyledTooltip';
import VersionDetailModal from './modal/VersionDetailModal';
import useUpdateEffect from '../../hooks/useUpdateEffect';

type Props = {
  search?: string;
  reloadPage?: boolean;
  brandId?: string;
  subBrandId?: string;
};
export function VersionsList({ search, reloadPage, brandId, subBrandId }: Readonly<Props>) {
  const [versions, setVersions] = useState<Version[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { openModal, closeModal, showModal } = useModal();
  const [selectedVersion, setSelectedVersion] = useState<Version>();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search, brandId, subBrandId]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadVersions = async () => {
      const { versions, error, total } = await getVersionsList(page, search, brandId, subBrandId);
      if (!error && versions) {
        setVersions(versions);
        setTotalItems(total);
      } else {
        setVersions(undefined);
        setTotalItems(0);
      }
      stopLoading();
    };
    loadVersions();
  }, [page]);

  const openDetailModal = (version: Version) => {
    setSelectedVersion(version);
    openModal();
  };

  const closeDetailModal = (updateTable?: boolean) => {
    setSelectedVersion(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={VERSIONS_TABLE_COLUMNS_NAME}
        data={versions?.map((version, i) => {
          return {
            items: [
              <EllipsedText key={`version-name-${i}`}>{version.name}</EllipsedText>,
              <EllipsedText key={`version-brand-${i}`}>{version.brand.name}</EllipsedText>,
              <EllipsedText key={`version-subBrand-${i}`}>{version.subBrand.name}</EllipsedText>,
              <StyledTooltip
                key={`details-${version._id}`}
                content={'Ver detalle'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => openDetailModal(version)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>,
            ],
          };
        })}
        hasData={versions !== undefined}
        isEmpty={!versions?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ninguna version"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && <VersionDetailModal
        onClose={(updateTable) => closeDetailModal(updateTable)}
        isOpen={showModal}
        selectedVersion={selectedVersion}
      />}
    </>
  );
}
