import { useEffect, useState } from 'react';
import { ContentContainer } from '../../ui/ContentContainer';
import { ClientUserFiltersBar } from './users/ClientUserFiltersBar';
import { ClientUserList } from './users/ClientUserList';
import LabeledData from '../../ui/LabeledData';
import { getClient } from '../../../services/clientService';
import { Client } from '../../../types/client';
import { useLoading } from '../../../hooks/useLoading';
import { FlexRow } from '../../ui/FlexRow';
import { ClientDetail } from './ClientDetail';
import { FlexColumn } from '../../ui/FlexColumn';
import { Loading } from '../../ui/Loading';
import { useStyles } from '../../../hooks/useStyles';
import { ClientForm } from './ClientForm';

type Props = {
  clientId: string;
};
export function ClientTab({ clientId }: Readonly<Props>) {
  const { css } = useStyles();
  const [reloadPage, setReloadPage] = useState(false);
  const { loading, startLoading, stopLoading } = useLoading();
  const [client, setClient] = useState<Client>();
  const [ editMode, setEditMode] = useState(false);

  const loadClientData = async () => {
    startLoading();
    const response = await getClient(clientId);
    if (response?.error) {
      setClient(undefined);
      // TODO
    } else {
      setClient(response.client);
    }
    stopLoading();
  };

  useEffect(() => {
    if (!clientId) return;
    loadClientData();
  }, [clientId, reloadPage]);


  return (
    <ContentContainer>
      {loading && 
      <FlexColumn centered classNames={css({
        height: "400px",
        width: "400px"
      })}>
        <Loading />
      </FlexColumn>}
      {client && !loading && !editMode && 
        <ClientDetail client={client} changeMode={() => setEditMode(!editMode)} />
      }
      {editMode && 
        <FlexColumn classNames={css({
          height: '100%',
          width: '100%',
          maxWidth: '900px',
          borderRight: '1px solid black',
          padding: '1em',
          boxSizing: 'border-box'
        })}>
          <ClientForm 
            client={client}
            onCancel={() => setEditMode(false)} 
            afterSave={() => {
              setEditMode(false)
              setReloadPage(!reloadPage)
            }}
          />
        </FlexColumn>
      }
    </ContentContainer>
  );
}
