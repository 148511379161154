import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { memo } from 'react';
import { useStyles } from '../../hooks/useStyles';
import { Vehicle } from '../../types/vehicle';
import { ColoredCircle } from '../ui/ColoredCircle';
import { FlexColumn } from '../ui/FlexColumn';
import LabeledData from '../ui/LabeledData';

type Props = {
  vehicle: Vehicle;
};

function AssetVehicleData({ vehicle }: Props) {
  const { theme, classes, css } = useStyles();

  return (
    <FlexColumn gap={theme.sizing.scale600}>
      <LabelMedium className={classes.dataHeaderClass}>Datos del vehículo</LabelMedium>
      <FlexColumn
        gap={theme.sizing.scale300}
        padding={'0px 0px 0px 2rem'}
      >
        {vehicle.licensePlate && <LabeledData
          label={<LabelMedium minWidth={'110px'}>{"Placa:"}</LabelMedium>}
          content={vehicle.licensePlate}
        />}
        {vehicle.vehicleType && <LabeledData
          label={<LabelMedium minWidth={'110px'}>{"Tipo:"}</LabelMedium>}
          content={vehicle.vehicleType.charAt(0).toUpperCase() + vehicle.vehicleType.slice(1)}
        />}
        {vehicle.brand.name && <LabeledData
          label={<LabelMedium minWidth={'110px'}>{"Marca:"}</LabelMedium>}
          content={vehicle.brand.name}
        />}
        {vehicle.subBrand?.name &&<LabeledData
          label={<LabelMedium minWidth={'110px'}>{"Submarca:"}</LabelMedium>}
          content={vehicle.subBrand?.name}
        />}
        {vehicle.version?.name && <LabeledData
          label={<LabelMedium minWidth={'110px'}>{"Version:"}</LabelMedium>}
          content={vehicle.version?.name}
        />}
        {!!vehicle.year && <LabeledData
          label={<LabelMedium minWidth={'110px'}>{"Modelo:"}</LabelMedium>}
          content={vehicle.year?.toString()}
        />}
        {vehicle.color && <LabeledData
          label={<LabelMedium minWidth={'110px'}>{"Color:"}</LabelMedium>}
          verticalAlign="center"
          content={
            <ParagraphSmall
              color={theme.colors.contentSecondary}
              margin={0}
              className={`${classes.horizontalFlex} ${classes.centeredStart} ${css({
                gap: theme.sizing.scale200,
              })}`}
            >
              {vehicle?.color ? <ColoredCircle color={vehicle.color} /> : '-'}
            </ParagraphSmall>
          }
        />}
        {vehicle.vin && <LabeledData
          label={<LabelMedium minWidth={'110px'} >{"No. Serie:"}</LabelMedium>}
          content={vehicle.vin}
        />}
      </FlexColumn>
    </FlexColumn>
  );
}

export default memo(AssetVehicleData);
