import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { HeadingXSmall } from "baseui/typography";
import { useState, useEffect, memo, useMemo } from "react";
import { createPortal } from "react-dom";
import { useFeedback } from "../../../hooks/useFeedback";
import { useStyles } from "../../../hooks/useStyles";
import { Installation, InstallationStatus, UpdatedInstallationStatusData } from "../../../types/installation";
import { FlexColumn } from "../../ui/FlexColumn";
import { FlexRow } from "../../ui/FlexRow";
import { StyledButton } from "../../ui/StyledButton";
import { OnChangeParams } from "baseui/select";
import { LabeledTextArea } from "../../ui/LabeledTextArea";
import { useLoading } from "../../../hooks/useLoading";
import { updateInstallationStatus } from "../../../services/installationService";
import { FEEDBACK, FEEDBACK_PREFIXES } from "../../../constants/app";
import { StyledBanner } from "../../ui/StyledBanner";
import { Delete } from "baseui/icon";
import { DetailElement } from "../../../types/app";
import { EnhancedLabeledSelect } from "../../ui/EnhancedLabeledSelect";

type Props = {
  onClose: (update?: boolean) => void;
  isOpen: boolean;
  installation?: Installation;
};

function ChangeStatusModal({ onClose, isOpen, installation }: Props) {
  const { theme, classes } = useStyles();
  const [updatedStatus, setUpdatedStatus] = useState<UpdatedInstallationStatusData>();
  const [installationsStatusList, setInstallationsStatusList] = useState<InstallationStatus[]>();
  const [installationsMotivesList, setInstallationsMotivesList] = useState<DetailElement[]>();
  const { showPositiveFeedback, showFailFeedback } = useFeedback();
  const { loading, startLoading, stopLoading } = useLoading();
  const [error, setError] = useState('');


  const canSubmit = 
    updatedStatus?.status && 
    (!installationsMotivesList?.length ? true : updatedStatus.reason) &&
    updatedStatus?.comments

  useEffect(() => {
    if (!installation || !isOpen) return;
    setInstallationsStatusList(installation.nextStatus)
  }, [installation, isOpen]);

  const statusOptions = useMemo(() => {
    if(!installationsStatusList) return;
    return [
      ...installationsStatusList.map((status) => ({ 
        id: status.clave, 
        label: status.descripcion, 
        motives: status.motivos 
      })),
    ];
  }, [installationsStatusList]);

  const motivesOptions = useMemo(() => {
    if(!installationsMotivesList) return;
    return [
      ...installationsMotivesList.map((motive) => ({ id: motive.clave, label: motive.descripcion })),
    ];
  }, [installationsMotivesList]);

  if(!installation) return null;

  const onStatusChange = (params: OnChangeParams) => {    
    setInstallationsMotivesList(params.option?.motives || undefined)
    setUpdatedStatus((prev) => {
      return {
        ...prev!,
        status: params.option?.id as string || '',
        reason: undefined
      }
    })
  };

  const onUpdatedDataChange = (value: string, field: keyof UpdatedInstallationStatusData) => {
    setUpdatedStatus((prev) => {
      return {
        ...prev!,
        [field]: value || ''
      }
    })
  };

  const onUpdateStatus = async () => {
    if (!updatedStatus) return;
    startLoading();
    const { error } = await updateInstallationStatus(installation?._id, updatedStatus);
    if (!error) {
      showPositiveFeedback(FEEDBACK.edited(FEEDBACK_PREFIXES.installationStatus))
      resetModal(true);
    } else {
      setError(typeof error === 'string' ? error : error.toString());
      showFailFeedback(error);
    }
    stopLoading();
  }
 
  const resetModal = (update?: boolean) => {
    setUpdatedStatus(undefined);
    setInstallationsMotivesList(undefined);
    setInstallationsStatusList(undefined);
    onClose(update);
  };
  

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => resetModal()}
      isOpen={isOpen}
    >
      <ModalHeader>
        <FlexRow
          gap={theme.sizing.scale200}
          classNames={classes.centeredStart}
        >
          <HeadingXSmall margin={0}>Estado: {installation?.estatusInstalacion.descripcion}</HeadingXSmall>
        </FlexRow>
      </ModalHeader>
      <ModalBody>
        <FlexColumn>
          {error && (
            <StyledBanner
              title="*Error*"
              kind="negative"
              action={{
                label: 'label',
                icon: () => <Delete />,
                onClick: () => {
                  setError('');
                },
              }}
            >
              {error}
            </StyledBanner>
          )}
          <EnhancedLabeledSelect
            label="Estado:*"
            options={statusOptions}
            value={[{ id: updatedStatus?.status }]}
            onChange={onStatusChange}
            disabled={!installationsStatusList?.length}
            inline
            inset
          />
          <EnhancedLabeledSelect
            label="Motivo:*"
            options={motivesOptions}
            value={[{ id: updatedStatus?.reason }]}
            onChange={(params) => onUpdatedDataChange(params.option?.id as string, 'reason')}
            disabled={!installationsMotivesList?.length}
            inline
            inset
          />
          <LabeledTextArea 
            label="Comentarios:*"
            value={updatedStatus?.comments}
            onChangeValue={(value) => onUpdatedDataChange(value, 'comments')}
          />
        </FlexColumn>
      </ModalBody>
      <ModalFooter>
        <FlexRow
          gap={theme.sizing.scale300}
          classNames={classes.centeredEnd}
        >
          <StyledButton
            kind="tertiary"
            onClick={() => resetModal()}
          >
            Cancelar
          </StyledButton>
          <StyledButton
            onClick={onUpdateStatus}
            disabled={!canSubmit}
            isLoading={loading}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </ModalFooter>
    </Modal>,
    document.body
  );
}

export default memo(ChangeStatusModal);
