import { OnChangeParams } from "baseui/select";
import { useState, useEffect, useMemo, useRef } from "react";
import { useDebounceFunction } from "../../../hooks/useDebounce";
import { useLoading } from "../../../hooks/useLoading";
import { getBrandsList } from "../../../services/vehicleService";
import { Brand } from "../../../types/brand";
import { EnhancedLabeledSelect } from "../../ui/EnhancedLabeledSelect";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

type Props = {
  brand?: Brand,
  selectedBrandId?: string,
  onChangeBrandId?: (brandId?: string) => void
  onChangeBrand?: (brand?: Brand) => void
  isFilter?: boolean,
  label?: string,
  error?: React.ReactNode;
  onBlur?: () => void;
  inline?: boolean;
  clearable?: boolean;
  required?: boolean;
}
export function BrandSelect({
  selectedBrandId, 
  onChangeBrandId,
  onChangeBrand,
  brand,
  isFilter = true,
  label = 'Marca:',
  error,
  onBlur,
  inline,
  clearable = false,
  required = false,
}: Readonly<Props>) {
  const [brandsList, setBrandsList] = useState<Brand[]>();
  const {loading, startLoading, stopLoading} = useLoading();
  const [page, setPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [selectedBrandOption, setSelectedBrandOption] = useState<{ id: string, label: string}>();
  const [initialBrandSearch, setInitialBrandSearch] = useState(false);
  const requestIdRef = useRef(0);

  useUpdateEffect(() => {
    setPage(0)
  }, [search])

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    const loadBrands = async () => {
      startLoading();
      const requestId = ++requestIdRef.current;
      const { brands, error, hasNext } = await getBrandsList(page, search);
      if (requestId === requestIdRef.current) {
        if (!error && brands) {
          if (page > 1 && brandsList) {
            setBrandsList([...brandsList, ...brands]);
            setHasNextPage(hasNext);
          } else {
            setBrandsList(brands);
            setHasNextPage(hasNext);
          }
        } else {
          setBrandsList(undefined);
          setHasNextPage(false);
        }
        stopLoading();
      }
    };
    loadBrands();
  }, [page]);

  const brandOptions = useMemo(() => {
    if (!brandsList) return;
    const brandsOptions: { id: string | undefined, label: string }[] = brandsList.map((brand) => ({ id: brand._id, label: brand.name }))
    if (!required) {
      brandsOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todos' : 'Ninguna',
      });
    }
    if (brand) {
      if (brand._id && !brandsOptions.find((c) => c.id === brand._id)) {
        brandsOptions.push({
          id: brand._id,
          label: brand.name
        })
      } else if (brand.name && !brand._id) {
        const brandFind = brandsOptions.find((c) => c.label.toLowerCase().trim() === brand.name.toLowerCase().trim())
        if (brandFind) {
          onChangeBrandId && onChangeBrandId(brandFind.id);
          onChangeBrand && onChangeBrand({ _id: brandFind.id as string, name: brandFind.label});
          setSelectedBrandOption(brandFind as {id: string, label: string})
        } else if (search !== brand.name && !initialBrandSearch){
          setSearch(brand.name);
          setInitialBrandSearch(true);
        } else {
          setSearch('');
          onChangeBrandId && onChangeBrandId(undefined);
          onChangeBrand && onChangeBrand(undefined);
        }
      }
    }
    if (selectedBrandOption) {
      if (!brandsOptions.find((c) => c.id === selectedBrandOption.id))
        brandsOptions.push(selectedBrandOption)
    }
    return brandsOptions
  }, [brandsList]);

  const onBrandChange = (params: OnChangeParams) => {
    if (params.option?.id) {
      onChangeBrandId && onChangeBrandId(params.option.id as string | undefined);
      onChangeBrand && onChangeBrand({ _id: params.option.id, name: params.option.label} as Brand);
    } else {
      onChangeBrandId && onChangeBrandId(undefined);
      onChangeBrand && onChangeBrand(undefined);
    }
    setSelectedBrandOption(params.option as {id: string, label: string});
    setSearch('');
  };

  const fetchData = () => {   
    if (hasNextPage)
      setPage((page ?? 0) + 1);
  }
  
  const handleInputChange = useDebounceFunction(function(term: string) {
    setSearch(term)
  }, 500);


  return (
    <EnhancedLabeledSelect
      label={label}
      options={brandOptions}
      value={[{ id: selectedBrandId }]}
      onChange={onBrandChange}
      onInputChange={(e) => {
        handleInputChange(e.target.value);
      }}
      fullWidth
      infiniteScroll
      fetchData={fetchData}
      fetchDataLoading={loading}
      hasNext={hasNextPage}
      onBlur={() => {
        setSearch('');
        onBlur && onBlur();
      }}
      searchable
      inline={inline}
      error={error}
      required={required}
      clearable={clearable}
      placeholder="Selecciona una marca"
    />
  )
}