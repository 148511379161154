import { useEffect, useMemo, useState } from "react";
import { Version } from "../../types/version";
import { LabeledInput } from "../ui/LabeledInput";
import { FlexColumn } from "../ui/FlexColumn";
import { useStyles } from "../../hooks/useStyles";
import { FlexRow } from "../ui/FlexRow";
import { areEqual } from "react-window";
import { StyledButton } from "../ui/StyledButton";
import { useLoading } from "../../hooks/useLoading";
import { useFeedback } from "../../hooks/useFeedback";
import { FEEDBACK, FEEDBACK_PREFIXES } from "../../constants/app";
import { postVersion, updateVersion } from "../../services/vehicleService";
import { StyledTooltip } from "../ui/StyledTooltip";
import { Button } from "baseui/button";
import { MaterialIcon } from "../ui/MaterialIcon";
import { BrandForm } from "../brands/BrandForm";
import { BrandSelect } from "../shared/selects/BrandSelect";
import { SubBrandSelect } from "../shared/selects/SubBrandSelect";
import { SubBrandForm } from "../subBrands/SubBrandForm";

type Props = {
  version?: Version
  onCancel: () => void,
  afterSave: () => void,
  inline?: boolean,
  brandId?: string
  subBrandId?: string
};
export function VersionForm({ 
  version, 
  onCancel, 
  afterSave, 
  inline = false, 
  brandId, 
  subBrandId 
}: Props) {
  const { css, theme } = useStyles();
  const [editingVersion, setEditingVersion] = useState<Version>({
    _id: '',
    name: '',
    brand: {
      _id: '',
      name: ''
    },
    subBrand: {
      _id: '',
      name: ''
    }
  });
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const [ createBrand, setCreateBrand ] = useState(false);
  const [ createSubBrand, setCreateSubBrand ] = useState(false);
  const [ selectedBrandId, setSelectedBrandId ] = useState<string>();
  const [ selectedSubBrandId, setSelectedSubBrandId ] = useState<string>();

  useEffect(() => {
    if (version) {
      setEditingVersion(version)
    } else {
      onInputChange({_id: undefined}, 'brand')
      onInputChange({_id: undefined}, 'subBrand')
    }
  }, [version])

  const hasUpdates = !areEqual(version ?? {}, editingVersion);


  const canSubmit = useMemo(() => {
    return !!editingVersion.name && !!editingVersion.brand._id && !!editingVersion.subBrand._id
  }, [editingVersion])


  const onInputChange = (value: string | undefined | null | {}, field: keyof Version) => {
    setEditingVersion((prev) => ({ ...prev!, [field]: value }));
  };

  useEffect(() => {
    if (!brandId && !subBrandId && !selectedBrandId && !selectedSubBrandId) return;
    else if (subBrandId && !brandId ) {
      onInputChange({_id: undefined}, 'brand')
      onInputChange({_id: undefined}, 'subBrand')
      setSelectedBrandId(undefined)
      setSelectedSubBrandId(undefined)
    } else if (brandId) {
      onInputChange({_id: brandId}, 'brand')
      onInputChange({_id: subBrandId}, 'subBrand')
      setSelectedBrandId(brandId)
      setSelectedSubBrandId(subBrandId)
    }    
  }, [brandId, subBrandId])

  const onSave = async () => {
    startLoading();
    const { id, error } = version ? await updateVersion(editingVersion) : await postVersion(editingVersion);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        version
          ? FEEDBACK.edited(FEEDBACK_PREFIXES.version, editingVersion.name)
          : FEEDBACK.created(FEEDBACK_PREFIXES.version)
      );
    } else {
      showFailFeedback(
        error || 
        version
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.version, editingVersion.name)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.version)
      );
    }
    stopLoading();
  }

  return (
    <FlexColumn 
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
        width: '100%',
        flexDirection: inline ? 'row' : 'column',
        alignItems: 'end'
      })}
    >
      <FlexColumn classNames={css({
        overflow: 'auto',
        width: '100%',
        height: '100%',
        flexDirection: inline ? 'row' : 'column'
      })}>
        <LabeledInput
          label={`${inline ? '(Nueva version) ' : ''}Nombre:`}
          value={editingVersion?.name}
          onChange={(value) => onInputChange(value, 'name')}
          required
        />
        {createBrand && !inline && <FlexRow classNames={css({ width: "100%", alignItems: "end" })}>
          <BrandForm
            afterSave={() => {
              setCreateBrand(false)
              setCreateSubBrand(false)
              onInputChange({_id: editingVersion.brand._id}, 'brand')
              onInputChange({_id: editingVersion.subBrand._id}, 'subBrand')
            }}
            onCancel={() => {
              setCreateBrand(false)
              onInputChange({_id: editingVersion.brand._id}, 'brand')
            }} 
            inline
          />
        </ FlexRow>}
        {!createBrand && !inline && <FlexRow classNames={css({ width: "100%", alignItems: "end" })} gap={'8px'}>
          <BrandSelect 
            brand={version?.brand}
            selectedBrandId={editingVersion?.brand._id}
            onChangeBrandId={(brandId) => {
              if (createSubBrand) {
                setCreateSubBrand(false);
                onInputChange({_id: undefined}, 'subBrand')
              }
              onInputChange({_id: brandId}, 'brand')
            }}
            isFilter={false}
            required
          />
          <StyledTooltip
            content={'Nueva'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={() => setCreateBrand(true)}
              kind="tertiary"
            >
              <MaterialIcon name={'add'} size='compact'/>
            </Button>
          </StyledTooltip>
        </FlexRow>}
        {createSubBrand && !inline && <FlexRow classNames={css({ width: "100%", alignItems: "end" })}>
          <SubBrandForm
            brandId={editingVersion.brand._id}
            afterSave={() => {
              setCreateSubBrand(false)
              onInputChange({_id: editingVersion?.subBrand?._id}, 'subBrand')
            }}
            onCancel={() => {
              setCreateSubBrand(false)
              onInputChange({_id: editingVersion?.subBrand?._id}, 'subBrand')
            }} 
            inline
          />
        </ FlexRow>}
        {!createSubBrand && !inline && <FlexRow classNames={css({ width: "100%", alignItems: "end" })} gap={'8px'}>
          <SubBrandSelect 
            subBrand={editingVersion?.subBrand.name ? editingVersion.subBrand : undefined}
            selectedBrandId={editingVersion.brand._id}
            selectedSubBrandId={editingVersion.subBrand?._id}
            onChangeSubBrand={(subBrandId) => onInputChange({_id: subBrandId}, 'subBrand')}
            isFilter={false}
            disabled={!editingVersion.brand._id}
          />
          <StyledTooltip
            content={'Nueva'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={() => setCreateSubBrand(true)}
              kind="tertiary"
              disabled={!editingVersion.brand._id}
            >
              <MaterialIcon name={'add'} size='compact'/>
            </Button>
          </StyledTooltip>
        </FlexRow>}
      </FlexColumn>
      {inline && <FlexRow gap={0}>
        <StyledTooltip
          content={'Cancelar'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onCancel}
            kind="tertiary"
          >
            <MaterialIcon name={'cancel'} />
          </Button>
        </StyledTooltip>
        <StyledTooltip
          content={'Guardar'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
            kind="tertiary"
          >
            <MaterialIcon name={'done'} />
          </Button>
        </StyledTooltip>
      </FlexRow>}
      {!inline && <FlexRow
        classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}
      >
        <FlexRow gap={theme.sizing.scale300}>
          {version?._id && <StyledButton
            kind="tertiary"
            onClick={onCancel}
          >
            Cancelar
          </StyledButton>}
          <StyledButton
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>}
    </FlexColumn>
  )
}