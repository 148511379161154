import { useState } from 'react';
import { BulkFiltersBar } from '../bulk/BulkFiltersBar';
import { BulkList } from '../bulk/BulkList';
import { ContentContainer } from '../ui/ContentContainer';

export function BulkPage() {
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <BulkFiltersBar onReloadPage={() => setReloadPage(!reloadPage)} />
      <BulkList reloadPage={reloadPage} />
    </ContentContainer>
  );
}
