import { useEffect, useState } from 'react';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { useStyles } from '../../hooks/useStyles';
import { getInstallationLogsList } from '../../services/logsService';
import { Installation } from '../../types/installation';
import { InstallationLog } from '../../types/logs';
import { EmptyState } from '../ui/EmptyState';
import { FlexColumn } from '../ui/FlexColumn';
import { Loading } from '../ui/Loading';
import { InstallationLogForm } from './logs/InstallationLogForm';
import { InstallationLogsList } from './logs/InstallationLogsList';

type Props = {
  installation: Installation;
};
export function InstallationDetailLogs({ installation }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [installationLogs, setInstallationLogs] = useState<InstallationLog[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const { showFailFeedback } = useFeedback();

  const getInstallationLogs = async () => {
    if (!installation) return;
    startLoading();
    const { logs, error } = await getInstallationLogsList(installation._id);
    if (error) {
      showFailFeedback(error);
      setInstallationLogs(undefined);
    } else {
      setInstallationLogs(logs);
    }
    stopLoading();
  };

  useEffect(() => {
    getInstallationLogs();
  }, [installation]);

  return (
    <FlexColumn gap={theme.sizing.scale400}>
      {loading && (
        <FlexColumn
          classNames={css({
            minHeight: '190px',
          })}
        >
          <Loading />
        </FlexColumn>
      )}
      {installationLogs && !loading && (
        <FlexColumn
          classNames={css({
            minHeight: '190px',
          })}
        >
          {!!installationLogs.length && <InstallationLogsList logs={installationLogs} />}
          {!installationLogs.length && <EmptyState title="No cuentas con ningún log." />}
        </FlexColumn>
      )}
      <InstallationLogForm
        installationId={installation._id}
        reloadData={getInstallationLogs}
      />
    </FlexColumn>
  );
}
