import { useStyles } from '../../hooks/useStyles';
import { GenericComponent } from '../../types/components';
import { FullContainer } from './FullContainer';

export function ContentContainer({ children, classNames }: Readonly<GenericComponent>) {
  const { theme, css, concatenateClasses } = useStyles();

  const containerStyles = css({
    padding: theme.sizing.scale400,
    gap: theme.sizing.scale500,
    boxSizing: 'border-box',
  });

  return (
    <FullContainer
      verticalFlex
      classNames={concatenateClasses(containerStyles, classNames ?? '')}
    >
      {children}
    </FullContainer>
  );
}
