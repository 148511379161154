import { Delete } from "baseui/icon";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { HeadingXSmall } from "baseui/typography";
import { useState, useMemo, memo, useEffect } from "react";
import { createPortal } from "react-dom";
import { FEEDBACK, FEEDBACK_PREFIXES } from "../../../constants/app";
import { useFeedback } from "../../../hooks/useFeedback";
import { useLoading } from "../../../hooks/useLoading";
import { useStyles } from "../../../hooks/useStyles";
import { getTagList, setTagPhone } from "../../../services/installationService";
import { FlexColumn } from "../../ui/FlexColumn";
import { FlexRow } from "../../ui/FlexRow";
import { LabeledSelect } from "../../ui/LabeledSelect";
import { StyledBanner } from "../../ui/StyledBanner";
import { StyledButton } from "../../ui/StyledButton";

type Props = {
  onClose: (update?: boolean) => void;
  isOpen: boolean;
  tags?: string[];
  phone: string;
};
function AddTagModal({ onClose, isOpen, tags, phone }: Props) {
  const { theme, classes } = useStyles();
  const [phoneTags, setPhoneTags] = useState<string[]>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const { showPositiveFeedback, showFailFeedback } = useFeedback();
  const { loading, startLoading, stopLoading } = useLoading();
  const [error, setError] = useState('');

  const canSubmit = true;

  const getTags = async () => {
    const {tags, error} = await getTagList();
    if (!error) {
      setPhoneTags(tags);
    } else {
      showFailFeedback(error);
      resetModal();
    }
  }

  useEffect(() => {
    if (tags) setSelectedTags(tags);
  }, [tags])

  useEffect(() => {
    if (isOpen) getTags();
  }, [isOpen])

  const tagsOptions = useMemo(() => {
    if(!phoneTags) return;
    return [
      ...phoneTags.map(
        (tag) => ({ 
          id: tag, label: tag 
        })
      ),
    ];
  }, [phoneTags]);

  const onUpdateTags = async () => {
    const addTags = selectedTags.filter((tag) => !tags?.includes(tag))
    const removeTags = tags?.filter((tag) => !selectedTags?.includes(tag))
    startLoading();
      await Promise.all([
        addTags.map(async (tag) => {
          await addRemovePhoneTags(tag, "add")
        }),
        removeTags?.map(async (tag) => {
          await addRemovePhoneTags(tag, "remove")
        })
      ])
      showPositiveFeedback(FEEDBACK.added(FEEDBACK_PREFIXES.tag))
      resetModal(true)
    stopLoading();
  };

  const addRemovePhoneTags = async (tag: string, action: string) => {
    const {error} = await setTagPhone(phone, tag, action);
    if (error) {
      showFailFeedback(error);
      setError(error as string);
    }
  }
  
  const resetModal = (update?: boolean) => {
    setSelectedTags([]);
    setPhoneTags(undefined);
    onClose(update);
  };
  

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => resetModal()}
      isOpen={isOpen}
    >
      <ModalHeader>
        <FlexRow
          gap={theme.sizing.scale200}
          classNames={classes.centeredStart}
        >
          <HeadingXSmall margin={0}>Tags</HeadingXSmall>
        </FlexRow>
      </ModalHeader>
      <ModalBody>
        <FlexColumn>
          {error && (
            <StyledBanner
              title="*Error*"
              kind="negative"
              action={{
                label: 'label',
                icon: () => <Delete />,
                onClick: () => {
                  setError('');
                },
              }}
            >
              {error}
            </StyledBanner>
          )}
          <LabeledSelect 
            label="Tags"
            options={tagsOptions}
            value={selectedTags.map((tag) => { return {id: tag} })}
            onChange={(params) => {
              setSelectedTags(params.value.map((value) => value.id) as string[])
            }}
            multi
            searchable
            required
          />
        </FlexColumn>
      </ModalBody>
      <ModalFooter>
        <FlexRow
          gap={theme.sizing.scale300}
          classNames={classes.centeredEnd}
        >
          <StyledButton
            kind="tertiary"
            onClick={() => resetModal()}
          >
            Cancelar
          </StyledButton>
          <StyledButton
            onClick={onUpdateTags}
            disabled={!canSubmit}
            isLoading={loading}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </ModalFooter>
    </Modal>,
    document.body
  );
}

export default memo(AddTagModal);
