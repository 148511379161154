import { useMemo } from 'react';
import { USER_TYPES } from '../../constants/users';
import { UserType } from '../../types/user';
import { OnChangeParams } from 'baseui/select';
import { EnhancedLabeledSelect } from '../ui/EnhancedLabeledSelect';

type Props = {
  onChange: (userType: UserType) => void;
  userType?: UserType;
  maxWidth?: number;
  disabled?: boolean;
  inline?: boolean;
  isFilter?: boolean;
  required?: boolean;
};

export function RolePicker({
  onChange,
  userType,
  maxWidth,
  disabled = false,
  inline = false,
  required = false,
  isFilter,
}: Readonly<Props>) {
  const userTypeOptions = useMemo(() => {
    const options = [
      {
        id: undefined,
        label: isFilter ? 'Todos' : 'Ninguno',
      },
      ...Object.entries(USER_TYPES).map(([key, userType]) => ({
        id: key,
        label: userType,
      })),
    ];
    return options;
  }, [isFilter]);

  const selectConfig = {
    label: `Rol: ${required ? '*' : ''}`,
    options: userTypeOptions,
    onChange: (params: OnChangeParams) => {
      onChange(params.option?.id as UserType);
    },
    value: [{ id: userType }],
  };

  return (
    <EnhancedLabeledSelect 
      {...selectConfig}
      searchable
      inline={inline}
      inset={inline}
      maxWidth={maxWidth}
      disabled={disabled}
      fullWidth
    />
  )
}
