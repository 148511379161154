import { useState } from 'react';
import { CommsReportFiltersBar } from '../reports/CommsReportFiltersBar';
import { ContentContainer } from '../ui/ContentContainer';
import { CommsReportOfflineList } from '../reports/CommsReportOfflineList';

export function CommsReportOfflinePage() {
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <CommsReportFiltersBar onReloadPage={() => setReloadPage(!reloadPage)} />
      <CommsReportOfflineList reloadPage={reloadPage} />
    </ContentContainer>
  );
}
