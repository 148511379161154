import { useState } from 'react';
import { DevicesFiltersBar } from '../devices/DevicesFiltersBar';
import { DevicesList } from '../devices/DevicesList';
import { ContentContainer } from '../ui/ContentContainer';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';

export function DevicesPage() {
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [reloadPage, setReloadPage] = useState(false);
  const [clientId, setClientId] = useState<string>();
  const [subClientId, setSubClientId] = useState<string>();

  return (
    <ContentContainer>
      <DevicesFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
        selectedClientId={clientId}
        setSelectedClientId={setClientId}
        selectedSubClientId={subClientId}
        setSelectedSubClientId={setSubClientId}
      />
      <DevicesList
        search={search}
        reloadPage={reloadPage}
        clientId={clientId}
        subClientId={subClientId}
      />
    </ContentContainer>
  );
}
