import { HeadingXSmall } from 'baseui/typography';
import { SubClient } from '../../types/subClient';
import LabeledData from '../ui/LabeledData';
import { LabeledIconData } from '../ui/LabeledIconData';

type Props = {
  subClient: SubClient;
};
export function SubClientInfo({ subClient }: Readonly<Props>) {
  const labelWidth = '190px';
  return (
    <>
      <LabeledIconData
        label="Nombre:"
        data={subClient.name}
        icon="person"
        labelWidth={labelWidth}
      />
      <LabeledIconData
        label="Nombre comercial:"
        data={subClient.tradeName}
        icon="person"
        labelWidth={labelWidth}
      />
      <LabeledIconData
        label="RFC:"
        data={subClient.rfc}
        icon="person"
        labelWidth={labelWidth}
      />
      <HeadingXSmall margin={0}>Contacto de emergencia</HeadingXSmall>
      <LabeledData 
        label='Nombre:'
        content={subClient.contact?.fullName}
      />
      <LabeledData 
        label='Correo:'
        content={subClient.contact?.email}
      />
      <LabeledData 
        label='Teléfono:'
        content={subClient.contact?.phone}
      />
    </>
  );
}
