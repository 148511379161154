import { Button } from 'baseui/button';
import { FlexRow } from '../ui/FlexRow';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledSearchBar } from '../ui/StyledSearchBar';
import { StyledTooltip } from '../ui/StyledTooltip';
import { useStyles } from '../../hooks/useStyles';
import { useModal } from '../../hooks/useModal';
import NewClientModal from './modal/NewClientModal';
import { StyledButton } from '../ui/StyledButton';

type Props = {
  search?: string;
  onChangeSearch: (search?: string) => void;
  onReloadPage: () => void;
};
export function ClientsFiltersBar({ search, onChangeSearch, onReloadPage }: Props) {
  const { css, theme } = useStyles();
  const { openModal, closeModal, showModal } = useModal();

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '50%',
        })}
      >
        <StyledSearchBar
          value={search ?? ''}
          onChange={onChangeSearch}
          clearable
        />
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
        <StyledButton
          onClick={openModal}
          startEnhancer={
            <MaterialIcon
              name="add"
              size="xs-mini"
              color={theme.colors.contentInversePrimary}
            />
          }
        >
          Nuevo
        </StyledButton>
      </FlexRow>
      {showModal && <NewClientModal
        onClose={(updateTable) => {
          if (updateTable) {
            onReloadPage();
          }
          closeModal();
        }}
        isOpen={showModal}
      />}
    </FlexRow>
  );
}
