import { LabelSmall, ParagraphSmall } from 'baseui/typography';
import { memo } from 'react';
import { useStyles } from '../../hooks/useStyles';
import { EllipsedText } from './EllipsedText';
import { FlexRow } from './FlexRow';

type Props = {
  label: string | React.ReactNode;
  content: string | React.ReactNode;
  labelWidth?: string;
  verticalAlign?: 'baseline' | 'center' | 'flex-end' | 'flex-start';
  minWidth?: string,
  maxWidth?: string,
};
function LabeledData({ 
  label, 
  content, 
  labelWidth = '120px', 
  verticalAlign = 'baseline', 
  minWidth, 
  maxWidth,
}: Readonly<Props>) {
  const { css, theme } = useStyles();

  return (
    <FlexRow
      gap={theme.sizing.scale300}
      classNames={css({
        alignItems: verticalAlign,
        flex: 1,
        minWidth,
        maxWidth,
      })}
    >
      {typeof label === 'string' && <LabelSmall minWidth={labelWidth}>{label}</LabelSmall>}
      {typeof label !== 'string' && label}
      {typeof content === 'string' && (
        <ParagraphSmall
          margin={0}
          color={theme.colors.contentSecondary}
        >
          <EllipsedText inline>{content}</EllipsedText>
        </ParagraphSmall>
      )}
      {typeof content !== 'string' && content}
    </FlexRow>
  );
}

export default memo(LabeledData);
