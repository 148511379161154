import { useState, useEffect, useMemo, useRef } from "react";
import { useLoading } from "../../../hooks/useLoading";
import { VersionCore } from "../../../types/version";
import { OnChangeParams } from "baseui/select";
import { useDebounceFunction } from "../../../hooks/useDebounce";
import { getVersionsList } from "../../../services/vehicleService";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import { EnhancedLabeledSelect } from "../../ui/EnhancedLabeledSelect";


type Props = {
  version?: VersionCore,
  selectedBrandId?: string,
  selectedSubBrandId?: string,
  selectedVersionId?: string,
  onChangeVersion: (versionId?: string) => void
  isFilter?: boolean,
  label?: string,
  disabled?: boolean
  inline?: boolean,
  required?: boolean,
}
export function VersionSelect({
  selectedBrandId,
  selectedSubBrandId,
  selectedVersionId, 
  onChangeVersion,
  version,
  isFilter = true,
  label = 'Version:',
  disabled = false,
  inline = false,
  required = false,
}: Readonly<Props>) {
  const [versionsList, setVersionsList] = useState<VersionCore[]>();
  const {loading, startLoading, stopLoading} = useLoading();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [subBrandId, setSubBrandId] = useState<string>();
  const [selectedVersionOption, setSelectedVersionOption] = useState<{ id: string, label: string}>();
  const [initialBrandSearch, setInitialBrandSearch] = useState(false);
  const requestIdRef = useRef(0);

  useUpdateEffect(() => {
    setPage(0)
  }, [search, selectedBrandId, subBrandId, disabled])

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    if (disabled) return;
    startLoading();
    const loadVersions = async () => {
      const requestId = ++requestIdRef.current;
      const { versions, error, hasNext } = await getVersionsList(page, search, selectedBrandId, subBrandId);
      if (requestId === requestIdRef.current) {
        if (!error && versions) {
          if (page > 1 && versionsList) {
            setVersionsList([...versionsList, ...versions]);
            setHasNextPage(hasNext);
          } else {
            setVersionsList(versions);
            setHasNextPage(hasNext);
          }
        } else {
          setVersionsList(undefined);
          setHasNextPage(false);
        }
        stopLoading();
      }
    };
    loadVersions();
  }, [page]);

  useEffect(() => {
    if (subBrandId && selectedVersionId) 
      onChangeVersion()
    setSubBrandId(selectedSubBrandId)
  }, [selectedSubBrandId])

  const versionOptions = useMemo(() => {
    if (!versionsList) return;
    const versionsOptions: { id: string | undefined, label: string }[] = [
      ...versionsList.map((version) => ({ id: version._id, label: version.name })),
    ];
    if (!required) {
      versionsOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todas' : 'Ninguna',
      });
    }
    if (version) {
      if (version._id && !versionsOptions.find((c) => c.id === version._id)) {
        versionsOptions.push({
          id: version._id,
          label: version.name
        })
      } else if (version.name && !version._id) {
        const subBrandFind = versionsOptions.find((c) => c.label.toLowerCase().trim() === version.name.toLowerCase().trim())
        if (subBrandFind) {
          onChangeVersion(subBrandFind.id);
          setSelectedVersionOption(subBrandFind as {id: string, label: string})
        } else if (search !== version.name && !initialBrandSearch){
          setSearch(version.name);
          setInitialBrandSearch(true);
        } else {
          setSearch('');
          onChangeVersion(undefined);
        }
      }
    }
    if (selectedVersionOption) {
      if (!versionsOptions.find((c) => c.id === selectedVersionOption.id))
        versionsOptions.push(selectedVersionOption)
    }
    return versionsOptions
  }, [versionsList]);

  const onVersionChange = (params: OnChangeParams) => {
    params.option?.id ? onChangeVersion(params.option.id as string) : onChangeVersion(undefined);
    setSelectedVersionOption(params.option as {id: string, label: string});
    setSearch(undefined);
  };

  const fetchData = () => {
    if (hasNextPage)
      setPage(page + 1);
  }
  
  const handleInputChange = useDebounceFunction(function(term: string) {
    setSearch(term)
  }, 500);


  return (
    <EnhancedLabeledSelect
      label={label}
      options={versionOptions}
      value={[{ id: selectedVersionId }]}
      onChange={onVersionChange}
      onInputChange={(e) => {
        handleInputChange(e.target.value)
      }}
      fullWidth
      inline={inline}
      inset={inline}
      infiniteScroll
      fetchData={fetchData}
      fetchDataLoading={loading}
      hasNext={hasNextPage}
      onBlur={() => setSearch(undefined)}
      searchable
      disabled={disabled}
    />
  ) 
}