import { PaginatedColumn } from '../types/app';
import { MinimalSubClient } from '../types/subClient';

export const SUB_CLIENTS_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Nombre',
    key: 'name',
    flex: 1,
  },
  {
    label: '# Usuarios',
    key: 'userAccounts',
    maxWidth: 150,
    centered: true,
  },
  {
    label: 'Acciones',
    key: 'actions',
    maxWidth: 150,
    centered: true,
  },
];

export const DETAIL_SECTION = 'detalle';
export const ASSETS_SECTION = 'activos';
export const SUBACCOUNTS_SECTION = 'subcuentas';
export const WEB_SERVICES_SECTION = 'web-services';

export const EMPTY_SUB_CLIENT: MinimalSubClient = {
  _id: '',
  client: undefined,
  name: '',
  tradeName: '',
  rfc: '',
};

export const SUB_CLIENT_ASSETS_TABLE_COLUMNS_NAME = [
  {
    label: 'Etiqueta',
    key: 'tag',
  },
  {
    label: 'Dispositivo Asignado',
    key: 'assignedDevice',
  },
  {
    label: 'Acciones',
    key: 'actions',
  },
];
