import { HeadingXSmall, ParagraphMedium } from 'baseui/typography';
import { StyleObject } from 'styletron-react';
import { useStyles } from '../../hooks/useStyles';
import { FullContainer } from './FullContainer';

type Props = {
  title?: string;
  description?: string;
  action?: React.ReactNode;
  children?: React.ReactNode;
  style?: StyleObject;
  centered?: boolean;
};

export function EmptyState({ children, title, description, style, action, centered = true }: Props) {
  const { css } = useStyles();
  return (
    <FullContainer
      classNames={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: centered ? 'center' : 'flex-start',
        alignItems: centered ? 'center' : 'flex-start',
        textAlign: centered ? 'center' : undefined,
        ...style,
      })}
    >
      {children}
      {title && (
        <HeadingXSmall
          marginTop={!children ? 0 : undefined}
          marginBottom={0}
        >
          {title}
        </HeadingXSmall>
      )}
      {description && <ParagraphMedium marginTop={0}>{description}</ParagraphMedium>}
      {action}
    </FullContainer>
  );
}
