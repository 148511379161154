import { LabelMedium, LabelSmall, ParagraphXSmall } from "baseui/typography";
import { Device } from "../../types/device";
import { EllipsedText } from "../ui/EllipsedText";
import { FlexColumn } from "../ui/FlexColumn";
import { FlexRow } from "../ui/FlexRow";
import LabeledData from "../ui/LabeledData";
import { LabeledIconData } from "../ui/LabeledIconData";
import { useStyles } from "../../hooks/useStyles";
import { useMoment } from "../../hooks/useMoment";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { Block } from "baseui/block";
import Map from '../map/Map';
import { useSetRecoilState } from "recoil";
import { selectDeviceState } from "../../storage/devices";
import { useEffect } from "react";

type Props = {
  device: Device
};
export function DeviceInfo({device}: Props) {
  const { css, classes } = useStyles();
  const { formatDateFromTs } = useMoment();
  const { isSmall } = useBreakpoints();
  const setSelectDevice = useSetRecoilState(selectDeviceState);

  useEffect(() => {
    setSelectDevice(device);
  }, [device])

  return (
    <FlexColumn>
      <FlexRow classNames={css({
        flexDirection: isSmall ? 'column' : 'row'
      })}>
        <FlexColumn classNames={css({flex: 1})}>
          <LabeledIconData 
            label="Modelo:"
            data={device.model.name}
            icon="device_hub"
          />
          <LabeledIconData 
            label="Marca:"
            data={device.brand.name}
            icon="devices_other"
          />
          {device.asset?.client && (
            <LabeledIconData 
              label="Cliente:"
              data={device.asset.client.name}
              icon="business_center"
            />
          )}
          {device.asset?.subClient && (
            <LabeledIconData 
              label="Subcliente:"
              data={device.asset.subClient.name}
              icon="business_center"
            />
          )}
          {device.imei && <LabeledIconData 
            label="IMEI:"
            data={device.imei}
            icon="label"
          />}
          {device.sim && <LabeledIconData 
            label="SIM:"
            data={device.sim}
            icon="label"
          />}
          {device.carrier && <LabeledIconData 
            label="Carrier:"
            data={device.carrier}
            icon="label"
          />}
        </FlexColumn>
        <FlexColumn classNames={css({ overflow: 'auto', flex: '1'})} gap={'4px'}>
          {(device.cs?.geoReference || device.cs?.position_latitude) && 
            <>
              <LabelMedium className={classes.dataHeaderClass}>Estado Actual</LabelMedium>
              <LabeledData
                label={<LabelSmall minWidth={'105px'}>{"Ultima posición:"}</LabelSmall>}
                content={
                <EllipsedText maxLines={5}>
                  {device.cs?.timestamp ? formatDateFromTs(device.cs.timestamp) : '-'}
                </EllipsedText>
                }
              />
              <FlexColumn gap={0}>
                <LabelSmall>Georeferencia:</LabelSmall>
                <ParagraphXSmall margin={'0px 0px 0px 12px'}>
                  <EllipsedText maxLines={5}>{device.cs?.geoReference || '-'}</EllipsedText>
                </ParagraphXSmall>
              </FlexColumn>
              <FlexRow>
                {device.cs?.position_latitude && <LabeledData
                  label={<LabelSmall minWidth={'50px'}>{"Latitud:"}</LabelSmall>}
                  content={<EllipsedText maxLines={5}>{device.cs.position_latitude.toString()}</EllipsedText>}
                />}
                {device.cs?.position_longitude && <LabeledData
                  label={<LabelSmall minWidth={'50px'}>{"Longitud:"}</LabelSmall>}
                  content={<EllipsedText maxLines={5}>{device.cs.position_longitude.toString()}</EllipsedText>}
                />}
              </FlexRow>
            </>
          }
          {(device.hsWithoutAsset?.geoReference || device.hsWithoutAsset?.position_latitude) && 
            <>
              <LabelMedium className={classes.dataHeaderClass}>Posición sin asset</LabelMedium>
              <LabeledData
                label={<LabelSmall minWidth={'105px'}>{"Ultima posición:"}</LabelSmall>}
                content={
                <EllipsedText maxLines={5}>
                  {device.hsWithoutAsset?.timestamp ? formatDateFromTs(device.hsWithoutAsset.timestamp) : '-'}
                </EllipsedText>
                }
              />
              <FlexColumn gap={0}>
                <LabelSmall>Georeferencia:</LabelSmall>
                <ParagraphXSmall margin={'0px 0px 0px 12px'}>
                  <EllipsedText maxLines={5}>{device.hsWithoutAsset?.geoReference || '-'}</EllipsedText>
                </ParagraphXSmall>
              </FlexColumn>
              <FlexRow>
                {device.hsWithoutAsset?.position_latitude && <LabeledData
                  label={<LabelSmall minWidth={'50px'}>{"Latitud:"}</LabelSmall>}
                  content={<EllipsedText maxLines={5}>{device.hsWithoutAsset.position_latitude.toString()}</EllipsedText>}
                />}
                {device.hsWithoutAsset?.position_longitude && <LabeledData
                  label={<LabelSmall minWidth={'50px'}>{"Longitud:"}</LabelSmall>}
                  content={<EllipsedText maxLines={5}>{device.hsWithoutAsset.position_longitude.toString()}</EllipsedText>}
                />}
              </FlexRow>
            </>
          }
        </FlexColumn>
      </FlexRow>
      {(device.cs?.geoReference || device.cs?.position_latitude || device.hsWithoutAsset?.geoReference || device.hsWithoutAsset?.position_latitude) && <Block className={css({
        width: '100%'
      })}>
        <Map fullWindow={false} minHeight={isSmall ? '150px' : '250px'}/>
      </Block>}
    </FlexColumn>
  )
}