import * as React from 'react';
import { IconProps } from 'baseui/icon';
import * as Icons from "baseui/icon/icon-exports";

type Props = {
  icon?: 'Alert' |
    'ArrowDown' |
    'ArrowLeft' |
    'ArrowRight' |
    'ArrowUp' |
    'Blank' |
    'CheckIndeterminate' |
    'Check' |
    'ChevronDown' |
    'ChevronLeft' |
    'ChevronRight' |
    'ChevronUp' |
    'DeleteAlt' |
    'Delete' |
    'Filter' |
    'Grab' |
    'Hide' |
    'Menu' |
    'Overflow' |
    'Plus' |
    'Search' |
    'Show' |
    'Spinner' |
    'TriangleDown' |
    'TriangleLeft' |
    'TriangleRight' |
    'TriangleUp' |
    'Upload' ;
  size?: string;
  className?: string;
  onClick?: ()=>{};
};

export function StyledActionIcon({ icon, size, className, onClick }: Readonly<Props>) {
  const ActIcon = Icons[icon ?? 'Delete'];
  return (
    <ActIcon
      className={`${className}`}
      size={size ?? 24}
      onClick={onClick}
    />
  );
}