import { Button } from 'baseui/button';

type Props = {
  full?: boolean;
};

export function StyledButton({ overrides, full, ...props }: React.ComponentProps<typeof Button> & Props) {
  return (
    <Button
      {...props}
      size={props.size || 'compact'}
      kind={props.kind || 'primary'}
      disabled={props.disabled || false}
      overrides={{
        ...overrides,
        BaseButton: {
          style: { ...overrides?.BaseButton?.style, width: full ? '100%' : undefined, whiteSpace: 'nowrap' },
        },
      }}
    />
  );
}
