import { StyledDivider } from 'baseui/divider';
import { useEffect, useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { EMPTY_USER } from '../../constants/users';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import { useStyles } from '../../hooks/useStyles';
import { postUser, updateUser, updateUserPassword } from '../../services/userService';
import { ClientCore } from '../../types/client';
import { User, UserMinimal } from '../../types/user';
import { ClientSelect } from '../shared/selects/ClientSelect';
import { SubAccountSelect } from '../shared/selects/SubAccountSelect';
import { SubClientSelect } from '../shared/selects/SubClientSelect';
import { PermissionTree } from '../shared/permissionTree/PermissionTree';
import { FlexColumn } from '../ui/FlexColumn';
import { FlexRow } from '../ui/FlexRow';
import { LabeledInput } from '../ui/LabeledInput';
import { PasswordFormFields } from '../ui/PasswordFormFields';
import { StyledButton } from '../ui/StyledButton';

type Props = {
  user?: User;
  onCancel: () => void;
  afterSave: () => void;
  clientId?: string;
};
export function UserForm({ user, onCancel, afterSave, clientId }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [editingUser, setEditingUser] = useState<UserMinimal>(EMPTY_USER);
  const [changePassword, setChangePassword] = useState(false);
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const { isValidPassword } = usePasswordValidation(editingUser.password, editingUser.confirmPassword);

  const isNewUser = !user;

  useEffect(() => {
    if (user) {
      setEditingUser(user);
    } else if (clientId) {
      setEditingUser((prev) => ({ ...prev, client: { _id: clientId } as ClientCore }));
    }
  }, [user, clientId]);

  useEffect(() => {
    setEditingUser((prev) => ({ ...prev, password: undefined, confirmPassword: undefined }));
  }, [changePassword]);

  const hasUpdates = !areEqual(user ?? {}, editingUser);

  const canSubmit = useMemo(() => {
    if (changePassword && !isValidPassword) return false;
    if (changePassword && isValidPassword) return true;
    if (isNewUser && !isValidPassword) {
      return false;
    }
    if (!editingUser.username) {
      return false;
    }
    return true;
  }, [editingUser, isNewUser]);

  const onInputChange = (value: any, field: keyof UserMinimal) => {
    setEditingUser((prev) => ({ ...prev!, [field]: value }));
  };

  const onChangePassword = async () => {
    if (!changePassword || !user || !isValidPassword) return;
    startLoading();
    const { error } = await updateUserPassword(editingUser._id, editingUser.password!);
    if (!error) {
      setChangePassword(false);
      showPositiveFeedback(FEEDBACK.edited(FEEDBACK_PREFIXES.password, editingUser.username));
    } else {
      showFailFeedback(error || FEEDBACK.failedEdition(FEEDBACK_PREFIXES.password, editingUser.username));
    }
    stopLoading();
  };

  const onSave = async () => {
    startLoading();
    const { id, error } = user ? await updateUser(editingUser) : await postUser(editingUser);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        user ? FEEDBACK.edited(FEEDBACK_PREFIXES.user, editingUser.username) : FEEDBACK.created(FEEDBACK_PREFIXES.user)
      );
    } else {
      showFailFeedback(
        error || user
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.user, editingUser.username)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.user)
      );
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          height: '100%',
        })}
      >
        {!changePassword && (
          <>
            <LabeledInput
              label="Nombre:"
              value={editingUser.name}
              onChange={(value) => onInputChange(value, 'name')}
            />
            {isNewUser && (
              <LabeledInput
                label="Nombre de usuario:"
                value={editingUser.username}
                onChange={(value) => onInputChange(value, 'username')}
                required
              />
            )}
            <LabeledInput
              label="Correo:"
              type="email"
              value={editingUser.email}
              onChange={(value) => onInputChange(value, 'email')}
            />
            {!isNewUser && (
              <FlexRow>
                <StyledButton
                  shape="pill"
                  kind="tertiary"
                  onClick={() => setChangePassword(true)}
                >
                  Cambiar contraseña
                </StyledButton>
              </FlexRow>
            )}
            {isNewUser && (
              <PasswordFormFields
                password={editingUser.password}
                setPassword={(password) => onInputChange(password, 'password')}
                confirmPassword={editingUser.confirmPassword}
                setConfirmPassword={(password) => onInputChange(password, 'confirmPassword')}
              />
            )}
            <StyledDivider
              $size="cell"
              style={{ width: '100%' }}
            />
            {!clientId && (
              <ClientSelect
                client={editingUser?.client?.name ? editingUser?.client : undefined}
                selectedClientId={editingUser?.client?._id}
                onChangeClientId={(clientId) => onInputChange({ _id: clientId }, 'client')}
                isFilter={false}
              />
            )}
            <SubClientSelect
              selectedClientId={editingUser?.client?._id}
              selectedSubClientId={editingUser?.subClient?._id}
              subClient={editingUser?.subClient?.name ? editingUser.subClient : undefined}
              onChangeSubClientId={(subClientId) => onInputChange({ _id: subClientId }, 'subClient')}
              disabled={!editingUser?.client?._id}
              isFilter={false}
            />
            <SubAccountSelect
              selectedSubClientId={editingUser?.subClient?._id}
              selectedSubAccountId={editingUser?.subAccount?._id}
              subAccount={editingUser.subAccount}
              onChangeSubAccount={(subAccountId) => onInputChange({ _id: subAccountId }, 'subAccount')}
              disabled={!editingUser?.subClient?._id}
              isFilter={false}
            />
            <PermissionTree
              userId={editingUser?._id}
              userPermissions={editingUser?.permissions ?? {}}
              disableScroll
              onChangePermissions={(newPermissions) => {
                onInputChange(newPermissions, 'permissions');
              }}
              disabled={false}
            />
          </>
        )}
        {changePassword && (
          <PasswordFormFields
            password={editingUser.password}
            setPassword={(password) => onInputChange(password, 'password')}
            confirmPassword={editingUser.confirmPassword}
            setConfirmPassword={(password) => onInputChange(password, 'confirmPassword')}
          />
        )}
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          {(user?._id || changePassword) && (
            <StyledButton
              kind="tertiary"
              onClick={
                changePassword
                  ? () => {
                      setChangePassword(false);
                    }
                  : onCancel
              }
            >
              Cancelar
            </StyledButton>
          )}
          <StyledButton
            onClick={changePassword ? onChangePassword : onSave}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
