import { useMemo } from 'react';
import { BULK_FILE_TYPES } from '../../constants/bulk';
import { FileType } from '../../types/bulk';
import { LabeledSelect } from '../ui/LabeledSelect';

type Props = {
  onChange: (fileType: FileType) => void;
  fileType?: FileType;
  maxWidth?: number;
  disabled?: boolean;
};

export function FileTypePicker({ onChange, fileType, maxWidth, disabled = false }: Props) {
  const fileTypeOptions = useMemo(() => {
    return Object.entries(BULK_FILE_TYPES).map(([key, fileType]) => ({
      id: key,
      label: fileType,
    }));
  }, []);

  const value = fileType ? [{ id: fileType }] : [];

  return (
    <LabeledSelect
      label={'Tipo de archivo'}
      options={fileTypeOptions}
      value={value}
      onChange={(params) => {
        onChange(params.option?.id as FileType);
      }}
      searchable
      maxWidth={maxWidth}
      disabled={disabled}
    />
  );
}
