import { useState } from 'react';
import { ContentContainer } from '../../ui/ContentContainer';
import { ClientSubClientFiltersBar } from './subClients/ClientSubClientFiltersBar';
import { ClientSubClientList } from './subClients/ClientSubClientList';

type Props = {
  clientId: string;
};

export function SubClientsTab({ clientId }: Readonly<Props>) {
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <ClientSubClientFiltersBar
        clientId={clientId}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <ClientSubClientList
        clientId={clientId}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
