import { PaginatedColumn } from '../types/app';

export const CLIENT_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'RFC',
    key: 'rfc',
  },
  {
    label: 'Nombre',
    key: 'name',
  },
  {
    label: 'Pagado',
    key: 'pagado',
    minWidth: 150,
    maxWidth: 150,
    centered: true,
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];

export const ACCOUNT_SECTION = 'cuenta';
export const SUB_CLIENTS_SECTION = 'subclientes';
export const USERS_SECTION = 'usuarios';
export const STREAMAX_SECTION = 'streamax';

export const CLIENT_USERS_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Nombre de usuario',
    key: 'name',
  },
  {
    label: 'Cliente',
    key: 'client',
  },
  {
    label: 'Subcliente',
    key: 'subClient',
  },
  {
    label: 'Subcuenta',
    key: 'subAccount',
  },
  {
    label: 'Acciones',
    key: 'actions',
  },
];
