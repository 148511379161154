import { DatePicker } from 'baseui/datepicker';
import { OnChangeParams } from 'baseui/select';
import { Filter } from 'baseui/table';
import { memo, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { COMMANDS_STATUS, COMMAND_ORIGINS } from '../../constants/commands';
import { useStyles } from '../../hooks/useStyles';
import { commandsState } from '../../storage/commands';
import { CommandOrigin } from '../../types/command';
import { FlexColumn } from '../ui/FlexColumn';
import { FlexRow } from '../ui/FlexRow';
import { StyledButton } from '../ui/StyledButton';
import { useCommands } from './hooks/useCommands';
import { EnhancedLabeledSelect } from '../ui/EnhancedLabeledSelect';

export function CommandFilters() {
  const { theme, css } = useStyles();
  const {
    selectedCommandsDateRange,
    onChangeDates,
    selectedStatus,
    onChangeStatus,
    selectedCommandType,
    onChangeCommandType,
    hasActiveFilters,
    resetFilters,
    selectedCommandOrigin,
    onChangeCommandOrigin,
  } = useCommands();
  const availableCommands = useRecoilValue(commandsState);

  const commandTypeOptions = useMemo(() => {
    if (!availableCommands) return;
    return [
      {
        id: '',
        label: 'Todos',
      },
      ...availableCommands.map((availableCommand) => ({
        id: availableCommand.commandName,
        label: availableCommand.commandDescription,
      })),
    ];
  }, [availableCommands]);

  const statusOptions = useMemo(() => {
    return [
      {
        id: '',
        label: 'Todos',
      },
      ...Object.keys(COMMANDS_STATUS).map((status) => ({ id: status, label: COMMANDS_STATUS[status] })),
    ];
  }, []);

  const commandOriginOptions = useMemo(() => {
    return [
      {
        id: '',
        label: 'Todos',
      },
      ...Object.keys(COMMAND_ORIGINS).map((origin) => ({ id: origin, label: COMMAND_ORIGINS[origin] })),
    ];
  }, []);

  const onCommandChange = (params: OnChangeParams) => {
    params.option?.id ? onChangeCommandType(params.option.id as string) : onChangeCommandType(undefined);
  };

  const onStatusChange = (params: OnChangeParams) => {
    params.option?.id ? onChangeStatus(params.option.id as string) : onChangeStatus(undefined);
  };

  const onOriginChange = (params: OnChangeParams) => {
    params.option?.id ? onChangeCommandOrigin(params.option.id as CommandOrigin) : onChangeCommandOrigin(undefined);
  };

  const startDate =
    selectedCommandsDateRange && selectedCommandsDateRange[0] ? new Date(selectedCommandsDateRange[0]) : undefined;
  const endDate =
    selectedCommandsDateRange && selectedCommandsDateRange[1] ? new Date(selectedCommandsDateRange[1]) : undefined;

  return (
    <Filter
      active={hasActiveFilters}
      overrides={{
        Heading: {
          component: () => <></>,
        },
        Footer: {
          component: () => {
            return (
              <FlexRow
                classNames={css({
                  justifyContent: 'end',
                  padding: theme.sizing.scale200,
                })}
              >
                <StyledButton
                  onClick={resetFilters}
                  size="mini"
                  kind="tertiary"
                >
                  Limpiar filtros
                </StyledButton>
              </FlexRow>
            );
          },
        },
        Content: {
          style: () => ({
            borderTop: 'none',
          }),
        },
      }}
    >
      <FlexColumn
        classNames={css({
          paddingTop: theme.sizing.scale500,
          paddingBottom: theme.sizing.scale500,
        })}
        gap={theme.sizing.scale200}
      >
        {!!availableCommands?.length && (
          <EnhancedLabeledSelect
            label="Comando:"
            options={commandTypeOptions}
            value={[{ id: selectedCommandType ?? '' }]}
            onChange={onCommandChange}
            inline
            inset
          />
        )}
        <EnhancedLabeledSelect
          label="Status:"
          options={statusOptions}
          value={[{ id: selectedStatus ?? '' }]}
          onChange={onStatusChange}
          inline
          inset
        />
        <EnhancedLabeledSelect
          label="Origen:"
          options={commandOriginOptions}
          value={[{ id: selectedCommandOrigin ?? '' }]}
          onChange={onOriginChange}
          inline
          inset
        />
        <DatePicker
          placeholder="Fecha inicio - Fecha fin"
          maxDate={new Date()}
          onClose={() => {
            if (!selectedCommandsDateRange || !selectedCommandsDateRange[0] || !selectedCommandsDateRange[1]) {
              onChangeDates(undefined);
            }
          }}
          range
          size="compact"
          value={[startDate, endDate]}
          onChange={({ date: dates }) => {
            if (dates instanceof Array) {
              if (dates[0] && dates[1]) {
                onChangeDates([dates[0].getTime(), dates[1].getTime()]);
              } else if (dates[0]) {
                onChangeDates([dates[0].getTime(), null]);
              }
            }
          }}
        />
      </FlexColumn>
    </Filter>
  );
}

export default memo(CommandFilters);
