import { FormControl } from "baseui/form-control"
import { FlexColumn } from "./FlexColumn"
import { useStyles } from "../../hooks/useStyles";
import { Input } from "baseui/input";
import { useEffect, useMemo, useState } from "react";
import {validate as validateEmail} from 'email-validator';
import useUpdateEffect from "../../hooks/useUpdateEffect";

type Props = {
  value?: string;
  onChange: (email: string) => void;
  required?: boolean;
  disabled?: boolean;
  isEmailValid?: (valid: boolean) => void;
  caption?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  label?: React.ReactNode;
  placeholder?: string;
  readonly?: boolean;
  startEnhancer?: React.ReactNode;
  size?: 'compact' | 'mini' | 'default' | 'large';
}
export function LabeledEmailInput({ 
  label = 'Correo',
  value,
  onChange,
  required = false,
  disabled = false,
  isEmailValid,
  caption,
  endEnhancer,
  placeholder,
  readonly,
  startEnhancer,
  size = 'compact',
}:Readonly<Props>) {
  const { css, theme } = useStyles();
  const [isValid, setIsValid] = useState(false);
  const [isVisited, setIsVisited] = useState(false);
  const shouldShowError = !isValid && (required ? isVisited : true);

  useEffect(() => {
    if (!value) {
      setIsValid(!required)
      return
    }
    setIsValid(validateEmail(value));
  }, [value])

  useUpdateEffect(() => {
    if (isEmailValid) {
      isEmailValid(isValid);
    }
  }, [isValid])

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const {value} = event.currentTarget;
    onChange(value.trim())
  };

  const errorMsg = useMemo(() => {
    if (shouldShowError) {
      if (required && !value) return 'Este campo es requerido';
      return 'Ingrese un correo valido'
    }
  }, [isValid, isVisited])
  
  return (
    <FlexColumn
      gap={theme.sizing.scale200}
      classNames={css({ width: '100%' })}
    >
      <FormControl
        label={label && `${label} ${required ? '*' : ''}`}
        caption={caption}
        disabled={disabled}
        overrides={{
          LabelContainer: {
            style: {
              marginTop: 0,
              marginBottom: 0,
            },
          },
          ControlContainer: {
            style: { marginBottom: 0 },
          },
          Caption: {
            style: {
              marginTop: theme.sizing.scale200,
              marginBottom: 0,
            },
          },
        }}
        error={errorMsg}
      >
        <Input
          value={value}
          placeholder={placeholder}
          readOnly={readonly}
          onChange={onInputChange}
          onBlur={() => setIsVisited(true)}
          size={size}
          error={shouldShowError}
          endEnhancer={endEnhancer}
          startEnhancer={startEnhancer}
          required={required}
        />
      </FormControl>
    </FlexColumn>
  )
}