import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { WEB_SERVICE_LOGS_TABLE_COLUMNS_NAME } from '../../constants/logs';
import { WS_TYPE } from '../../constants/webService';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import { useMoment } from '../../hooks/useMoment';
import { getWebServiceLogs } from '../../services/logsService';
import { wsTypeState } from '../../storage/logs';
import { MinimalWebServiceLog } from '../../types/webServiceLogs';
import { ListWrapper } from '../shared/ListWrapper';
import { EllipsedText } from '../ui/EllipsedText';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledTooltip } from '../ui/StyledTooltip';
import WebServiceLogDetailModal from './modal/WebServiceLogDetailModal';
import useUpdateEffect from '../../hooks/useUpdateEffect';

type Props = {
  selectedClientId?: string;
  selectedSubClientId?: string;
  search?: string;
  reloadPage?: boolean;
};
export function WebServiceLogList({ selectedClientId, selectedSubClientId, search, reloadPage }: Readonly<Props>) {
  const [webServiceLogs, setWebServiceLogs] = useState<MinimalWebServiceLog[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const wsType = useRecoilValue(wsTypeState);
  const [selectedWebServiceLogId, setSelectedWebServiceLogId] = useState<string>();
  const { openModal, closeModal, showModal } = useModal();
  const { formatDateFromTs } = useMoment();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, wsType, selectedClientId, selectedSubClientId, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadWebServiceLogsTableData = async () => {
      const response = await getWebServiceLogs(selectedClientId, selectedSubClientId, page, search, wsType);
      if (response?.error) {
        setWebServiceLogs(undefined);
        setTotalItems(0);
      } else {
        setWebServiceLogs(response?.webServiceLogs);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadWebServiceLogsTableData();
  }, [page]);

  const openWebServiceLogModal = (webServiceLogId: string) => {
    setSelectedWebServiceLogId(webServiceLogId);
    openModal();
  };

  const closeWebServiceLogModal = (updateTable?: boolean) => {
    setSelectedWebServiceLogId(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={WEB_SERVICE_LOGS_TABLE_COLUMNS_NAME}
        data={webServiceLogs?.map((webServiceLog, i) => {
          return {
            items: [
              WS_TYPE[webServiceLog.wsType],
              <EllipsedText key={`ws-ident-${i}`}>{webServiceLog.asset.device?.ident ?? '-'}</EllipsedText>,
              formatDateFromTs(+webServiceLog.created),
              <StyledTooltip
                key={`ws-action-${i}`}
                content={'Ver detalles'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => openWebServiceLogModal(webServiceLog._id)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>,
            ],
          };
        })}
        hasData={webServiceLogs !== undefined}
        isEmpty={!webServiceLogs?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun log"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && <WebServiceLogDetailModal
        onClose={(updateTable) => closeWebServiceLogModal(updateTable)}
        isOpen={showModal}
        selectedWebServiceId={selectedWebServiceLogId}
      />}
    </>
  );
}
