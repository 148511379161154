import { useStyles } from '../../hooks/useStyles';
import { GenericComponent } from '../../types/components';

export function FullScreenContainer({ children }: GenericComponent) {
  const { css } = useStyles();

  return (
    <div
      className={css({
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      })}
    >
      {children}
    </div>
  );
}
