import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ASSETS_TABLE_COLUMNS_NAME } from '../../constants/asset';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import { getAssets } from '../../services/assetService';
import { assetTypeState } from '../../storage/assets';
import { Asset } from '../../types/asset';
import { ListWrapper } from '../shared/ListWrapper';
import { EllipsedText } from '../ui/EllipsedText';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledTooltip } from '../ui/StyledTooltip';
import AssetDetailModal from './modal/AssetDetailModal';
import { useMoment } from '../../hooks/useMoment';
import { ParagraphXSmall } from 'baseui/typography';
import useUpdateEffect from '../../hooks/useUpdateEffect';

type Props = {
  selectedClientId?: string;
  selectedSubClientId?: string;
  search?: string;
  reloadPage?: boolean;
};
export function AssetList({ selectedClientId, selectedSubClientId, search, reloadPage }: Readonly<Props>) {
  const [assets, setAssets] = useState<Asset[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const assetType = useRecoilValue(assetTypeState);
  const [selectedAssetId, setSelectedAssetId] = useState<string>();
  const { openModal, closeModal, showModal } = useModal();
  const { formatDateFromTs } = useMoment();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, assetType, selectedClientId, selectedSubClientId, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadAssetsTableData = async () => {
      const response = await getAssets({
        assetType,
        page,
        clientId: selectedClientId,
        subClientId: selectedSubClientId,
        q: search,
      });
      if (response?.error) {
        setAssets(undefined);
        setTotalItems(0);
      } else {
        setAssets(response?.assets);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadAssetsTableData();
  }, [page]);

  const openAssetModal = (assetId: string) => {
    setSelectedAssetId(assetId);
    openModal();
  };

  const closeAssetModal = (updateTable?: boolean) => {
    setSelectedAssetId(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={ASSETS_TABLE_COLUMNS_NAME}
        data={
          assets?.map((asset, i) => {
            return {
              items: [
                <ParagraphXSmall margin={0} key={`asset-device-${i}`}>
                  <EllipsedText>{asset?.device?.ident ?? '-'}</EllipsedText>
                </ParagraphXSmall>,
                <ParagraphXSmall margin={0} key={`asset-type-${i}`}>
                  {asset?.assetType ?? '-'}
                </ParagraphXSmall>,
                <ParagraphXSmall margin={0} key={`asset-label-${i}`}>
                  <EllipsedText>{asset?.label ?? '-'}</EllipsedText>
                </ParagraphXSmall>,
                <ParagraphXSmall margin={0} key={`asset-client-${i}`}>
                  {asset?.client?.name ?? '-'}
                </ParagraphXSmall>,
                <ParagraphXSmall margin={0} key={`asset-subclient-${i}`}>
                  {asset?.subClient?.name ?? '-'}
                </ParagraphXSmall>,
                <ParagraphXSmall margin={0} key={`asset-cs-${i}`}>
                  {asset?.cs?.timestamp ? formatDateFromTs(asset.cs.timestamp) : '-'}
                </ParagraphXSmall>,
                <ParagraphXSmall margin={0} key={`asset-monitoring-${i}`}>
                  {asset?.monitoring ? 'Si' : 'No'}
                </ParagraphXSmall>,
                <ParagraphXSmall margin={0} key={`asset-deleted-${i}`}>
                  {asset?.deleted ? 'Si' : 'No'}
                </ParagraphXSmall>,
                <StyledTooltip
                  key={`asset-action-${i}`}
                  content={'Ver detalles'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openAssetModal(asset._id)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'visibility'} />
                  </Button>
                </StyledTooltip>,
              ],
            };
          }) ?? []
        }
        hasData={assets !== undefined}
        isEmpty={!assets?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun activo"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && <AssetDetailModal
        onClose={(updateTable) => closeAssetModal(updateTable)}
        isOpen={showModal}
        selectedAssetId={selectedAssetId}
      />}
    </>
  );
}
