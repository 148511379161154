import { StyledDivider } from 'baseui/divider';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { LabelSmall } from 'baseui/typography';
import { useEffect, useState } from 'react';
import { Navigate, generatePath, useNavigate, useParams } from 'react-router-dom';
import { CLIENTS_PAGE, CLIENT_PAGE, SUB_CLIENT_DETAIL_PAGE } from '../../constants/app';
import { ASSETS_SECTION, DETAIL_SECTION, SUBACCOUNTS_SECTION, WEB_SERVICES_SECTION } from '../../constants/subClients';
import { useStyles } from '../../hooks/useStyles';
import { getSubClient } from '../../services/subClientService';
import { SubClient } from '../../types/subClient';
import { AssetsTab } from '../subClient/assets/AssetsTab';
import { DetailTab } from '../subClient/detail/DetailTab';
import { SubAccountsTab } from '../subClient/subAccounts/SubAccountsTab';
import { WebServicesTab } from '../subClient/webServices/WebServicesTab';
import { FlexRow } from '../ui/FlexRow';
import { FullContainer } from '../ui/FullContainer';
import { Loading } from '../ui/Loading';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledRouterLink } from '../ui/StyledRouterLink';

export function SubClientPage() {
  const {
    css,
    theme,
    overrides: { tabOverrides, tabsOverrides },
  } = useStyles();
  const { subClientId, tabName } = useParams();
  const navigate = useNavigate();
  const [subClient, setSubClient] = useState<SubClient>();
  const [reloadPage, setReloadPage] = useState(false);

  useEffect(() => {
    if (!subClientId) return;

    const loadSubClient = async () => {
      const response = await getSubClient(subClientId);
      if (!response.error && response.subClient) {
        setSubClient(response.subClient);
      } else {
        navigate(CLIENTS_PAGE.route, { replace: true });
      }
    };

    loadSubClient();
  }, [subClientId, reloadPage]);

  if (!subClientId) {
    return (
      <Navigate
        to={CLIENTS_PAGE.route}
        replace
      />
    );
  }

  if (!tabName) {
    return (
      <Navigate
        to={generatePath(SUB_CLIENT_DETAIL_PAGE.route, { subClientId, tabName: DETAIL_SECTION })}
        replace={false}
      />
    );
  }

  const activeKey = tabName;

  return (
    <FullContainer verticalFlex>
      {!subClient && <Loading />}
      {subClient && (
        <>
          <FlexRow
            gap={0}
            classNames={css({
              padding: theme.sizing.scale500,
              gap: theme.sizing.scale500,
              boxSizing: 'border-box',
              alignItems: 'center',
            })}
          >
            <StyledRouterLink to={CLIENTS_PAGE.route}>
              <LabelSmall>Clientes</LabelSmall>
            </StyledRouterLink>
            <MaterialIcon
              name={'navigate_next'}
              size="mini"
            />
            <StyledRouterLink to={generatePath(CLIENT_PAGE.route, { clientId: subClient.client._id })}>
              <LabelSmall>Cliente</LabelSmall>
            </StyledRouterLink>
            <MaterialIcon
              name={'navigate_next'}
              size="mini"
            />
            <LabelSmall>Subcliente</LabelSmall>
          </FlexRow>
          <StyledDivider
            style={{ width: '100%', margin: 0 }}
            $size="cell"
          />
          <Tabs
            activeKey={activeKey}
            onChange={({ activeKey }) => {
              const route = generatePath(SUB_CLIENT_DETAIL_PAGE.route, { subClientId, tabName: activeKey });
              navigate(route, { replace: false });
            }}
            activateOnFocus
            overrides={tabsOverrides}
          >
            <Tab
              title="Detalle"
              overrides={tabOverrides}
              key={DETAIL_SECTION}
            >
              <DetailTab
                subClient={subClient}
                onReloadPage={() => setReloadPage(!reloadPage)}
              />
            </Tab>
            <Tab
              title="Activos"
              overrides={tabOverrides}
              key={ASSETS_SECTION}
            >
              <AssetsTab subClient={subClient} />
            </Tab>
            <Tab
              title="Subcuentas"
              overrides={tabOverrides}
              key={SUBACCOUNTS_SECTION}
            >
              <SubAccountsTab subClient={subClient} />
            </Tab>
            <Tab
              title="Web Services"
              overrides={tabOverrides}
              key={WEB_SERVICES_SECTION}
            >
              <WebServicesTab subClient={subClient} />
            </Tab>
          </Tabs>
        </>
      )}
    </FullContainer>
  );
}
