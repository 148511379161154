import { useState, useEffect } from "react";
import { useStyles } from "../../hooks/useStyles";
import { InstallationCore } from "../../types/installation";
import { useLoading } from "../../hooks/useLoading";
import { getInstallationsList } from "../../services/installationService";
import { FlexColumn } from "../ui/FlexColumn";
import { EmptyState } from "../ui/EmptyState";
import { Loading } from "../ui/Loading";
import { useMoment } from "../../hooks/useMoment";
import { FlexRow } from "../ui/FlexRow";
import { generatePath, useNavigate } from "react-router-dom";
import { Button } from "baseui/button";
import { MaterialIcon } from "../ui/MaterialIcon";
import { StyledTooltip } from "../ui/StyledTooltip";
import { INSTALLATIONS_DETAIL_PAGE } from "../../constants/app";
import { useFeedback } from "../../hooks/useFeedback";
import { useRecoilState, useRecoilValue } from "recoil";
import { installationCampaignState, installationDateRangeState, installationDownloadListState, installationFilterGDLState, installationFilterStatusState, installationIvrState, installationReloadListState, installationSearchState, sortInstallationState, tableColumnsState } from "../../storage/Installations";
import { StyledPaginatedTable } from "../shared/StyledPaginatedTable";
import { ParagraphXSmall } from "baseui/typography";
import { StyledLink } from 'baseui/link'

export function InstallationsList() {
  const { css, classes, theme } = useStyles();
  const [installations, setInstallations] = useState<InstallationCore[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [download, setDownload] = useRecoilState(installationDownloadListState);
  const { formatDateFromTs } = useMoment();
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const navigate = useNavigate();
  const [installationTableColumns, setInstallationsTableColumns] = useRecoilState(tableColumnsState);
  const sort = useRecoilValue(sortInstallationState);
  const reloadPage = useRecoilValue(installationReloadListState);
  const status = useRecoilValue(installationFilterStatusState);
  const gdlStatus = useRecoilValue(installationFilterGDLState);
  const search = useRecoilValue(installationSearchState);
  const dateRange = useRecoilValue(installationDateRangeState);
  const campaign = useRecoilValue(installationCampaignState);
  const ivr = useRecoilValue(installationIvrState);

  useEffect(() => {
    if (initialLoad) return;
    setPage(0);
  }, [reloadPage, search, status, sort, campaign, ivr, gdlStatus]);

  useEffect(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      setPage(0);
    } else if (!dateRange) {
      setPage(0)
    }
  }, [dateRange])

  const loadInstallation = async () => {
    startLoading();
    const { installations, error, total } = await getInstallationsList(
      page, 
      search, 
      status,
      sort?.key,
      sort?.sortDirection?.toLowerCase(),
      dateRange && dateRange[0],
      dateRange && dateRange[1],
      campaign,
      ivr === 'true' ? true : ivr === 'false' ? false : undefined,
      gdlStatus,
      download
    );
    if (!error) {
      if(download) {
        showPositiveFeedback('Se esta generando el archivo...')
        setDownload(false);
      } else {
        setInstallations(installations);
        setTotalItems(total);
      }
    } else {
      showFailFeedback(error);
      if (!download) {
        setInstallations(undefined);
        setTotalItems(0);
      } else {
        setDownload(false);
      }
    }
    stopLoading();
  };

  useEffect(() => {
    if (!download) return;
    loadInstallation();
  }, [download]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    loadInstallation();
    setInitialLoad(false);
  }, [page]);

  const handleSort = (column: string) => {
    setInstallationsTableColumns((prev) => {
      return prev.map((prevColumn) => {
        if (column === prevColumn.label) 
          return {
            ...prevColumn,
            sortDirection: !prevColumn.sortDirection ? 'ASC' : (prevColumn.sortDirection === 'ASC' ? 'DESC' : undefined)
          };
        else {
          return {
            ...prevColumn,
            sortDirection: undefined
          }
        }
      })
    })
  };

  return (
    <FlexColumn classNames={`${classes.flexFill} ${css({ overflow: 'hidden' })}`}>
      {!installations && !loading && <EmptyState title="Todavía no tienes ninguna instalación" />}
      {!installations && loading && <Loading />}
      {installations && (
        <>
          {!!installations.length && (
            <StyledPaginatedTable 
              columns={installationTableColumns}
              onSort={handleSort}
              data={installations.map((installation) => {
                return {
                  items: [
                    <ParagraphXSmall margin={0} key={`insurance-company-${installation._id}`}>
                      {installation.origin || '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`vin-${installation._id}`}>
                      {installation.vin || '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`fch-asignado-${installation._id}`}>
                      {installation.fchAsignado ? formatDateFromTs(installation.fchAsignado, "DD/MM/yyyy") : '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`fch-proceso-${installation._id}`}>
                      {installation.fchEnProceso ? formatDateFromTs(installation.fchEnProceso, "DD/MM/yyyy") : '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`fch-instalado-${installation._id}`}>
                      {installation.fchInstaladoFSM ? formatDateFromTs(installation.fchInstaladoFSM, "DD/MM/yyyy") : '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`estatus-instalacion-${installation._id}`}>
                      {installation.estatusInstalacion.descripcion || '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`gdl-status-${installation._id}`}>
                      {installation.gdlStatus || '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`external-id-${installation._id}`}>
                      {installation.externalId?.length === 24 ? 
                        <StyledLink href={`https://fsm-instalaciones.gorilamx.com/trabajos/?jobId=${installation.externalId}`} target="_blank">
                          {installation.externalId}
                        </StyledLink>
                      : installation.externalId || '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`contratante-${installation._id}`}>
                      {installation.contratante || '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`no-contacts-${installation._id}`}>
                      {installation.contactAttempts || '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`last-contact-${installation._id}`}>
                      {installation.lastContactAttempt ? formatDateFromTs(installation.lastContactAttempt) : '-'}
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`is-ivr-${installation._id}`}>
                      {installation.isIVR === true ? 'Unitario' : installation.isIVR === false ? 'Pyme' : '-' }
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`last-log-${installation._id}`}>
                      {installation.lastLog || '-' }
                    </ParagraphXSmall>,
                    <ParagraphXSmall margin={0} key={`last-modified-by-${installation._id}`}>
                      {installation.lastModifiedBy || '-' }
                    </ParagraphXSmall>,
                    <FlexRow 
                      key={`details-${installation._id}`} 
                      classNames={css({ justifyContent: 'center', width: '100%'})}
                      gap={theme.sizing.scale200}
                    >
                      <StyledTooltip
                        content={'Ver detalles'}
                        showArrow={false}
                      >
                        <Button
                          size="mini"
                          shape="square"
                          onClick={() => {
                            const route = generatePath(INSTALLATIONS_DETAIL_PAGE.route, { installationId: installation._id });
                            navigate(route, { replace: false });
                          }}
                          kind="tertiary"
                        >
                          <MaterialIcon name={'visibility'} size="18px" />
                        </Button>
                      </StyledTooltip>
                    </FlexRow>
                  ],
                }
              })}
              totalItems={totalItems}
              page={page}
              onPageChange={setPage}
              loading={loading}
            />
          )}
          {!installations.length && (
            <EmptyState
              title="No se encontraron resultados"
              description="Intenta con otra busqueda"
            />
          )}
        </>
      )}
    </FlexColumn>
  );
}
