import { useStyles } from '../../hooks/useStyles';
import { GenericComponent } from '../../types/components';

export function Centered({ children }: GenericComponent) {
  const { css } = useStyles();

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      })}
    >
      {children}
    </div>
  );
}
