import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { CommunicationsReport } from '../../../types/report';
import { FlexColumn } from '../../ui/FlexColumn';
import { FlexRow } from '../../ui/FlexRow';
import { CommsReportInfo } from '../CommsReportInfo';

type Props = {
  isOpen: boolean;
  communicationsReport: CommunicationsReport;
  onClose: (update?: boolean) => void;
};

function CommsReportDetailModal({ onClose, isOpen, communicationsReport }: Props) {
  const { css, theme } = useStyles();

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      <FlexRow
        gap={0}
        classNames={css({
          justifyContent: 'space-between',
          margin: '6px 2rem 0px 0px',
        })}
      >
        <ModalHeader
          className={css({
            marginBottom: '0 !important',
          })}
        >
          {communicationsReport.ident}
        </ModalHeader>
      </FlexRow>
      <ModalBody>
        <FlexColumn
          classNames={css({
            width: '70vw',
            maxWidth: '1200px',
            minWidth: '350px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
            overflowY: 'auto',
            gap: theme.sizing.scale900,
          })}
        >
          <CommsReportInfo communicationsReport={communicationsReport} />
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(CommsReportDetailModal);
