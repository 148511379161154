import { memo, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useStyles } from "../../../hooks/useStyles";
import { mapViewState } from "../../../storage/map";
import { useCamera } from "../../../hooks/useCamera";
import { mapDevicesAssetGeoJson, selectAssetState } from "../../../storage/assets";
import { Marker, useMap } from "react-map-gl";
import useSupercluster from "use-supercluster";
import { FixedMarkerOverrides, FloatingMarker } from "baseui/map-marker";
import { isValidLatitude, isValidLongitude } from "../../../utils/map";

function AssetClusters() {
  const { map } = useMap();
  const { css, theme } = useStyles();
  const { zoomTo, fitToLngLats } = useCamera();
  const viewState = useRecoilValue(mapViewState);
  const mapCurrentStatesGeojson = useRecoilValue(mapDevicesAssetGeoJson);
  const bounds = map ? (map.getBounds().toArray().flat() as [number, number, number, number]) : undefined;
  const selectAsset = useRecoilValue(selectAssetState);

  useEffect(() => {
    if (!selectAsset?.cs?.position_latitude && !selectAsset?.cs?.position_longitude) return;
    let points = [[selectAsset?.cs?.position_longitude, selectAsset?.cs?.position_latitude]]
    selectAsset.secondariesCS?.map((device) => {
      points.push([device.position_longitude, device.position_latitude])
    })
    points = points.filter((point) => {
      return isValidLatitude(point[1]) && isValidLongitude(point[0]);
    })
    fitToLngLats(points);
  }, [selectAsset]);


  const { clusters } = useSupercluster({
    points: mapCurrentStatesGeojson,
    bounds: bounds,
    zoom: viewState.zoom,
    options: { radius: 50, maxZoom: 19 },
  });

  const floatingMarkerOverrides: FixedMarkerOverrides = {
    Root: {
      style: () => ({
        transform: `translateY(-50%)`,
      }),
    },
    OuterAnchor: {
      style: { backgroundColor: theme.colors.negative },
    },
  };

  return (
    <>
      {clusters.map((cluster) => {
        const [lng, lat] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount, ident } = cluster.properties;

        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              latitude={lat}
              longitude={lng}
              onClick={() => zoomTo({ lat, lng }, viewState.zoom + 3 < 12 ? viewState.zoom + 3 : 12)}
            >
              <div
                className={css({
                  ...theme.typography.LabelSmall,
                  backgroundImage: "url('/img/icons/map/cluster.png')",
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '50px',
                  height: '50px',
                  fontWeight: '700',
                })}
              >
                {pointCount}
              </div>
            </Marker>
          );
        }

        if (ident) {          
          return (
            <Marker
              latitude={lat}
              longitude={lng}
              key={ident}
            >
              <FloatingMarker
                label={ident}
                overrides={floatingMarkerOverrides}
                size="small"
                anchorType="circle"
              />
            </Marker>
          );
        }
        return null;
      })}
    </>
  );
}

export default memo(AssetClusters);
