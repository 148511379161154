import { useStyles } from '../../hooks/useStyles';
import { CommandsReportFiltersBar } from '../commands/offline/CommandsReportFiltersBar';
import { CommandsReportList } from '../commands/offline/CommandReportList';
import { FullContainer } from '../ui/FullContainer';

export function CommandsReportPage() {
  const { css, theme } = useStyles();

  return (
    <FullContainer
      verticalFlex
      classNames={`${css({
        padding: theme.sizing.scale400,
        gap: theme.sizing.scale500,
        boxSizing: 'border-box',
      })}`}
    >
      <CommandsReportFiltersBar />
      <CommandsReportList />
    </FullContainer>
  );
}
