import { useMemo, useState } from "react";
import { FlexRow } from "../../ui/FlexRow";
import { InstallationLogRequest, LogsSubTypes } from "../../../types/logs";
import { LabeledInput } from "../../ui/LabeledInput";
import { useRecoilValue } from "recoil";
import { logsTypesState } from "../../../storage/Installations";
import { useStyles } from "../../../hooks/useStyles";
import { StyledButton } from "../../ui/StyledButton";
import { FEEDBACK, FEEDBACK_PREFIXES } from "../../../constants/app";
import { useFeedback } from "../../../hooks/useFeedback";
import { useLoading } from "../../../hooks/useLoading";
import { createLog } from "../../../services/logsService";
import { NEW_LOG_DEFAULTS } from "../../../constants/installations";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { EnhancedLabeledSelect } from "../../ui/EnhancedLabeledSelect";


type Props = {
  installationId: string,
  reloadData: () => void
}
export function InstallationLogForm({ installationId, reloadData }: Readonly<Props>) {
  const { css } = useStyles();
  const [form, setForm] = useState<InstallationLogRequest>(NEW_LOG_DEFAULTS);
  const logsTypes = useRecoilValue(logsTypesState)
  const [logsSubTypes, setLogsSubTypes] = useState<LogsSubTypes[]>([]);
  const [selectedLogSubType, setSelectedLogSubType] = useState<LogsSubTypes>();
  const { showPositiveFeedback, showFailFeedback } = useFeedback();
  const { loading, startLoading, stopLoading } = useLoading();

  const canSubmit = !!form.logType && !!form.logSubType && (selectedLogSubType?.commentRequired ? !!form.comment : true)

  useUpdateEffect(() => {
    if (!form.logSubType) {
      setSelectedLogSubType(undefined);
      return;
    }
    const logSubType = logsSubTypes.find((log) => log.slug === form.logSubType);
    setSelectedLogSubType(logSubType);
  }, [form.logSubType])

  const logsTypesOptions = useMemo(() => {
    if(!logsTypes) return;
    return [
      ...Object.keys(logsTypes).map(
        (key) => ({ 
          id: key, label: logsTypes[key].label 
        })
      ),
    ];
  }, [logsTypes]);

  const logsSubTypesOptions = useMemo(() => {
    if(!logsSubTypes) return;
    return logsSubTypes.map((subType) => ({id: subType.slug, label: subType.label}));
  }, [logsSubTypes]);

  const onInputChange = (value: any, field: keyof InstallationLogRequest) => {
    setForm((prev) => ({ ...prev, [field]: value }));
  };

  const resetForm = () => {
    reloadData();
    setForm(NEW_LOG_DEFAULTS);
    setLogsSubTypes([])
    setSelectedLogSubType(undefined);
  }

  const onSubmitLog = async () => {
    if (!form) return;
    startLoading();
    const { error } = await createLog(installationId, form);
    if (!error) {
      showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.installationStatus));
      resetForm();
    } else {
      showFailFeedback(error);
    }
    stopLoading();
  }

  return (
    <FlexRow>
      <span className={css({ minWidth: '220px'})}>
        <EnhancedLabeledSelect 
          label="Tipo: *"
          options={logsTypesOptions}
          value={[{ id: form.logType }]}
          onChange={(params) => {
            const value = params.option?.id as string
            onInputChange(value, 'logType');  
            setLogsSubTypes(logsTypes![value].subTypes)
          }}
          inline
          inset
        />
      </span>
      <span className={css({ minWidth: '220px'})}>
        <EnhancedLabeledSelect 
          label="Mensaje: *"
          options={logsSubTypesOptions}
          value={[{ id: form.logSubType }]}
          onChange={(params) => {
            onInputChange(params.option?.id as string, 'logSubType');
          }}
          disabled={!form.logType}
          inline
          inset
        />
      </span>
      <LabeledInput
        placeholder={`Comentario ${selectedLogSubType?.commentRequired ? '*': ''}`}
        value={form.comment}
        onChange={(value) => onInputChange(value, 'comment')}
        disabled={!selectedLogSubType?.commentRequired}
      />
      <StyledButton
        kind="primary"
        onClick={onSubmitLog}
        isLoading={loading}
        disabled={!canSubmit}
      >
        Enviar
      </StyledButton>
    </FlexRow>
  )
}