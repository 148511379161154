import { atom } from 'recoil';

export const commsDateRangeState = atom<(number | null)[] | undefined>({
  key: 'app.reports.commsDateRange',
  default: undefined,
});

export const commsDownloadState = atom<boolean>({
  key: 'app.reports.comms.dowload',
  default: false,
});

//emissions
export const reportDateRangeState = atom<(number | null)[] | undefined>({
  key: "app.report.filter.dateRange",
  default: undefined,
});

export const reportReloadListState = atom<boolean>({
  key: "app.report.list.reload",
  default: false,
});
