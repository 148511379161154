import { useState } from 'react';
import { BrandsFiltersBar } from '../brands/BrandsFiltersBar';
import { BrandsList } from '../brands/BrandsList';
import { ContentContainer } from '../ui/ContentContainer';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';

export function BrandsPage() {
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <BrandsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <BrandsList
        search={search}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
