import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { useStyles } from '../../hooks/useStyles';
import { FlexColumn } from './FlexColumn';

type Props = {
  caption?: React.ReactNode;
  disabled?: boolean;
  endEnhancer?: React.ReactNode;
  error?: React.ReactNode;
  label?: React.ReactNode;
  name?: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  startEnhancer?: React.ReactNode;
  type?: string;
  value?: string | number;
  size?: 'compact' | 'mini' | 'default' | 'large';
  disabledViewOption?: boolean;
  onChange?: (value: string) => void;
  min?: number;
  max?: number;
  autocomplete?: string;
  maxLength?: number;
  onBlur?: () => void;
};
export function LabeledInput({
  caption,
  disabled,
  endEnhancer,
  error,
  label,
  name,
  onChange,
  placeholder,
  readonly,
  required,
  startEnhancer,
  type,
  value,
  size = 'compact',
  disabledViewOption,
  min,
  max,
  autocomplete,
  maxLength,
  onBlur
}: Readonly<Props>) {
  const { css, theme } = useStyles();

  return (
    <FlexColumn
      gap={theme.sizing.scale200}
      classNames={css({ width: '100%' })}
    >
      <FormControl
        label={label && `${label} ${required ? '*' : ''}`}
        caption={caption}
        error={
          !Array.isArray(error)
            ? error
            : () => {
                return (
                  <ul>
                    {error.map((e, index) => {
                      return <li key={`error-${index}`}>{e}</li>;
                    })}
                  </ul>
                );
              }
        }
        disabled={disabled}
        overrides={{
          LabelContainer: {
            style: {
              marginTop: 0,
              marginBottom: 0,
            },
          },
          ControlContainer: {
            style: { marginBottom: 0 },
          },
        }}
      >
        <Input
          endEnhancer={endEnhancer}
          name={name}
          placeholder={placeholder}
          readOnly={readonly}
          required={required}
          size={size}
          startEnhancer={startEnhancer}
          type={type}
          value={value}
          min={min}
          max={max}
          maxLength={maxLength}
          autoComplete={autocomplete}
          onChange={
            onChange
              ? (event) => {
                  onChange(event.target.value);
                }
              : undefined
          }
          overrides={{
            MaskToggleButton: {
              style: () => ({
                display: disabledViewOption && 'none',
              }),
            },
          }}
          onBlur={onBlur}
        />
      </FormControl>
    </FlexColumn>
  );
}
