import { PaginatedColumn } from '../types/app';

export const COMMS_REPORT_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Ident',
    key: 'ident',
  },
  {
    label: 'Modelo',
    key: 'deviceModel',
  },
  {
    label: 'No. Posiciones',
    key: 'positionsCount',
  },
  {
    label: 'Últime Posición',
    key: 'lastPosition',
  },
  {
    label: 'Cliente',
    key: 'client',
  },
  {
    label: 'Subcliente',
    key: 'subclient',
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];

export const COMMS_REPORT_OFFLINE_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Fecha inicio',
    key: 'from',
  },
  {
    label: 'Fecha fin',
    key: 'to',
  },
  {
    label: 'Estado',
    key: 'status',
  },
  {
    label: 'Creado',
    key: 'created',
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];

export const EMISSIONS_REPORTS_TABLE_COLUMNS_NAME = [
  {
    label: 'Nombre',
    key: 'name',
  },
  {
    label: 'Estado',
    key: 'status',
  },
  {
    label: 'Creado',
    key: 'created',
  },
  {
    label: '',
    key: 'actions',
  },
];

export const REPORTS_PANELS_TYPES: { [key: string]: string } = {
  'salud-flota': 'https://06sgh9gzsl.execute-api.us-east-1.amazonaws.com/prod/daludplataforma-embeded',
  'resultados-robos': 'https://mn5agbxqfj.execute-api.us-east-1.amazonaws.com/prod/robosqualitas-embeded',
  emisiones: 'https://3daulvqyt2.execute-api.us-east-1.amazonaws.com/prod/emisionesgorila-embeded',
  instalaciones: 'https://1ntc5obur3.execute-api.us-east-1.amazonaws.com/prod/FSM_REPORTES',
};
