import { Button } from 'baseui/button';
import { Checkbox } from 'baseui/checkbox';
import { OnChangeParams } from 'baseui/select';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useMoment } from '../../hooks/useMoment';
import { useStyles } from '../../hooks/useStyles';
import { assetsTypesState } from '../../storage/assets';
import { Asset, AssetMinimal } from '../../types/asset';
import { DeviceForm } from '../devices/DeviceForm';
import { ClientSelect } from '../shared/selects/ClientSelect';
import { DeviceSelect } from '../shared/selects/DeviceSelect';
import { SubClientSelect } from '../shared/selects/SubClientSelect';
import { FlexColumn } from '../ui/FlexColumn';
import { FlexRow } from '../ui/FlexRow';
import { LabeledInput } from '../ui/LabeledInput';
import { LabeledSelect } from '../ui/LabeledSelect';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledTooltip } from '../ui/StyledTooltip';

type Props = {
  asset?: Asset | AssetMinimal;
  clientId?: string;
  subClientId?: string;
  hasInstallation: boolean;
  editingAsset: AssetMinimal;
  onChange: React.Dispatch<React.SetStateAction<AssetMinimal>>;
};
export function AssetFormFields({
  asset,
  editingAsset,
  onChange,
  clientId,
  subClientId,
  hasInstallation,
}: Readonly<Props>) {
  const { css } = useStyles();
  const editMode = !!asset && !!asset._id && asset?._id !== 'installation';
  const assetTypes = useRecoilValue(assetsTypesState);
  const [createDevice, setCreateDevice] = useState(false);
  const { formatDateFromTs } = useMoment();  

  const assetTypeOptions = useMemo(() => {
    /*
      ToDo: 
        uncomment once the new types have been added and their
        new rules for the components have been defined
        
        if (!assetTypes) return;
        return assetTypes.map((assetType) => ({ id: assetType.key, label: assetType.label }));
    */
    return [
      {
          label: "Contenedor",
          id: "container"
      },
      {
          label: "Vehículo",
          id: "vehicle"
      },
    ]
  }, [assetTypes]);

  const onInputChange = (value: string | boolean | number | undefined | null | {}, field: keyof AssetMinimal) => {
    onChange((prev) => ({ ...prev, [field]: value }));
  };

  const onDateChange = (value: string | undefined | null, field: keyof AssetMinimal) => {
    if (value) {
      const [year, month, day] = value.split('-').map(Number);
      onChange((prev) => ({ ...prev, [field]: +new Date(year, month - 1, day) }));
    } else {
      onChange((prev) => ({ ...prev, [field]: value }));
    }
  };

  const onSecondariesDevicesChange = (value: string[] | undefined) => {
    onChange((prev) => ({
      ...prev!,
      secondaries: value
        ? value.map((val) => {
            return {
              _id: val,
              ident: '',
            };
          })
        : undefined,
    }));
  };

  const onAssetTypeChange = (params: OnChangeParams) => {
    params.option?.id
      ? onInputChange(params.option.id as 'vehicle' | 'container' | 'phone', 'assetType')
      : onInputChange(undefined, 'assetType');
  };

  return (
    <FlexColumn
      classNames={css({
        overflow: 'auto',
        height: '100%',
      })}
    >
      {createDevice && !hasInstallation && (
        <FlexRow classNames={css({ width: '100%', alignItems: 'end' })}>
          <DeviceForm
            afterSave={() => {
              setCreateDevice(false);
              onInputChange({ _id: editingAsset.device?._id }, 'device');
            }}
            onCancel={() => {
              setCreateDevice(false);
              onInputChange({ _id: editingAsset.device?._id }, 'device');
            }}
            inline
          />
        </FlexRow>
      )}
      {!createDevice && !hasInstallation && (
        <FlexRow classNames={css({ width: '100%', alignItems: 'end' })}>
          <DeviceSelect
            device={asset?.device}
            selectedDeviceId={editingAsset?.device?._id}
            onChangeDevice={(deviceId) => onInputChange({ _id: deviceId }, 'device')}
            isFilter={false}
          />
          <StyledTooltip
            content={'Nuevo'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={() => setCreateDevice(true)}
              kind="tertiary"
            >
              <MaterialIcon
                name={'add'}
                size="compact"
              />
            </Button>
          </StyledTooltip>
        </FlexRow>
      )}
      {!hasInstallation && (
        <DeviceSelect
          label="Dispositivos secundarios"
          devices={asset?.secondaries}
          selectedDevicesIds={editingAsset?.secondaries?.map((d) => d._id)}
          onChangeDevices={onSecondariesDevicesChange}
          isMulti
          isFilter={false}
        />
      )}
      <FlexRow breakPoint="isXSmall">
        <LabeledInput
          label="Etiqueta:"
          value={editingAsset?.label}
          onChange={(value) => onInputChange(value, 'label')}
          required
        />
        <LabeledSelect
          label="Tipo de activo"
          options={assetTypeOptions}
          value={[{ id: editingAsset?.assetType }]}
          onChange={onAssetTypeChange}
          required
        />
      </FlexRow>
      <FlexRow breakPoint="isXSmall">
        {!clientId && (
          <div className={css({flex: 1 , overflow: 'hidden'})}>
            <ClientSelect
              client={editingAsset?.client?.name ? editingAsset.client : undefined}
              selectedClientId={editingAsset?.client?._id}
              onChangeClient={(client) => {
                onInputChange(client, 'client')
                onInputChange(undefined, 'subClient')
              }}
              isFilter={false}
              required
            />
          </div>
          )}

        {!subClientId && (
          <div className={css({flex: 1, overflow: 'hidden'})}> 
            <SubClientSelect
              subClient={editingAsset?.subClient?.name ? editingAsset.subClient : undefined}
              selectedClientId={editingAsset?.client?._id}
              selectedSubClientId={editingAsset?.subClient?._id}
              onChangeSubClient={(subClient) => onInputChange(subClient, 'subClient')}
              isFilter={false}
              disabled={!editingAsset?.client?._id}
              autoSelectFirst={!editMode && !editingAsset?.subClient?._id}
              required
            />
          </div>
        )}
      </FlexRow>
      <LabeledInput
        label="Poliza:"
        value={editingAsset?.insuranceNumber}
        onChange={(value) => onInputChange(value, 'insuranceNumber')}
      />
      <FlexRow breakPoint="isXSmall">
        <LabeledInput
          label="Inicio Vigencia"
          value={
            editingAsset?.insuranceStartDate
              ? formatDateFromTs(editingAsset?.insuranceStartDate, 'yyyy-MM-DD')
              : undefined
          }
          type="date"
          onChange={(value) => {
            onDateChange(value, 'insuranceStartDate');
          }}
        />
        <LabeledInput
          label="Fin de Vigencia"
          value={
            editingAsset?.insuranceEndDate ? formatDateFromTs(editingAsset?.insuranceEndDate, 'yyyy-MM-DD') : undefined
          }
          type="date"
          onChange={(value) => {
            onDateChange(value, 'insuranceEndDate');
          }}
        />
      </FlexRow>
      {!hasInstallation && (
        <FlexRow>
          <Checkbox
            checked={editingAsset?.deleted}
            onChange={() => onInputChange(!editingAsset?.deleted, 'deleted')}
          >
            Eliminado
          </Checkbox>
          <Checkbox
            checked={editingAsset?.monitoring}
            onChange={() => onInputChange(!editingAsset?.monitoring, 'monitoring')}
          >
            Seguimiento
          </Checkbox>
        </FlexRow>
      )}
    </FlexColumn>
  );
}
