import { useState, useEffect, useMemo, useRef } from "react";
import { useLoading } from "../../../hooks/useLoading";
import { DeviceModel } from "../../../types/deviceModel";
import { OnChangeParams } from "baseui/select";
import { useDebounceFunction } from "../../../hooks/useDebounce";
import { getDeviceModelsList } from "../../../services/deviceService";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { EnhancedLabeledSelect } from "../../ui/EnhancedLabeledSelect";


type Props = {
  deviceModel?: DeviceModel,
  selectedDeviceModelId?: string,
  onChangeDeviceModel: (deviceModelId?: string, deviceBrandId?: string) => void
  isFilter?: boolean,
  label?: string,
  deviceBrandId?: string,
  disabled?: boolean,
  inline?: boolean,
  required?: boolean,
}
export function DeviceModelSelect({
  selectedDeviceModelId, 
  onChangeDeviceModel,
  deviceModel,
  isFilter = true,
  label = 'Modelo:',
  deviceBrandId,
  disabled = false,
  inline = false,
  required = false
}: Readonly<Props>) {
  const [devicesModelsList, setDevicesModelsList] = useState<DeviceModel[]>();
  const {loading, startLoading, stopLoading} = useLoading();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [selectedDeviceModelOption, setSelectedDeviceModelOption] = useState<{ 
    id: string, 
    label: string,
    brandId: string,
  }>();
  const requestIdRef = useRef(0);

  useUpdateEffect(() => {
    setPage(0)
  }, [search, disabled])

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    const loadDevicesModels = async () => {
      startLoading();
      const requestId = ++requestIdRef.current;
      const { models, error, hasNext } = await getDeviceModelsList(page, search, deviceBrandId);
      if (requestId === requestIdRef.current) {
        if (!error && models) {
          if (page > 1 && devicesModelsList) {
            setDevicesModelsList([...devicesModelsList, ...models]);
            setHasNextPage(hasNext);
          } else {
            setDevicesModelsList(models);
            setHasNextPage(hasNext);
          }
        } else {
          setDevicesModelsList(undefined);
          setHasNextPage(false);
        }
        stopLoading();
      }
    };
    if(disabled) return;
    loadDevicesModels();
  }, [page]);

  const deviceModelOptions = useMemo(() => {
    if (!devicesModelsList || devicesModelsList.length === 0) return;
    const devicesModelsOptions: { id: string | undefined, label: string, brandId: string | undefined }[] = [
      ...devicesModelsList.map((deviceModel) => ({ 
        id: deviceModel._id, 
        label: deviceModel.name,
        brandId: deviceModel.brand._id 
      })),
    ];
    if (!required) {
      devicesModelsOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todas' : 'Ninguna',
        brandId: undefined
      });
    }
    if (deviceModel) {
      if (deviceModel._id && !devicesModelsOptions.find((c) => c.id === deviceModel._id))
        devicesModelsOptions.push({
          id: deviceModel._id,
          label: deviceModel.name,
          brandId: deviceModel.brand._id
        })
    }
    if (selectedDeviceModelOption) {
      if (!devicesModelsOptions.find((c) => c.id === selectedDeviceModelOption.id))
        devicesModelsOptions.push(selectedDeviceModelOption)
    }
    return devicesModelsOptions
  }, [devicesModelsList]);

  const onDeviceModelChange = (params: OnChangeParams) => {
    params.option?.id 
      ? onChangeDeviceModel(params.option.id as string, params.option.brandId as string) 
      : onChangeDeviceModel(undefined);
    setSelectedDeviceModelOption(params.option as {id: string, label: string, brandId: string});
    setSearch(undefined);
  };

  const fetchData = () => {   
    if (hasNextPage)
      setPage(page + 1);
  }
  
  const handleInputChange = useDebounceFunction(function(term: string) {
    setSearch(term)
  }, 500);


  return (
    <EnhancedLabeledSelect
      label={label}
      options={deviceModelOptions}
      value={[{ id: selectedDeviceModelId }]}
      onChange={onDeviceModelChange}
      onInputChange={(e) => {
        handleInputChange(e.target.value)
      }}
      inline={inline}
      inset={inline}
      fullWidth
      infiniteScroll
      fetchData={fetchData}
      fetchDataLoading={loading}
      hasNext={hasNextPage}
      onBlur={() => setSearch(undefined)}
      searchable
      disabled={disabled}
      required={required}
    />
  )
}