import { useStyles } from '../../hooks/useStyles';

type Props = {
  color: string;
  size?: number;
};

export function ColoredCircle({ color, size = 12 }: Props) {
  const { css } = useStyles();

  return (
    <span
      className={css({
        backgroundColor: color,
        borderRadius: '50%',
        display: 'inline-block',
        height: `${size}px`,
        width: `${size}px`,
      })}
    />
  );
}
