import { Button } from 'baseui/button';
import { ListItem, ListItemLabel } from 'baseui/list';
import { memo } from 'react';
import { useStyles } from '../../../hooks/useStyles';
import { AssetMinimal } from '../../../types/asset';
import { EllipsedText } from '../../ui/EllipsedText';
import { MaterialIcon } from '../../ui/MaterialIcon';

type Props = {
  data: {
    assets: AssetMinimal[];
    type: 'selectable' | 'deselectable';
    onClick: (assetId: string) => void;
    isLoaded: (index: number) => boolean;
  };
  index: number;
  style: React.CSSProperties;
};

function AssetItem({ data, index, style }: Props) {
  const { css, theme } = useStyles();
  const asset = data.assets[index];

  return (
    <span style={style}>
      <ListItem
        endEnhancer={() => (
          <Button
            size="mini"
            shape="round"
            kind="tertiary"
            onClick={() => data.onClick(asset._id)}
          >
            <MaterialIcon
              name={data.type === 'selectable' ? 'add' : 'remove'}
              size="mini"
            />
          </Button>
        )}
      >
        <ListItemLabel description={asset?.assetType}>
          <EllipsedText inline>{asset?.label || '-'}</EllipsedText>
        </ListItemLabel>
      </ListItem>
    </span>
  );
}

export default memo(AssetItem);
