import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { HeadingXSmall } from "baseui/typography";
import { useState, memo } from "react";
import { createPortal } from "react-dom";
import { useFeedback } from "../../../hooks/useFeedback";
import { useStyles } from "../../../hooks/useStyles";
import { FlexColumn } from "../../ui/FlexColumn";
import { FlexRow } from "../../ui/FlexRow";
import { StyledButton } from "../../ui/StyledButton";
import { useLoading } from "../../../hooks/useLoading";
import { FEEDBACK, FEEDBACK_PREFIXES } from "../../../constants/app";
import { StyledBanner } from "../../ui/StyledBanner";
import { Delete } from "baseui/icon";
import { LabeledPhoneInput } from "../../ui/LabeledPhoneInput";
import { addRemovePhoneToBl } from "../../../services/blackListService";

type Props = {
  onClose: (update?: boolean) => void;
  isOpen: boolean;
};
function AddedPhoneToBlModal({ onClose, isOpen }: Props) {
  const { theme, classes } = useStyles();
  const [phone, setPhone] = useState('');
  const { showPositiveFeedback, showFailFeedback } = useFeedback();
  const { loading, startLoading, stopLoading } = useLoading();
  const [error, setError] = useState('');

  const canSubmit = !!phone && phone.length === 10

  const addPhoneToBl = async () => {
    if (!phone) return
    startLoading();
    const { error } = await addRemovePhoneToBl(phone.trim(), true);
    if (!error) {
      showPositiveFeedback(FEEDBACK.added(FEEDBACK_PREFIXES.phone))
      resetModal(true);
    } else {
      showFailFeedback(error);
      setError(typeof error === 'string' ? error : error.toString());
    }
    stopLoading();
  }
  
  const resetModal = (update?: boolean) => {
    setPhone('');
    onClose(update);
  };
  
  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => resetModal()}
      isOpen={isOpen}
    >
      <ModalHeader>
        <FlexRow
          gap={theme.sizing.scale200}
          classNames={classes.centeredStart}
        >
          <HeadingXSmall margin={0}>Agregar teléfono a la blacklist</HeadingXSmall>
        </FlexRow>
      </ModalHeader>
      <ModalBody>
        <FlexColumn>
          {error && (
            <StyledBanner
              title="*Error*"
              kind="negative"
              action={{
                label: 'label',
                icon: () => <Delete />,
                onClick: () => {
                  setError('');
                },
              }}
            >
              {error}
            </StyledBanner>
          )}
          <LabeledPhoneInput 
            placeholder="Ingresa un número telefonico"
            value={phone}
            onChange={setPhone}
            type="simple"
            required
          />
        </FlexColumn>
      </ModalBody>
      <ModalFooter>
        <FlexRow
          gap={theme.sizing.scale300}
          classNames={classes.centeredEnd}
        >
          <StyledButton
            kind="tertiary"
            onClick={() => resetModal()}
          >
            Cancelar
          </StyledButton>
          <StyledButton
            onClick={addPhoneToBl}
            disabled={!canSubmit}
            isLoading={loading}
          >
            Añadir
          </StyledButton>
        </FlexRow>
      </ModalFooter>
    </Modal>,
    document.body
  );
}

export default memo(AddedPhoneToBlModal);
