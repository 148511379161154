import { AdminUser } from '../../types/user';
import { LabeledIconData } from '../ui/LabeledIconData';

type Props = {
  user: AdminUser;
};
export function UserInfo({ user }: Readonly<Props>) {
  const labelWidth = '190px';
  return (
    <>
      {user.name && (
        <LabeledIconData
          label="Nombre:"
          data={user.name}
          icon="person"
          labelWidth={labelWidth}
        />
      )}
      {user.email && (
        <LabeledIconData
          label="Correo electrónico:"
          data={user.email}
          icon="alternate_email"
          labelWidth={labelWidth}
        />
      )}
      {user.phoneNumber && (
        <LabeledIconData
          label="Teléfono :"
          data={user.phoneNumber}
          icon="phone_iphone"
          labelWidth={labelWidth}
        />
      )}
    </>
  );
}
