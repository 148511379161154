import { Page } from '../types/app';

export const LOGIN_PAGE: Page = {
  id: 'login',
  name: 'Inicio de sesión',
  route: '/login',
};
export const FORGOTTEN_PASSWORD: Page = {
  id: 'forgottenPassword',
  name: 'Contraseña olvidada',
  route: '/forgotten-password',
};
// admin pages
export const USERS_ADMIN_PAGE: Page = {
  id: 'adminUsers',
  name: 'Usuarios',
  icon: 'group',
  route: '/admin/usuarios',
  navKey: 'adminUsers',
};
// platform pages
export const CLIENTS_PAGE: Page = {
  id: 'clients',
  name: 'Clientes',
  icon: 'business_center',
  route: '/plataforma/clientes',
  navKey: 'clients',
};
export const CLIENT_PAGE: Page = {
  id: 'client',
  name: 'Cliente',
  route: '/plataforma/clientes/:clientId',
  navKey: 'clients',
  parentId: 'clients'
};
export const CLIENT_DETAIL_PAGE: Page = {
  id: 'clientDetail',
  name: 'Cliente',
  route: '/plataforma/clientes/:clientId/:tabName',
  navKey: 'clients',
  parentId: 'clients'
};
export const SUB_CLIENT_PAGE: Page = {
  id: 'subClient',
  name: 'Subcliente',
  route: '/plataforma/subclientes/:subClientId',
  parentId: 'clients'
};
export const SUB_CLIENT_DETAIL_PAGE: Page = {
  id: 'subClientDetail',
  name: 'Subcliente',
  route: '/plataforma/subclientes/:subClientId/:tabName',
  parentId: 'clients'
};
export const USERS_PAGE: Page = {
  id: 'users',
  name: 'Usuarios',
  icon: 'group',
  route: '/plataforma/usuarios',
  navKey: 'users',
};
export const ASSETS_PAGE: Page = {
  id: 'assets',
  name: 'Activos',
  icon: 'transportation',
  route: '/plataforma/activos',
  navKey: 'assets',
};
export const COMMS_REPORT_PAGE: Page = {
  id: 'commsReport',
  name: 'Reporte Comunicaciones',
  icon: 'description',
  route: '/plataforma/comunicaciones',
  navKey: 'commsReport',
};
export const COMMS_REPORT_OFFLINE_PAGE: Page = {
  id: 'commsReportOffline',
  name: 'Descargas Admin',
  icon: 'download',
  route: '/plataforma/comunicaciones/offline',
  navKey: 'commsReportOffline',
};
export const WEB_SERVICE_LOGS_PAGE: Page = {
  id: 'webServiceLogs',
  name: 'Web Services',
  icon: 'summarize',
  route: '/plataforma/web-services',
  navKey: 'webServiceLogs',
};
export const BULK_PAGE: Page = {
  id: 'bulk',
  name: 'Carga Masiva',
  icon: 'file_upload',
  route: '/plataforma/carga-masiva',
  navKey: 'bulk',
};

//vehicles pages
export const BRANDS_PAGE: Page = {
  id: 'brands',
  name: 'Marcas',
  icon: 'commute',
  route: '/vehiculos/marcas',
  navKey: 'brands',
};
export const SUB_BRANDS_PAGE: Page = {
  id: 'subBrands',
  name: 'Submarcas',
  icon: 'directions_car',
  route: '/vehiculos/submarcas',
  navKey: 'subBrands',
};
export const VERSIONS_PAGE: Page = {
  id: 'versions',
  name: 'Versiones',
  icon: 'label',
  route: '/vehiculos/versiones',
  navKey: 'versions',
};

//equipments pages
export const DEVICES_PAGE: Page = {
  id: 'devices',
  name: 'Dispositivos',
  icon: 'devices',
  route: '/equipos/dispositivos',
  navKey: 'devices',
};
export const DEVICE_MODELS_PAGE: Page = {
  id: 'devicesModels',
  name: 'Modelos',
  icon: 'device_hub',
  route: '/equipos/modelos',
  navKey: 'devicesModels',
};
export const DEVICE_MODEL_COMMANDS_PAGE: Page = {
  id: 'devicesModelCommands',
  name: 'Commandos',
  route: '/equipos/modelos/:modelId',
  navKey: 'devicesCommandsModels',
  parentId: 'devicesModels'
};
export const DEVICE_BRANDS_PAGE: Page = {
  id: 'devicesBrands',
  name: 'Marcas',
  icon: 'devices_other',
  route: '/equipos/marcas',
  navKey: 'devicesBrands',
};
export const COMMANDS_PAGE: Page = {
  id: 'commands',
  name: 'Comandos',
  icon: 'settings_remote',
  route: '/equipos/comandos',
  navKey: 'commands',
};
export const COMMANDS_REPORT_OFFLINE_PAGE: Page = {
  id: 'commandsOffline',
  name: 'Descargas',
  icon: 'download',
  route: '/equipos/reportes',
  navKey: 'commandsOffline',
};

// emissions pages
export const INSTALLATIONS_PAGE: Page = {
  id: 'installations',
  name: 'Emisiones',
  icon: 'home_repair_service',
  route: '/emisiones',
  navKey: 'installations',
};
export const INSTALLATIONS_DETAIL_PAGE: Page = {
  id: 'installationDetail',
  name: 'Emisiones',
  route: '/emisiones/:installationId',
  navKey: 'installation',
  parentId: 'installations'
};
export const PHONE_BLACKLIST_PAGE: Page = {
  id: 'phonesBl',
  name: 'Lista negra de teléfonos',
  icon: 'description',
  route: '/instalaciones/blacklist',
  navKey: 'phonesBl',
};
export const EMISSION_REPORT_PAGE: Page = {
  id: 'reports',
  name: 'Descargas',
  icon: 'download',
  route: '/instalaciones/reportes',
  navKey: 'reports',
};

// reports panels pages
export const REPORTS_PANELS_PAGE: Page = {
  id: 'reportsPanels',
  name: 'Reportes',
  route: '/reportes/:reportType',
  navKey: 'reportsPanels',
};
export const FLEET_HEALTH_REPORT_PANEL_PAGE: Page = {
  id: 'fleetHealthReport',
  name: 'Salud de Flota',
  icon: 'description',
  route: '/reportes/salud-flota',
  navKey: 'fleetHealthReport',
  parentId: 'reportsPanels'
};
export const THEFTS_REPORT_PANEL_PAGE: Page = {
  id: 'theftsReport',
  name: 'Resultados Robos',
  icon: 'description',
  route: '/reportes/resultados-robos',
  navKey: 'theftsReport',
  parentId: 'reportsPanels'
};
export const EMISSION_REPORT_PANEL_PAGE: Page = {
  id: 'emissionReport',
  name: 'Emisiones',
  icon: 'description',
  route: '/reportes/emisiones',
  navKey: 'emissionReport',
  parentId: 'reportsPanels'
};
export const INSTALLATION_REPORT_PANEL_PAGE: Page = {
  id: 'installationReport',
  name: 'Instalaciones',
  icon: 'description',
  route: '/reportes/instalaciones',
  navKey: 'installationReport',
  parentId: 'reportsPanels'
};

export const ROOT_PAGES: Page[] = [];

export const ALL_PAGES: Page[] = [
  LOGIN_PAGE,
  FORGOTTEN_PASSWORD,
  // admin section
  USERS_ADMIN_PAGE,
  // platform section
  CLIENTS_PAGE,
  ASSETS_PAGE,
  USERS_PAGE,
  COMMS_REPORT_PAGE,
  WEB_SERVICE_LOGS_PAGE,
  COMMS_REPORT_OFFLINE_PAGE,
  BULK_PAGE,
  CLIENT_PAGE,
  CLIENT_DETAIL_PAGE,
  SUB_CLIENT_PAGE,
  SUB_CLIENT_DETAIL_PAGE,
  // installations section
  INSTALLATIONS_PAGE,
  PHONE_BLACKLIST_PAGE,
  EMISSION_REPORT_PAGE,
  INSTALLATIONS_DETAIL_PAGE,
  // recovery section

  // vehicles section
  BRANDS_PAGE,
  SUB_BRANDS_PAGE,
  VERSIONS_PAGE,
  // equipments section
  DEVICE_BRANDS_PAGE,
  DEVICE_MODELS_PAGE,
  DEVICE_MODEL_COMMANDS_PAGE,
  DEVICES_PAGE,
  COMMANDS_PAGE,
  COMMANDS_REPORT_OFFLINE_PAGE,
  //reports section
  REPORTS_PANELS_PAGE,
  FLEET_HEALTH_REPORT_PANEL_PAGE,
  THEFTS_REPORT_PANEL_PAGE,
  EMISSION_REPORT_PANEL_PAGE,
  INSTALLATION_REPORT_PANEL_PAGE,
];

export const ADMIN_PAGE_ID = 'admin';
export const ADMIN_PAGE_IDS = [USERS_ADMIN_PAGE.id];
export const CHILDREN_ADMIN_PAGE_IDS: string[] = [];

export const PLATFORM_PAGE_ID = 'platform';
export const PLATFORM_PAGE_IDS = [
  CLIENTS_PAGE.id,
  USERS_PAGE.id,
  ASSETS_PAGE.id,
  COMMS_REPORT_PAGE.id,
  COMMS_REPORT_OFFLINE_PAGE.id,
  WEB_SERVICE_LOGS_PAGE.id,
  BULK_PAGE.id,
];
export const CHILDREN_PLATFORM_PAGE_IDS = [
  CLIENT_PAGE.id, 
  CLIENT_DETAIL_PAGE.id, 
  SUB_CLIENT_PAGE.id, 
  SUB_CLIENT_DETAIL_PAGE.id,
]

export const VEHICLE_PAGE_ID = 'vehicles';
export const VEHICLE_PAGE_IDS = [BRANDS_PAGE.id, SUB_BRANDS_PAGE.id, VERSIONS_PAGE.id];
export const CHILDREN_VEHICLE_PAGE_IDS: string[] = []

export const EMISSIONS_PAGE_ID = 'emissions';
export const EMISSIONS_PAGE_IDS = [INSTALLATIONS_PAGE.id, PHONE_BLACKLIST_PAGE.id, EMISSION_REPORT_PAGE.id];
export const CHILDREN_EMISSIONS_PAGE_IDS = [INSTALLATIONS_DETAIL_PAGE.id];

export const EQUIPMENT_PAGE_ID = 'equipments';
export const EQUIPMENT_PAGE_IDS = [
  DEVICE_BRANDS_PAGE.id,
  DEVICE_MODELS_PAGE.id,
  DEVICES_PAGE.id,
  COMMANDS_PAGE.id,
  COMMANDS_REPORT_OFFLINE_PAGE.id,
];
export const CHILDREN_EQUIPMENT_PAGE_IDS = [DEVICE_MODEL_COMMANDS_PAGE.id]

export const REPORTS_PANELS_PAGE_ID = 'reports';
export const REPORTS_PANELS_PAGE_IDS = [
  FLEET_HEALTH_REPORT_PANEL_PAGE.id,
  THEFTS_REPORT_PANEL_PAGE.id,
  EMISSION_REPORT_PANEL_PAGE.id,
  INSTALLATION_REPORT_PANEL_PAGE.id,
];
export const CHILDREN_REPORTS_PANELS_PAGE_IDS = [REPORTS_PANELS_PAGE.id];

export const SMALL_LIST_ITEM_HEIGHT = 50;
export const DEFAULT_LIST_ITEM_HEIGHT = 65;
export const LARGE_LIST_ITEM_HEIGHT = 80;
export const EXTRA_LARGE_LIST_ITEM_HEIGHT = 100;

export const SIDENAV_WIDTH = 76;
export const EXPANDED_SIDENAV_WIDTH = 270;

export const LIST_CARD_WIDTH = 300;
export const ASIDE_WIDTH = 300;

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const MOUNTH = 30 * DAY;

export const HISTORIC_REPORT_TIME_INTERVAL = 6; //value in months
export const ROUTE_REPORT_TIME_INTERVAL = 3; //value in months

export const FEEDBACK = {
  created: (prefix: string) => `${prefix} se creo con éxito.`,
  added: (prefix: string, name?: string) => `${prefix}${name ? ' "' + name + '" ' : ' '}se añadio con éxito.`,
  edited: (prefix: string, name: string = '') => `${prefix} "${name}" fue actualizado correctamente.`,
  deleted: (prefix: string, name: string = '') => `Se eliminó ${prefix.toLowerCase()} "${name}" correctamente.`,
  remove: (prefix: string, name?: string) =>
    `Se removio ${prefix.toLowerCase()}${name ? ' "' + name + '" ' : ' '}correctamente.`,
  failedCreation: (prefix: string) => `Ocurrió un error al crear ${prefix.toLowerCase()}, inténtalo nuevamente.`,
  failedEdition: (prefix: string, name: string = '') =>
    `Ocurrió un error al editar ${prefix.toLowerCase()} ${name}, inténtalo nuevamente.`,
  failedDeletion: (prefix: string, name: string = '') =>
    `Ocurrió un error al eliminar ${prefix.toLowerCase()} ${name}, inténtalo nuevamente.`,
};

export const FEEDBACK_PREFIXES = {
  subAccount: 'La subcuenta',
  webService: 'El servicio externo',
  asset: 'El activo',
  brand: 'La marca',
  subBrand: 'La submarca',
  version: 'La version',
  device: 'El dispositivo',
  deviceBrand: 'La marca del dispositivo',
  deviceModel: 'El modelo del dispositivo',
  deviceModelCommand: 'El commando del dispositivo',
  bulkFile: 'El archivo',
  user: 'El usuario',
  password: 'La contraseña de',
  subClient: 'El subcliente',
  client: 'El cliente',
  webhook: 'El webhook',
  installation: 'La instalación',
  emission: 'La emision',
  installationStatus: 'El estatus de la instalación',
  job: 'El trabajo',
  logs: 'El log',
  phone: 'El teléfono',
  tag: 'La tag',
};

export const REQUIRED_FIELD_TEXT = 'Este campo es requerido';
export const VELID_FIELD_TEXT = (example?: string, rangeExample?: string) =>
  `Ingrese un valor valido${example ? ', ejemplo: ' + example : rangeExample ? ', entre ' + rangeExample : ''}.`;
