import { useStyles } from '../../hooks/useStyles';

type Props = {
  children: string;
  maxLines?: number;
  breakAll?: boolean;
  style?: React.CSSProperties;
  inline?: boolean;
};

export function EllipsedText({ children, maxLines = 3, style, breakAll = false, inline = false }: Props) {
  const { classes } = useStyles();
  if (inline) {
    maxLines = 1;
  }
  return (
    <span
      className={classes.ellipseText({ maxLines, breakAll })}
      style={style}
    >
      {children}
    </span>
  );
}
