import { atom, selector } from 'recoil';
import { EMPTY_ADMIN_USER, NEW_ADMIN_USER_TOUCHED_DEFAULTS } from '../constants/users';
import { AdminUserRequest, AdminUserRequestTouched } from '../types/user';

export const usersSearchState = atom<string | undefined>({
  key: 'app.admin.users.search',
  default: undefined,
});

export const editUserState = atom<AdminUserRequest>({
  key: "app.admin.user.edit",
  default: EMPTY_ADMIN_USER,
});

export const editUserTouchedState = atom<AdminUserRequestTouched>({
  key: "app.admin.user.edit.touched",
  default: NEW_ADMIN_USER_TOUCHED_DEFAULTS,
});

export const editUserValidState = selector<{
  [key in keyof AdminUserRequest]: boolean;
}>({
  key: "app.admin.user.edit.valid",
  get: ({ get }) => {
    const fields = get(editUserState);

    // SET ALL FIELDS TO BE VALID
    const validFields = Object.fromEntries(
      Object.keys(fields).map((key) => [key, true])
    ) as {
      [key in keyof AdminUserRequest]: boolean;
    };

    // VALIDATE REQUIRED FIELDS
    if (!fields.username) {
      validFields.username = false;
    }
    if (!fields.name) {
      validFields.name = false;
    }

    if (!fields.email) {
      validFields.email = false;
    }
    return validFields;
  },
});

export const editUserErrorState = selector({
  key: "app.admin.user.edit.error",
  get: ({ get }) => {
    const validFields = get(editUserValidState);
    return (
      Object.values(validFields).some((valid) => !valid)
    );
  },
});