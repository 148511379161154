import { FormControl } from "baseui/form-control"
import { FlexColumn } from "./FlexColumn"
import { useStyles } from "../../hooks/useStyles";
import { MaskedInput } from "baseui/input";
import { useEffect, useMemo, useState } from "react";
import useUpdateEffect from "../../hooks/useUpdateEffect";

type Props = {
  value?: string;
  onChange: (phone: string) => void;
  type?: 'full' | 'simple';
  required?: boolean;
  disabled?: boolean;
  isPhoneValid?: (valid: boolean) => void;
  caption?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  label?: React.ReactNode;
  placeholder?: string;
  readonly?: boolean;
  startEnhancer?: React.ReactNode;
  size?: 'compact' | 'mini' | 'default' | 'large';
  maxLength?: number;
  mask?: string;
};
export function LabeledPhoneInput({ 
  label = 'Telefono',
  value,
  onChange,
  type = 'full',
  required = false,
  disabled = false,
  caption,
  endEnhancer,
  placeholder,
  readonly,
  startEnhancer,
  size = 'compact',
  maxLength,
  isPhoneValid,
  mask,
}: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [isValid, setIsValid] = useState(false);
  const [isVisited, setIsVisited] = useState(false);
  const shouldShowError = !isValid && (required ? isVisited : true);

  const validatePhone = (value?: string): boolean => {
    if (required && !value) return false;
    if (value) {
      const phone = value.replace(/\D/g, '').trim();
      if (phone.length < 10) return false;
    }
    return true
  }

  useEffect(() => {
    setIsValid(validatePhone(value));
  }, [value])

  useUpdateEffect(() => {
    if (isPhoneValid) {
      isPhoneValid(isValid);
    }
  }, [isValid])

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const {value} = event.currentTarget;
    if (type === 'full')
      onChange(value.trim())
    else if (type === 'simple' )
      onChange(value.replace(/\D/g, '').trim())
  };

  const errorMsg = useMemo(() => {
    if (shouldShowError) {
      if (required && !value) return 'Este campo es requerido';
      return 'Ingrese un número valido'
    }
  }, [isValid, isVisited])

  const phoneMask = useMemo(() => {
    if (mask && mask.replace(/\D/g, '').length === 10) return mask;
    return "999-999-9999"
  }, [mask])

  
  
  return (
    <FlexColumn
      gap={theme.sizing.scale200}
      classNames={css({ width: '100%' })}
    >
      <FormControl
        label={label && `${label} ${required ? '*' : ''}`}
        caption={caption}
        error={errorMsg}
        disabled={disabled}
        overrides={{
          LabelContainer: {
            style: {
              marginTop: 0,
              marginBottom: 0,
            },
          },
          ControlContainer: {
            style: { marginBottom: 0 },
          },
          Caption: {
            style: {
              marginTop: theme.sizing.scale200,
              marginBottom: 0,
            },
          },
        }}
      >
        <MaskedInput 
          value={value}
          placeholder={placeholder}
          readOnly={readonly}
          onChange={onInputChange}
          mask={phoneMask}
          size={size}
          error={shouldShowError}
          maxLength={maxLength}
          endEnhancer={endEnhancer}
          startEnhancer={startEnhancer}
          onBlur={() => setIsVisited(true)}
          required={required}
        />
      </FormControl>
    </FlexColumn>
  )
}