import { Spinner } from 'baseui/spinner';
import { useStyles } from '../../hooks/useStyles';
import { EmptyState } from './EmptyState';

type Props = {
  size?: 'default' | 'compact'
}
export function Loading({size = 'default'}:Props) {
  const { theme } = useStyles();

  return (
    <EmptyState>
      <Spinner
        $borderWidth={theme.sizing.scale100}
        $size={size === 'default' ? theme.sizing.scale1600 : theme.sizing.scale1200}
      />
    </EmptyState>
  );
}
