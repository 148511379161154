import { TetherPlacement } from 'baseui/layer';
import { StatefulTooltip } from 'baseui/tooltip';
import { useStyles } from '../../hooks/useStyles';

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  showArrow?: boolean;
  placement?: TetherPlacement;
  ignoreBoundary?: boolean;
  popoverMargin?: number;
};

export function StyledTooltip({
  children,
  content,
  showArrow = true,
  placement,
  ignoreBoundary = false,
  popoverMargin = 5,
}: Props) {
  const { css } = useStyles();

  return (
    <StatefulTooltip
      content={() => content}
      showArrow={showArrow}
      autoFocus
      popoverMargin={popoverMargin}
      placement={placement}
      ignoreBoundary={ignoreBoundary}
    >
      <span
        className={css({
          lineHeight: 0,
          height: 'fit-content',
        })}
      >
        {children}
      </span>
    </StatefulTooltip>
  );
}
