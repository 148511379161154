import { OnChangeParams } from 'baseui/select';
import { useMemo } from 'react';
import { EnhancedLabeledSelect } from '../../ui/EnhancedLabeledSelect';
import { JobTypeCategory } from '../../../types/job';
import { JOB_TYPE_CATEGORY_OPTIONS } from '../../../constants/jobTypeCategories';

type Props = {
  selectedId?: JobTypeCategory;
  onChangeId?: (id?: string) => void;
  isFilter?: boolean;
  label?: string;
  emptyOptionText?: string;
  showEmptyOption?: boolean;
  error?: React.ReactNode;
  onBlur?: () => void;
  inline?: boolean;
  clearable?: boolean;
  required?: boolean;
};

function JobTypeCategorySelect({
  selectedId,
  onChangeId,
  isFilter = true,
  label = 'Categoría:',
  emptyOptionText = 'Seleciona una categoría',
  showEmptyOption = false,
  error,
  onBlur,
  inline,
  clearable = false,
  required = false,
}: Readonly<Props>) {
  const jobTypeCategoryOptions = useMemo(() => {
    const coreOptions = JOB_TYPE_CATEGORY_OPTIONS.map((jobTypeCategory) => ({
      id: jobTypeCategory.id,
      label: jobTypeCategory.label,
    }));
    const options = showEmptyOption
      ? [
          {
            id: undefined,
            label: isFilter ? 'Todos' : emptyOptionText,
          },
          ...coreOptions,
        ]
      : coreOptions;
    return options;
  }, []);

  const onSelectedChange = (params: OnChangeParams) => {
    if (params.option?.id) {
      onChangeId && onChangeId(params.option.id as string);
    } else {
      onChangeId && onChangeId(undefined);
    }
  };

  return (
    <EnhancedLabeledSelect
      label={label}
      options={jobTypeCategoryOptions}
      value={showEmptyOption || selectedId !== undefined ? [{ id: selectedId }] : undefined}
      onChange={onSelectedChange}
      fullWidth
      onBlur={() => {
        onBlur && onBlur();
      }}
      searchable
      inline={inline}
      inset={inline}
      error={error}
      required={required}
      clearable={clearable}
      placeholder={emptyOptionText}
    />
  );
}

export default JobTypeCategorySelect;
