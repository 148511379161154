import { ASSET_TYPE } from '../../constants/asset';
import { CommunicationsReport } from '../../types/report';
import AssetContainerData from '../assets/AssetContainerData';
import AssetVehicleData from '../assets/AssetVehicleData';
import { LabeledIconData } from '../ui/LabeledIconData';

type Props = {
  communicationsReport: CommunicationsReport;
};
export function CommsReportInfo({ communicationsReport }: Readonly<Props>) {
  return (
    <>
      <LabeledIconData
        label="Tipo de activo:"
        data={ASSET_TYPE[communicationsReport.asset.assetType]}
        icon="devices_other"
      />
      {communicationsReport.asset.client && (
        <LabeledIconData
          label="Cliente:"
          data={communicationsReport.asset.client.name}
          icon="business_center"
        />
      )}
      {communicationsReport.asset.subClient && (
        <LabeledIconData
          label="Subcliente:"
          data={communicationsReport.asset.subClient.name}
          icon="business_center"
        />
      )}
      {communicationsReport.asset.vehicle && <AssetVehicleData vehicle={communicationsReport.asset.vehicle} />}
      {communicationsReport.asset.container && <AssetContainerData container={communicationsReport.asset.container} />}
    </>
  );
}
