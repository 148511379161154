import { Command } from "../../types/command";
import LabeledData from "../ui/LabeledData";
import { Textarea } from "baseui/textarea";

type Props = {
  command: Command
};
export function CommandInfo({command}: Props) {

  return (
    <>
      <LabeledData 
        label="Descripción:"
        content={command.commandDescription}
      />
      <LabeledData 
        label="Queue:"
        content={command.queue ? 'Sí' : 'No'}
      />
      <LabeledData 
        label="TTL:"
        content={command.ttl ? command.ttl : 0}
      />
      <LabeledData 
        label="Comando:"
        content={(
          <Textarea 
            value={command.command ? command.command : 'Sin comando'}
            readOnly
            overrides={{
              Input: {
                style: () => ({
                  fontSize: '12px',
                  lineHeight: '13px',
                  height: '70px'
                })
              }
            }}
          />
        )}
      />
      <LabeledData 
        label="Propiedades:"
        content={(
          <Textarea 
            value={JSON.stringify(command.properties)}
            readOnly
            overrides={{
              Input: {
                style: () => ({
                  fontSize: '12px',
                  lineHeight: '13px',
                  height: '120px'
                })
              }
            }}
          />
        )}
      />
      <LabeledData 
        label="Campos:"
        content={(
          <Textarea 
            value={JSON.stringify(command.fields)}
            readOnly
            overrides={{
              Input: {
                style: () => ({
                  fontSize: '12px',
                  lineHeight: '13px',
                  height: '120px'
                })
              }
            }}
          />
        )}
      />
      <LabeledData 
        label="Logs:"
        content={(
          <Textarea 
            value={command.logMessage ? command.logMessage : ''}
            readOnly
            overrides={{
              Input: {
                style: () => ({
                  fontSize: '12px',
                  lineHeight: '13px',
                  height: '70px'
                })
              }
            }}
          />
        )}
      />
    </>
  )
}