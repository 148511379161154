import { useState } from 'react';
import { SetterOrUpdater } from 'recoil';
import { useDebounceValue } from 'usehooks-ts';
import useUpdateEffect from './useUpdateEffect';

export function useDebouncedSearch([search, setSearch]: [string | undefined, SetterOrUpdater<string | undefined>]) {
  const [searchLocal, setSearchLocal] = useState(search);
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue<string | undefined>(searchLocal, 500);

  useUpdateEffect(() => {
    setSearch(searchLocal);
  }, [debouncedSearch]);

  useUpdateEffect(() => {
    setDebouncedSearch(searchLocal);
  }, [searchLocal]);

  return { search: searchLocal, setSearch: setSearchLocal };
}
