import { useStyles } from '../../hooks/useStyles';
import { GenericComponent } from '../../types/components';

type Props = {
  flexible?: boolean;
  verticalFlex?: boolean;
  gap?: string;
};

export function FullContainer({ children, flexible, classNames, verticalFlex, gap }: GenericComponent & Props) {
  const { classes, css, theme } = useStyles();

  return (
    <div className={`
      ${classes.fillParent} 
      ${flexible ? classes.horizontalFlex : ''} 
      ${classNames ?? ''}
      ${verticalFlex ? classes.verticalFlex : ''}
      ${gap && css({gap: gap})}`
    }>
      {children}
    </div>
  );
}
