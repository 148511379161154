import { FormControl } from "baseui/form-control"
import { FlexColumn } from "./FlexColumn"
import { useStyles } from "../../hooks/useStyles";
import { Textarea } from "baseui/textarea";
import { ParagraphXSmall } from "baseui/typography";
import { FlexRow } from "./FlexRow";

type Props = {
  label: string,
  value?: string,
  required?: boolean,
  disabled?: boolean,
  onChangeValue: (value: string) => void,
  hint?: string,
  size?: 'small' | 'default' | 'large',
  error?: any
}
export function LabeledTextArea({ 
  label, 
  required = false, 
  disabled = false,
  value,
  onChangeValue,
  hint,
  size = 'default',
  error
}:Props) {
  const { css, theme } = useStyles();
  const textAreaHeight = {
    small: '70px',
    large: '150px'
  }

  return (
    <FlexColumn
      gap={theme.sizing.scale200}
      classNames={css({ width: '100%' })}
    >
      <FormControl
        label={label && `${label} ${required ? '*' : ''}`}
        disabled={disabled}
        overrides={{
          LabelContainer: {
            style: {
              marginTop: 0,
              marginBottom: 0,
            },
          },
          ControlContainer: {
            style: { marginBottom: 0 },
          },
        }}
      >
        <FlexRow classNames={css({
          height: size !== 'default' ? textAreaHeight[size] : ''
        })}>
          <Textarea
            value={value}
            onChange={e => onChangeValue(e.target.value)}
            clearOnEscape
            required={required}
            size='compact'
            error={error}
          />
        </FlexRow>
      </FormControl>
      <ParagraphXSmall margin={0}>
        <code className={css({
          whiteSpace: 'pre-wrap',
          color: theme.colors.contentSecondary,
        })}>
          {hint}
        </code>
      </ParagraphXSmall>
    </FlexColumn>
  )
}