import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { LoginContainer } from '../login/LoginContainer';
import { LoginForm } from '../login/LoginForm';
import { HeadTitle } from '../shared/HeadTitle';
import { FlexColumn } from '../ui/FlexColumn';
import { StyledBanner } from '../ui/StyledBanner';
import { StyledButton } from '../ui/StyledButton';
import { currentPagePathState } from '../../storage/app';
import { useRecoilValue } from 'recoil';
import { getCurrentUser } from 'aws-amplify/auth';

export function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const currentPage = useRecoilValue(currentPagePathState);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async () => {
    setLoading(true);
    const response = await login(username, password);
    if (response?.error) {
      setError(`${response.error}`);
    } else if (currentPage){
      navigate(currentPage);
    } else {
      navigate('/');
    }
    setLoading(false);
  };

  useEffect(() => {
    const validateSession = async () => {
      try {
        const currentUser = await getCurrentUser();
        if (currentUser) navigate('/');
      } catch (err) {
        
      }
    }
    validateSession();
  })


  return (
    <LoginContainer>
      <HeadTitle title="Iniciar sesión" />
      <FlexColumn centered>
        <LoginForm
          user={username}
          password={password}
          onChangeUser={setUsername}
          onChangePass={setPassword}
        />
        {error && (
          <StyledBanner
            title="*Error*"
            kind="negative"
            full
            action={{
              label: 'Label',
              onClick: () => {
                setError('');
              },
            }}
          >
            {error}
          </StyledBanner>
        )}
        <StyledButton
          full
          size="default"
          onClick={handleLogin}
          isLoading={loading}
        >
          Ingresar
        </StyledButton>
      </FlexColumn>
    </LoginContainer>
  );
}
