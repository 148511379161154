import { useState } from 'react';
import { CommsReportFiltersBar } from '../reports/CommsReportFiltersBar';
import { CommsReportList } from '../reports/CommsReportList';
import { ContentContainer } from '../ui/ContentContainer';

export function CommsReportPage() {
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <CommsReportFiltersBar onReloadPage={() => setReloadPage(!reloadPage)} enabledDownload/>
      <CommsReportList reloadPage={reloadPage} />
    </ContentContainer>
  );
}
