import { InstallationsList } from "../installations/InstallationsList";
import { FullContainer } from "../ui/FullContainer";
import { useStyles } from "../../hooks/useStyles";
import InstallationsFiltersBar from "../installations/InstallationsFiltersBar";

export function InstallationsPage() {
  const { css, theme } = useStyles();

  return (
    <FullContainer
      verticalFlex
      classNames={`${css({
        padding: theme.sizing.scale400,
        gap: theme.sizing.scale500,
        boxSizing: 'border-box',
      })}`}
    > 
      <InstallationsFiltersBar />
      <InstallationsList />
    </FullContainer>
  );
}
