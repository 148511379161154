import * as uuid from 'uuid';
import { ApiError } from '../types/apiResponseError';
import { Bulk, BulkEmpty } from '../types/bulk';
import { convertObjectToJson } from '../utils/objects';
import { deleteRequest, fileRequest, getRequest, postRequest, uploadRequest } from './api';
import { URLS } from './urls/urls';

export const getBulkFiles = async (
  page?: number
): Promise<{ bulkFiles?: Bulk[]; error?: ApiError; total?: number }> => {
  const params = {
    page,
  };
  const response = await getRequest(URLS.bulkFiles.all, {
    queryParams: params,
  });
  return {
    bulkFiles: response?.data?.items,
    total: response?.data?.total,
    error: response?.error,
  };
};

export const getBulkFile = async (bulkFileId: string): Promise<{ bulkFile?: Bulk; error?: ApiError }> => {
  const response = await getRequest(URLS.bulkFiles.detail(bulkFileId));
  return {
    bulkFile: response?.data,
    error: response?.error,
  };
};

export const postBulkFile = async (bulkFileId: string): Promise<{ error?: ApiError }> => {
  const response = await postRequest(URLS.bulkFiles.create(bulkFileId));
  return {
    error: response?.error,
  };
};

export const deleteBulkFile = async (bulkFileId: string): Promise<{ error?: ApiError }> => {
  const response = await deleteRequest(URLS.bulkFiles.delete(bulkFileId));
  return {
    error: response?.error,
  };
};

export const getBulkFileExampleUrl = async (key: string): Promise<{ url?: string; error?: ApiError }> => {
  const response = await fileRequest(key);
  return {
    url: response?.data?.url,
    error: response?.error,
  };
};

export const createBulkFile = async (bulk: BulkEmpty, file: File): Promise<{ error?: ApiError }> => {
  const myId = uuid.v4();
  const storageMetadata = {
    fileType: bulk.fileType,
    assetType: bulk.assetType,
    deviceBrandId: bulk.brand?._id,
    deviceModelId: bulk.device?._id,
    clientId: bulk.client?._id,
    subClientId: bulk.subClient?._id
  }
  const metadata = convertObjectToJson(storageMetadata);
  const response = await uploadRequest(`public/bulk/${myId}.csv`, file, {
    contentType: file.type,
    metadata,
  });

  return {
    error: response?.error,
  };
};
