import { Delete } from 'baseui/icon';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { HeadingXSmall } from 'baseui/typography';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { generatePath, useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES, INSTALLATIONS_DETAIL_PAGE } from '../../../constants/app';
import { useFeedback } from '../../../hooks/useFeedback';
import { useLoading } from '../../../hooks/useLoading';
import { useStyles } from '../../../hooks/useStyles';
import { createInstallation } from '../../../services/installationService';
import {
  editInstallationErrorState,
  editInstallationState,
  editInstallationTouchedState,
} from '../../../storage/Installations';
import { FlexColumn } from '../../ui/FlexColumn';
import { FlexRow } from '../../ui/FlexRow';
import { StyledBanner } from '../../ui/StyledBanner';
import { StyledButton } from '../../ui/StyledButton';
import { InstallationForm } from '../InstallationForm';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};
function CreateInstallationModal({ onClose, isOpen }: Props) {
  const { theme, classes, css } = useStyles();
  const { loading, startLoading, stopLoading } = useLoading();
  const editInstallation = useRecoilValue(editInstallationState);
  const formHasError = useRecoilValue(editInstallationErrorState);
  const resetEditInstallationState = useResetRecoilState(editInstallationState);
  const resetEditInstallationTouchedState = useResetRecoilState(editInstallationTouchedState);
  const { showPositiveFeedback, showFailFeedback } = useFeedback();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const canSubmit = !formHasError;

  useEffect(() => {
    return () => {
      clearStates();
    };
  }, [isOpen]);

  const clearStates = (update?: boolean) => {
    resetEditInstallationState();
    resetEditInstallationTouchedState();
  };

  const onSubmit = async () => {
    if (!editInstallation) return;
    startLoading();
    const { error, id } = await createInstallation(editInstallation);
    if (!error) {
      showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.emission));
      if (id) {
        const route = generatePath(INSTALLATIONS_DETAIL_PAGE.route, { installationId: id });
        navigate(route, { replace: false });
      }
      onClose();
    } else {
      setError(typeof error === 'string' ? error : error.toString());
      showFailFeedback(error);
    }
    stopLoading();
  };

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      <ModalHeader>
        <FlexRow
          gap={theme.sizing.scale200}
          classNames={classes.centeredStart}
        >
          <HeadingXSmall margin={0}>Nueva emisión</HeadingXSmall>
        </FlexRow>
      </ModalHeader>
      <ModalBody>
        <FlexColumn
          classNames={css({
            width: '75vw',
            maxWidth: '1200px',
            minWidth: '350px',
            maxHeight: '80vh',
            overflow: 'auto',
          })}
        >
          {error && (
            <StyledBanner
              title="*Error*"
              kind="negative"
              action={{
                label: 'label',
                icon: () => <Delete />,
                onClick: () => {
                  setError('');
                },
              }}
            >
              {error}
            </StyledBanner>
          )}
          <InstallationForm />
        </FlexColumn>
      </ModalBody>
      <ModalFooter>
        <FlexRow
          gap={theme.sizing.scale300}
          classNames={classes.centeredEnd}
        >
          <StyledButton
            kind="tertiary"
            onClick={() => onClose()}
          >
            Cancelar
          </StyledButton>
          <StyledButton
            onClick={onSubmit}
            disabled={!canSubmit}
            isLoading={loading}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </ModalFooter>
    </Modal>,
    document.body
  );
}

export default memo(CreateInstallationModal);
