import { useEffect, useMemo, useState } from "react";
import { Device } from "../../types/device";
import { LabeledInput } from "../ui/LabeledInput";
import { FlexColumn } from "../ui/FlexColumn";
import { useStyles } from "../../hooks/useStyles";
import { FlexRow } from "../ui/FlexRow";
import { areEqual } from "react-window";
import { StyledButton } from "../ui/StyledButton";
import { useLoading } from "../../hooks/useLoading";
import { useFeedback } from "../../hooks/useFeedback";
import { FEEDBACK, FEEDBACK_PREFIXES } from "../../constants/app";
import { StyledTooltip } from "../ui/StyledTooltip";
import { Button } from "baseui/button";
import { MaterialIcon } from "../ui/MaterialIcon";
import { postDevice, updateDevice } from "../../services/deviceService";
import { DeviceModelForm } from "../devicesModels/DeviceModelForm";
import { DeviceModelSelect } from "../shared/selects/DeviceModelSelect";

type Props = {
  device?: Device
  onCancel: () => void,
  afterSave: () => void,
  inline?: boolean,
};
export function DeviceForm({ device, onCancel, afterSave, inline = false }: Props) {
  const { css, theme } = useStyles();
  const [editingDevice, setEditingDevice] = useState<Device>({
    _id: '',
    ident: '',
    brand: {
      _id: '',
      name: ''
    },
    model: {
      _id: '',
      name: ''
    },
  });
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const [ createModel, setCreateModel ] = useState(false);

  useEffect(() => {
    if (device) {
      setEditingDevice(device)
    }
  }, [device])

  const hasUpdates = !areEqual(device ?? {}, editingDevice);

  const canSubmit = useMemo(() => {
    return !!editingDevice.ident && !!editingDevice.brand._id && !!editingDevice.model._id
  }, [editingDevice])


  const onInputChange = (value: string | undefined | null | {}, field: keyof Device) => {
    setEditingDevice((prev) => ({ ...prev!, [field]: value }));
  };

  const onSave = async () => {
    startLoading();
    const { id, error } = device ? await updateDevice(editingDevice) : await postDevice(editingDevice);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        device
          ? FEEDBACK.edited(FEEDBACK_PREFIXES.device, editingDevice.ident)
          : FEEDBACK.created(FEEDBACK_PREFIXES.device)
      );
    } else {
      showFailFeedback(
        error || device
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.device, editingDevice.ident)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.device)
      );
    }
    stopLoading();
  }

  return (
    <FlexColumn 
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
        width: '100%',
        flexDirection: inline ? 'row' : 'column',
        alignItems: 'end'
      })}
    >
      <FlexColumn classNames={css({
        overflow: 'auto',
        width: '100%',
        height: '100%',
        flexDirection: inline ? 'row-reverse' : 'column'
      })}>
        <LabeledInput
          label={`${inline ? '(Nuevo dispositivo) ' : ''}Ident:`}
          value={editingDevice?.ident}
          onChange={(value) => onInputChange(value, 'ident')}
          required
        />
        {createModel && <FlexRow classNames={css({ width: "100%", alignItems: "end" })}>
          <DeviceModelForm
            afterSave={() => {
              setCreateModel(false)
              onInputChange({_id: editingDevice.model._id}, 'model')
              onInputChange({_id: editingDevice.brand._id}, 'brand')
            }}
            onCancel={() => {
              setCreateModel(false)
              onInputChange({_id: editingDevice.model._id}, 'model')
              onInputChange({_id: editingDevice.brand._id}, 'brand')
            }} 
            inline
          />
        </ FlexRow>}
        {!createModel && <FlexRow classNames={css({ width: "100%", alignItems: "end" })} gap={'8px'}>
          <DeviceModelSelect 
            deviceModel={{...device?.model!, brand: device?.brand! }}
            selectedDeviceModelId={editingDevice?.model._id}
            onChangeDeviceModel={(modelId, brandId) => {
              onInputChange({_id: modelId}, 'model')
              onInputChange({_id: brandId}, 'brand')
            }}
            isFilter={false}
            required
          />
          <StyledTooltip
              content={'Nuevo'}
              showArrow={false}
            >
              <Button
                size="compact"
                shape="square"
                onClick={() => setCreateModel(true)}
                kind="tertiary"
              >
                <MaterialIcon name={'add'} size='compact'/>
              </Button>
            </StyledTooltip>
        </FlexRow>}
        {!inline && 
          <>
            <LabeledInput
              label={`IMEI:`}
              value={editingDevice?.imei}
              onChange={(value) => onInputChange(value, 'imei')}
            />
            <LabeledInput
              label={`SIM:`}
              value={editingDevice?.sim}
              onChange={(value) => onInputChange(value, 'sim')}
            />
            <LabeledInput
              label={`Carrier:`}
              value={editingDevice?.carrier}
              onChange={(value) => onInputChange(value, 'carrier')}
            />
          </>
        }
      </FlexColumn>
      {inline && <FlexRow gap={0}>
        <StyledTooltip
          content={'Cancelar'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onCancel}
            kind="tertiary"
          >
            <MaterialIcon name={'cancel'} />
          </Button>
        </StyledTooltip>
        <StyledTooltip
          content={'Guardar'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
            kind="tertiary"
          >
            <MaterialIcon name={'done'} />
          </Button>
        </StyledTooltip>
      </FlexRow>}
      {!inline && <FlexRow
        classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}
      >
        <FlexRow gap={theme.sizing.scale300}>
          {device?._id && <StyledButton
            kind="tertiary"
            onClick={onCancel}
          >
            Cancelar
          </StyledButton>}
          <StyledButton
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>}
    </FlexColumn>
  )
}