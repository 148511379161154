import { FormControl } from 'baseui/form-control';
import { ImperativeMethods, OnChangeParams, Options, Select, Value } from 'baseui/select';
import { ChangeEvent, forwardRef, useRef } from 'react';
import { useStyles } from '../../hooks/useStyles';
import { FlexColumn } from './FlexColumn';
import { FlexResponsive } from './FlexResponsive';
import { FlexRow } from './FlexRow';
import useEffectOnce from '../../hooks/useEffectOnce';

type Props = {
  label?: string;
  options: Options;
  value?: Value;
  searchable?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
  labelKey?: string;
  valueKey?: string;
  fullWidth?: boolean;
  infiniteScroll?: boolean;
  fetchDataLoading?: boolean;
  hasNext?: boolean;
  inset?: boolean;
  onBlur?: () => void;
  fetchData?: () => void;
  onChange: (params: OnChangeParams) => void;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  filterOptions?: (option: Value, filterValue: string) => Value;
  clearable?: boolean;
  placeholder?: string;
  selectMaxWidth?: number;
  inline?: boolean;
  maxWidth?: number;
  required?: boolean;
  error?: React.ReactNode;
  caption?: React.ReactNode;
  maxDropdownHeight?: string;
  smallContainer?: boolean;
  dropdownOffset?: string;
  defaultSearch?: string;
  onBlurResetsInput?: boolean;
};

export function EnhancedLabeledSelect({
  value,
  onChange,
  options,
  label,
  searchable = false,
  disabled = false,
  labelKey,
  valueKey,
  fullWidth = false,
  infiniteScroll = false,
  fetchDataLoading = false,
  hasNext = false,
  isMulti = false,
  inset = false,
  fetchData,
  onInputChange,
  filterOptions,
  onBlur,
  clearable = false,
  placeholder,
  selectMaxWidth,
  inline = false,
  maxWidth,
  required = false,
  error,
  caption,
  maxDropdownHeight = '150px',
  smallContainer = false,
  dropdownOffset,
  defaultSearch,
  onBlurResetsInput = true,
}: Readonly<Props>) {
  const { theme, css } = useStyles();
  const controlRef = useRef<ImperativeMethods>(null);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (!e?.target && !hasNext) return;
    const container = e.target as HTMLDivElement;
    const scrollingEnd = container.clientHeight + container.scrollTop >= container.scrollHeight - 50;
    if (scrollingEnd && !!fetchData) {
      fetchData();
    }
  };

  useEffectOnce(() => {
    controlRef.current && defaultSearch && controlRef.current.setInputValue(defaultSearch);
  });

  const dropdownMarginTop = dropdownOffset ? dropdownOffset : theme.sizing.scale1200;

  const select = (
    <Select
      controlRef={controlRef}
      onBlur={onBlur}
      closeOnSelect
      openOnClick
      clearable={clearable}
      onChange={onChange}
      options={options}
      searchable={searchable}
      size={'compact'}
      value={value}
      disabled={disabled}
      labelKey={labelKey}
      valueKey={valueKey}
      onInputChange={onInputChange}
      filterOptions={!!onInputChange && !filterOptions ? (options) => options : filterOptions}
      isLoading={fetchDataLoading}
      multi={isMulti}
      maxDropdownHeight={maxDropdownHeight}
      placeholder={placeholder}
      onBlurResetsInput={onBlurResetsInput}
      overrides={{
        Root: {
          style: {
            width: fullWidth ? '100%' : 'auto',
            maxWidth: selectMaxWidth ? `${selectMaxWidth}px` : undefined,
          },
        },
        ValueContainer: {
          component:
            inline && inset
              ? ({ children }) => {
                  return (
                    <FlexRow gap={theme.sizing.scale100}>
                      <span
                        className={css({
                          paddingLeft: theme.sizing.scale400,
                          paddingTop: theme.sizing.scale200,
                          paddingBottom: theme.sizing.scale200,
                          fontWeight: '600',
                          whiteSpace: 'nowrap',
                        })}
                      >
                        {label}
                      </span>
                      <FlexRow
                        classNames={css({
                          paddingTop: theme.sizing.scale200,
                          paddingBottom: theme.sizing.scale200,
                          gap: theme.sizing.scale100,
                          whiteSpace: 'nowrap',
                        })}
                      >
                        {children}
                      </FlexRow>
                    </FlexRow>
                  );
                }
              : undefined,
        },
        Dropdown: infiniteScroll
          ? {
              component: forwardRef<HTMLDivElement, { children: React.ReactNode }>(({ children, ...props }, ref) => {
                return (
                  <div
                    onScroll={handleScroll}
                    className={css({
                      overflowY: 'auto',
                      maxHeight: maxDropdownHeight,
                      paddingTop: theme.sizing.scale300,
                      paddingBottom: theme.sizing.scale300,
                      borderRadius: theme.sizing.scale300,
                      background: theme.colors.backgroundPrimary,
                      marginTop: smallContainer ? dropdownMarginTop : undefined,
                      boxShadow: '0 4px 16px hsla(0, 0%, 0%, 0.16)',
                      zIndex: 10,
                    })}
                    ref={ref}
                    {...props}
                  >
                    {children}
                  </div>
                );
              }),
            }
          : {
              style: () => ({
                marginTop: smallContainer ? dropdownMarginTop : 0,
                zIndex: 10,
              }),
            },
        Popover: {
          props: {
            placement: 'bottom',
            overrides: {
              Body: {
                style: () => ({
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  zIndex: '110',
                }),
              },
              Inner: {
                style: () => ({
                  backgroundColor: 'transparent',
                }),
              },
            },
          },
        },
      }}
    />
  );

  if (inline && inset) return select;

  if (inline) {
    return (
      <FlexResponsive
        gap={0}
        centered
        breakAt="medium"
      >
        <FormControl
          label={label}
          overrides={{
            ControlContainer: {
              style: {
                marginBottom: 0,
                flex: 1,
              },
            },
            LabelContainer: {
              style: {
                marginTop: 0,
                minWidth: '100px',
                [theme.mediaQuery.medium]: {
                  width: 'auto',
                  marginBottom: 0,
                },
              },
            },
          }}
        >
          {select}
        </FormControl>
      </FlexResponsive>
    );
  }

  return (
    <FlexColumn
      gap={theme.sizing.scale200}
      classNames={css({ width: '100%', maxWidth: maxWidth && `${maxWidth}px` })}
    >
      <FormControl
        label={label ? `${label} ${required ? '*' : ''}` : undefined}
        caption={caption}
        disabled={disabled}
        error={
          !Array.isArray(error)
            ? error
            : () => {
                return (
                  <ul>
                    {error.map((e, index) => {
                      return <li key={`error-${index}`}>{e}</li>;
                    })}
                  </ul>
                );
              }
        }
        overrides={{
          LabelContainer: {
            style: {
              marginTop: 0,
              marginBottom: 0,
            },
          },
          ControlContainer: {
            style: { marginBottom: 0 },
          },
          Caption: {
            style: {
              marginTop: theme.sizing.scale200,
              marginBottom: 0,
            },
          },
        }}
      >
        {select}
      </FormControl>
    </FlexColumn>
  );
}
