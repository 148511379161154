import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLoading } from '../../../../hooks/useLoading';
import { useStyles } from '../../../../hooks/useStyles';
import { getSubAccount } from '../../../../services/subAccountService';
import { SubAccount } from '../../../../types/subAccount';
import { EmptyState } from '../../../ui/EmptyState';
import { FlexColumn } from '../../../ui/FlexColumn';
import { FlexRow } from '../../../ui/FlexRow';
import { Loading } from '../../../ui/Loading';
import { MaterialIcon } from '../../../ui/MaterialIcon';
import { StyledTooltip } from '../../../ui/StyledTooltip';
import { SubAccountForm } from '../SubAccountForm';
import { SubAccountInfo } from '../SubAccountInfo';

type Props = {
  isOpen: boolean;
  selectedSubAccountId?: string;
  onClose: (update?: boolean) => void;
  clientId?: string;
  subClientId?: string;
};

function SubAccountDetailModal({ onClose, isOpen, selectedSubAccountId, clientId, subClientId }: Props) {
  const { css } = useStyles();
  const [subAccount, setSubAccount] = useState<SubAccount>();
  const { startLoading, stopLoading, loading } = useLoading(false);
  const [editingMode, setEditingMode] = useState(false);
  const [error, setError] = useState(false);

  const loadSubAccountData = async (subAccountId: string) => {
    startLoading();
    const response = await getSubAccount(subAccountId);
    if (response?.error) {
      setSubAccount(undefined);
      setError(true);
    } else {
      setSubAccount(response?.subAccount);
      setError(false);
    }
    stopLoading();
  };

  useEffect(() => {
    if (isOpen && selectedSubAccountId) setEditingMode(false);
  }, [isOpen]);

  useEffect(() => {
    if (!selectedSubAccountId) {
      setSubAccount(undefined);
      setEditingMode(true);
      return;
    }
    loadSubAccountData(selectedSubAccountId);
  }, [selectedSubAccountId]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      {loading && (
        <FlexColumn
          centered
          classNames={css({
            height: '400px',
            width: '400px',
          })}
        >
          <Loading />
        </FlexColumn>
      )}
      {!loading && error && (
        <FlexColumn
          centered
          classNames={css({
            height: '400px',
            width: '400px',
          })}
        >
          <EmptyState
            title="*Error*"
            description="No se pudieron obtener los datos de la subcuenta"
          />
        </FlexColumn>
      )}
      {!loading && !error && (
        <>
          <FlexRow
            gap={0}
            classNames={css({
              justifyContent: 'space-between',
              margin: '6px 2rem 0px 0px',
            })}
          >
            <ModalHeader
              className={css({
                marginBottom: '0 !important',
              })}
            >
              {subAccount?.name ?? 'Nueva subcuenta'}
            </ModalHeader>
            {!editingMode && (
              <StyledTooltip
                content={'Editar'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => setEditingMode(true)}
                  kind="tertiary"
                >
                  <MaterialIcon
                    name={'edit'}
                    size="xs-mini"
                  />
                </Button>
              </StyledTooltip>
            )}
          </FlexRow>
          <ModalBody>
            <FlexColumn
              classNames={css({
                maxWidth: '1200px',
                minWidth: '550px',
                boxSizing: 'border-box',
                padding: '1rem',
                borderTop: '1px solid rgb(226, 226, 226)',
                maxHeight: '80vh',
                overflow: 'auto',
              })}
            >
              {!editingMode && subAccount && <SubAccountInfo subAccount={subAccount} />}
              {editingMode && !loading && (
                <SubAccountForm
                  subAccount={subAccount}
                  onCancel={() => setEditingMode(false)}
                  afterSave={() => onClose(true)}
                  clientId={clientId}
                  subClientId={subClientId}
                />
              )}
            </FlexColumn>
          </ModalBody>
        </>
      )}
    </Modal>,
    document.body
  );
}

export default memo(SubAccountDetailModal);
