import { Block } from 'baseui/block';
import { LabelSmall, LabelXSmall } from 'baseui/typography';
import { memo } from 'react';
import { useMoment } from '../../../hooks/useMoment';
import { useStyles } from '../../../hooks/useStyles';
import { InstallationLog } from '../../../types/logs';
import { EllipsedText } from '../../ui/EllipsedText';
import { FlexColumn } from '../../ui/FlexColumn';
import { FlexRow } from '../../ui/FlexRow';
import { VerticalDivider } from '../../ui/VerticalDivider';

type Props = {
  data: InstallationLog[];
  index: number;
  style: React.CSSProperties;
};
function InstallationLogItem({ data, index, style }: Readonly<Props>) {
  const { css, theme, classes, concatenateClasses } = useStyles();
  const { formatDateFromTs } = useMoment();
  const installationLog = data[index];

  if (!installationLog) {
    return <></>; // TODO: skeleton
  }

  const paddingClass = css({ paddingTop: theme.sizing.scale400, paddingBottom: theme.sizing.scale400 });
  const isOdd = index % 2 !== 0;
  const textRight = css({ textAlign: 'end' });

  return (
    <Block style={{ ...style, backgroundColor: isOdd ? theme.colors.backgroundSecondary : undefined }}>
      <FlexRow
        classNames={css({
          paddingLeft: theme.sizing.scale300,
          paddingRight: theme.sizing.scale300,
          height: '70px',
        })}
      >
        <FlexColumn
          gap={theme.sizing.scale0}
          classNames={concatenateClasses(css({ alignItems: 'flex-end' }), classes.flexible(1), paddingClass)}
        >
          <LabelXSmall
            color={theme.colors.contentSecondary}
            className={textRight}
          >
            {installationLog.created ? formatDateFromTs(installationLog.created) : '-'}
          </LabelXSmall>
          <LabelSmall className={textRight}>
            <EllipsedText
              maxLines={2}
              breakAll
            >
              {installationLog.logTypeDescription}
            </EllipsedText>
          </LabelSmall>
        </FlexColumn>
        <VerticalDivider
          height={70}
          width={4}
        />
        <FlexColumn
          gap={theme.sizing.scale500}
          classNames={concatenateClasses(classes.flexible(3), paddingClass)}
        >
          <FlexRow
            gap={theme.sizing.scale200}
            classNames={classes.centeredStart}
          >
            <LabelSmall margin={0}>
              <EllipsedText
                maxLines={2}
                breakAll
              >
                {installationLog.comment}
              </EllipsedText>
            </LabelSmall>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </Block>
  );
}

export default memo(InstallationLogItem);
