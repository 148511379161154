import { useEffect, useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { FEEDBACK, FEEDBACK_PREFIXES, REQUIRED_FIELD_TEXT } from '../../constants/app';
import { EMPTY_ADMIN_USER } from '../../constants/users';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import { useStyles } from '../../hooks/useStyles';
import { postAdminUser, updateAdminUser, updateAdminUserPassword, } from '../../services/userService';
import { AdminUser, AdminUserRequestTouched } from '../../types/user';
import { FlexColumn } from '../ui/FlexColumn';
import { FlexRow } from '../ui/FlexRow';
import { LabeledInput } from '../ui/LabeledInput';
import { PasswordFormFields } from '../ui/PasswordFormFields';
import { StyledButton } from '../ui/StyledButton';
import { LabeledEmailInput } from '../ui/LabeledEmailInput';
import { editUserErrorState, editUserState, editUserTouchedState, editUserValidState } from '../../storage/adminUsers';
import { useRecoilState, useRecoilValue } from 'recoil';
import { LabeledInternationalPhoneInput } from '../ui/LabeledInternationalPhoneInput';

type Props = {
  user?: AdminUser;
  onCancel: () => void;
  afterSave: () => void;
};
export function UserForm({ user, onCancel, afterSave }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [changePassword, setChangePassword] = useState(false);
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const { isValidPassword } = usePasswordValidation(password, confirmPassword);
  const [form, setForm] = useRecoilState(editUserState);
  const [formTouched, setFormTouched] = useRecoilState(editUserTouchedState);
  const formValid = useRecoilValue(editUserValidState);
  const formHasError = useRecoilValue(editUserErrorState);

  const isNewUser = !user;

  useEffect(() => {
    if (user) {
      setForm(user);
    } else setForm(EMPTY_ADMIN_USER);
  }, [user]);

  useEffect(() => {
    setForm((prev) => ({ ...prev, password: undefined, confirmPassword: undefined }));
  }, [changePassword]);

  const hasUpdates = changePassword ? true : !areEqual(user ?? {}, form);

  const canSubmit = useMemo(() => {
    if (changePassword && !isValidPassword) return false;
    if (changePassword && isValidPassword) return true;
    if (isNewUser && !isValidPassword) {
      return false;
    }
    
    return !formHasError;
  }, [form, isNewUser, isValidPassword]);

  const onInputChange = (value: any, field: keyof AdminUser) => {
    setForm((prev) => ({ ...prev!, [field]: value }));
  };

  const onTouched = (key: keyof AdminUserRequestTouched) => {
    setFormTouched((prev) => ({
      ...prev,
      [key]: true,
    }));
  };

  const onChangePassword = async () => {
    if (!changePassword || !user || !isValidPassword || !password) return;
    startLoading();
    const { error } = await updateAdminUserPassword(user._id, password);
    if (!error) {
      setChangePassword(false);
      showPositiveFeedback(FEEDBACK.edited(FEEDBACK_PREFIXES.password, form.username));
    } else {
      showFailFeedback(error || FEEDBACK.failedEdition(FEEDBACK_PREFIXES.password, form.username));
    }
    stopLoading();
  };

  const onSave = async () => {
    startLoading();
    const { id, error } = user ? await updateAdminUser({...form, _id: user._id}) : await postAdminUser({...form, password});
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        user ? FEEDBACK.edited(FEEDBACK_PREFIXES.user, form.username) : FEEDBACK.created(FEEDBACK_PREFIXES.user)
      );
    } else {
      showFailFeedback(
        error || user
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.user, form.username)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.user)
      );
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          height: '100%',
        })}
      >
        {!changePassword && (
          <>
            <LabeledInput
              label="Nombre:"
              value={form.name}
              onChange={(value) => onInputChange(value, 'name')}
              onBlur={() => onTouched('nameTouched')}
              error={formTouched.nameTouched && !formValid.name && REQUIRED_FIELD_TEXT}
              required
            />
            {isNewUser && (
              <LabeledInput
                label="Nombre de usuario:"
                value={form.username}
                onChange={(value) => onInputChange(value, 'username')}
                onBlur={() => onTouched('usernameTouched')}
                error={formTouched.usernameTouched && !formValid.username && REQUIRED_FIELD_TEXT}
                required
              />
            )}
            <LabeledInternationalPhoneInput 
              placeholder="Ingresa un número telefonico"
              value={form.phoneNumber}
              onChange={(value) => {
                onInputChange(value, 'phoneNumber');
              }}
              type="simple"
            />
            <LabeledEmailInput 
              placeholder="Ingrese un correo electronico"
              value={form.email}
              onChange={(value) => {
                onInputChange(value, 'email');
              }}
              required
            />
            {!isNewUser && (
              <FlexRow>
                <StyledButton
                  shape="pill"
                  kind="tertiary"
                  onClick={() => setChangePassword(true)}
                >
                  Cambiar contraseña
                </StyledButton>
              </FlexRow>
            )}
            {isNewUser && (
              <PasswordFormFields
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
              />
            )}
          </>
        )}
        {changePassword && (
          <PasswordFormFields
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
          />
        )}
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          {(user?._id || changePassword) && (
            <StyledButton
              kind="tertiary"
              onClick={
                changePassword
                  ? () => {
                      setChangePassword(false);
                    }
                  : onCancel
              }
            >
              Cancelar
            </StyledButton>
          )}
          <StyledButton
            onClick={changePassword ? onChangePassword : onSave}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
