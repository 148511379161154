import { DeviceModelCommandsList } from '../deviceModelsCommands/DeviceModelCommandsList';
import { ContentContainer } from '../ui/ContentContainer';

export function DeviceModelCommandsPage() {
  return (
    <ContentContainer>
      <DeviceModelCommandsList />
    </ContentContainer>
  );
}
