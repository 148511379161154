import { ListItem, ListItemLabel } from 'baseui/list';
import { memo } from 'react';
import { ASSET_TYPE_VEHICLE } from '../../constants/asset';
import { useStyles } from '../../hooks/useStyles';
import { Asset } from '../../types/asset';
import { EllipsedText } from '../ui/EllipsedText';
import { Loading } from '../ui/Loading';

type Props = {
  data: {
    items: Asset[];
    isLoaded: (index: number) => boolean;
  };
  index: number;
  style: React.CSSProperties;
};

function SearchableAssetItem({ data, index, style }: Props) {
  const { theme } = useStyles();
  const asset = data.items[index];

  const label =
    asset.assetType === ASSET_TYPE_VEHICLE
      ? `${asset.label ?? 'Sin etiqueta'} / ${asset.vehicle?.brand.name ?? ''} ${asset.vehicle?.subBrand?.name ?? ''} ${
          asset.vehicle?.licensePlate ?? ''
        }`
      : `${asset.label ?? ''}`;

  const description = asset.device ? `Dispositivo asignado: ${asset.device.ident}` : 'Sin dispositivo';

  return (
    <span style={style}>
      {data.isLoaded(index) ? (
        <ListItem
          overrides={{
            Content: {
              style: {
                marginLeft: 0,
                paddingLeft: theme.sizing.scale600,
              },
            },
          }}
        >
          <ListItemLabel description={description}>
            <EllipsedText inline>{label}</EllipsedText>
          </ListItemLabel>
        </ListItem>
      ) : (
        <Loading size="compact" />
      )}
    </span>
  );
}

export default memo(SearchableAssetItem);
