import { Input } from 'baseui/input';
import { ChangeEvent } from 'react';
import { MaterialIcon } from '../ui/MaterialIcon';

type Props = {
  onChange: (value: string) => void;
  value?: string;
  size?: 'mini' | 'default' | 'compact' | 'large';
  clearable?: boolean;
  name?: string;
  placeholder?: string;
};

export function StyledSearchBar({
  onChange,
  value,
  size = 'compact',
  placeholder = 'Buscar',
  clearable = true,
  name,
}: Readonly<Props>) {
  const onSearchChange = ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(value);
  };

  return (
    <Input
      startEnhancer={
        <MaterialIcon
          name="search"
          size="mini"
        />
      }
      name={name}
      placeholder={placeholder}
      size={size}
      value={value}
      onChange={onSearchChange}
      clearable={clearable}
      autoComplete="off"
      overrides={{
        Root: {
          style: {
            flexShrink: 0,
          },
        },
        StartEnhancer: {
          style: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      }}
    />
  );
}
