import { useState } from 'react';
import { SubBrandsFiltersBar } from '../subBrands/SubBrandsFiltersBar';
import { SubBrandsList } from '../subBrands/SubBrandsList';
import { ContentContainer } from '../ui/ContentContainer';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';

export function SubBrandsPage() {
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [reloadPage, setReloadPage] = useState(false);
  const [brandId, setBrandId] = useState<string>();

  return (
    <ContentContainer>
      <SubBrandsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
        selectedBrandId={brandId}
        setSelectedBrandId={setBrandId}
      />
      <SubBrandsList
        search={search}
        reloadPage={reloadPage}
        brandId={brandId}
      />
    </ContentContainer>
  );
}
