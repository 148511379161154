import { useState } from 'react';
import { ContentContainer } from '../ui/ContentContainer';
import { UserFiltersBar } from '../adminUsers/UserFiltersBar';
import { UserList } from '../adminUsers/UserList';


export function AdminUsersPage() {
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <UserFiltersBar onReloadPage={() => setReloadPage(!reloadPage)} />
      <UserList reloadPage={reloadPage} />
    </ContentContainer>
  );
}
