export const URLS = {
  accounts: {
    me: 'me/',
    notifications: 'me/notifications/',
    subAccounts: 'sub-accounts/',
  },
  adminUsers: {
    all: 'admin/users/',
    create: 'admin/users/',
    delete: (userId: string) => `admin/users/${userId}/`,
    update: (userId: string) => `admin/users/${userId}/`,
  },
  assets: {
    all: 'assets/',
    create: 'assets/',
    detail: (assetId: string) => `assets/${assetId}/`,
    search: 'assets/search/',
    types: 'asset-types/',
    update: (assetId: string) => `assets/${assetId}/`,
  },
  bulkFiles: {
    all: 'csv-files/',
    create: (bulkFileId: string) => `csv-files/${bulkFileId}/`,
    delete: (bulkFileId: string) => `csv-files/${bulkFileId}/`,
    detail: (bulkFileId: string) => `csv-files/${bulkFileId}/`,
  },
  clients: {
    all: 'clients/',
    create: 'clients/',
    detail: (clientId: string) => `clients/${clientId}/`,
    update: (clientId: string) => `clients/${clientId}/`,
  },
  commands: {
    all: 'commands/',
    historic: 'commands/historic/',
    reports: 'commands/reports/',
    downloadReport: (reportId: string) => `commands/reports/${reportId}/`,
  },
  devices: {
    all: 'devices/',
    brandList: 'devices-brands/',
    create: 'devices/',
    createBrand: 'devices-brands/',
    createModel: 'devices-models/',
    detailModel: (modelId: string) => `devices-models/${modelId}/`,
    modelList: 'devices-models/',
    update: (deviceId: string) => `devices/${deviceId}/`,
    updateBrand: (brandId: string) => `devices-brands/${brandId}/`,
    updateModel: (modelId: string) => `devices-models/${modelId}/`,
  },
  installations: {
    all: 'emissions/assignments/',
    campaignsList: 'emissions/assignments/campaigns/',
    create: 'emissions/assignments/',
    detail: (installationId: string) => `emissions/assignments/${installationId}/`,
    logsList: (installationId: string) => `emissions/assignments/${installationId}/logs/`,
    newLog: (installationId: string) => `emissions/assignments/${installationId}/log/`,
    statusList: 'emissions/assignments/status/',
    phoneStatus: (installationId: string) => `emissions/assignments/${installationId}/phone/status/`,
    phoneTags: 'emissions/assignments/phone/tags/',
    update: (installationId: string) => `emissions/assignments/${installationId}/`,
    updateStatus: (installationId: string) => `emissions/assignments/${installationId}/status/`,
  },
  jobs: {
    create: (installationId: string) => `emissions/assignments/${installationId}/schedule/`,
    workers: (installationId: string) => `emissions/assignments/${installationId}/schedule/`,
  },
  logs: {
    emissionsTypes: 'emissions/assignments/logs-types/',
    webServiceDetail: (webServiceLogId: string) => `external-ws/logs/${webServiceLogId}/`,
    webServiceList: 'external-ws/logs/',
  },
  permissions: {
    all: 'app-permissions/',
  },
  phonesBl: {
    addRemove: 'emissions/assignments/blacklist/',
    all: 'emissions/assignments/blacklist/',
  },
  reports: {
    devices: 'reports/reporting-devices/',
    devicesOffline: 'reports/reporting-devices/offline/',
    deviceFileUrl: (reportId: string) => `reports/reporting-devices/offline/${reportId}/`,
    downloadEmissions: (reportId: string) => `emissions/assignments/reports/${reportId}/`,
    emissions: 'emissions/assignments/reports/',
  },
  subAccounts: {
    all: 'sub-accounts/',
    create: 'sub-accounts/',
    detail: (subAccountId: string) => `sub-accounts/${subAccountId}/`,
    update: (subAccountId: string) => `sub-accounts/${subAccountId}/`,
  },
  subClients: {
    all: 'sub-clients/',
    create: 'sub-clients/',
    detail: (subClientId: string) => `sub-clients/${subClientId}/`,
    update: (subClientId: string) => `sub-clients/${subClientId}/`,
  },
  users: {
    all: 'users/',
    changePassword: (userId: string) => `users/${userId}/password/`,
    create: 'users/',
    detail: (userId: string) => `users/${userId}/`,
    update: (userId: string) => `users/${userId}/`,
  },
  vehicles: {
    createBrand: 'vehicles-brands/',
    createSubBrand: 'vehicles-sub-brands/',
    createVersion: 'vehicles-versions/',
    brandList: 'vehicles-brands/',
    subBrandList: 'vehicles-sub-brands/',
    updateBrand: (brandId: string) => `vehicles-brands/${brandId}/`,
    updateSubBrand: (subBrandId: string) => `vehicles-sub-brands/${subBrandId}/`,
    updateVersion: (versionId: string) => `vehicles-versions/${versionId}/`,
    versionList: 'vehicles-versions/',
  },
  webHooks: {
    all: (clientId: string) => `clients/${clientId}/webhooks/`,
    create: (clientId: string) => `clients/${clientId}/webhooks/`,
    delete: (clientId: string, webhookId: string) => `clients/${clientId}/webhooks/${webhookId}/`,
  },
  webService: {
    all: 'external-ws/',
    create: 'external-ws/',
    detail: (webServiceId: string) => `external-ws/${webServiceId}/`,
    update: (webServiceId: string) => `external-ws/${webServiceId}/`,
  },
};
