import { FullContainer } from "../ui/FullContainer";
import { useStyles } from "../../hooks/useStyles";
import ReportsFilters from "../emissionsReports/ReportsFilters";
import { ReportsList } from "../emissionsReports/ReportsList";

export function EmissionsReportPage() {
  const { css, theme } = useStyles();

  return (
    <FullContainer
      verticalFlex
      classNames={`${css({
        padding: theme.sizing.scale400,
        gap: theme.sizing.scale500,
        boxSizing: 'border-box',
      })}`}
    > 
      <ReportsFilters />
      <ReportsList />
    </FullContainer>
  );
}
