import { useEffect, useMemo, useState } from "react";
import { VEHICLE_COLOR_OPTIONS } from "../../constants/vehicle";
import { FormControl } from "baseui/form-control";
import { Select } from "baseui/select";
import { FlexColumn } from "../ui/FlexColumn";
import { useStyles } from "../../hooks/useStyles";
import { FlexRow } from "../ui/FlexRow";
import { ColoredCircle } from "../ui/ColoredCircle";

type Props = {
  selectedColor?: string,
  onChangeColor: (color?: string) => void
  required?: boolean,
  label?: string,
  disabled?: boolean,
  maxWidth?: number;
  selectMaxWidth?: number;
}
export function ColorSelect({
  selectedColor, 
  onChangeColor, 
  required = false,
  label = 'Color',
  disabled = false,
  maxWidth,
  selectMaxWidth
}: Readonly<Props>) {
  const { theme, css } = useStyles();
  const [colorSelected, setColorSelected] = useState<string>();

  useEffect(() => {
    if(selectedColor) {
      setColorSelected(VEHICLE_COLOR_OPTIONS.some(color => color.value = selectedColor) ? selectedColor : undefined)
    } else {
      setColorSelected(undefined)
    }
  }, [selectedColor])

  const vehicleColorOptions = useMemo(() => {
    if (!VEHICLE_COLOR_OPTIONS) return;
    return VEHICLE_COLOR_OPTIONS.map((color) => ({ id: color.value, label: color.name }));
  }, [VEHICLE_COLOR_OPTIONS]);
  
  return (
    <FlexColumn
      gap={theme.sizing.scale200}
      classNames={css({ width: '100%', maxWidth: maxWidth && `${maxWidth}px` })}
    >
      <FormControl
        label={`${label} ${required ? '*' : ''}`}
        disabled={disabled}
        overrides={{
          LabelContainer: {
            style: {
              marginTop: 0,
              marginBottom: 0,
            },
          },
          ControlContainer: {
            style: { marginBottom: 0 },
          },
        }}
      >
        <Select
          closeOnSelect
          openOnClick
          clearable={false}
          onChange={(params) => onChangeColor(params.option?.id as string | undefined)}
          options={vehicleColorOptions}
          searchable={true}
          size={'compact'}
          value={[{ id: colorSelected }]}
          overrides={{
            Root: {
              style: {
                maxWidth: selectMaxWidth ? `${selectMaxWidth}px` : undefined,
              },
            },
            ValueContainer: {
              component: ({ children }) => {
                return (
                  <FlexRow gap={theme.sizing.scale100}>
                    <span
                      className={css({
                        paddingLeft: theme.sizing.scale400,
                        paddingTop: theme.sizing.scale200,
                        paddingBottom: theme.sizing.scale200,
                        fontWeight: '600',
                        whiteSpace: 'nowrap',
                      })}
                    >
                      {selectedColor && <ColoredCircle color={selectedColor} />}
                    </span>
                    <FlexRow
                      classNames={css({
                        paddingTop: theme.sizing.scale200,
                        paddingBottom: theme.sizing.scale200,
                        gap: theme.sizing.scale100,
                        whiteSpace: 'nowrap',
                      })}
                    >
                      {children}
                    </FlexRow>
                  </FlexRow>
                );
              },
            },
          }}
        />
      </FormControl>
    </FlexColumn>
  )
}