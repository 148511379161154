import { useState } from 'react';
import { ContentContainer } from '../ui/ContentContainer';
import { VersionsFiltersBar } from '../versions/VersionsFiltersBar';
import { VersionsList } from '../versions/VersionsList';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';

export function VersionsPage() {
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [reloadPage, setReloadPage] = useState(false);
  const [brandId, setBrandId] = useState<string>();
  const [subBrandId, setSubBrandId] = useState<string>();

  return (
    <ContentContainer>
      <VersionsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
        selectedBrandId={brandId}
        setSelectedBrandId={setBrandId}
        selectedSubBrandId={subBrandId}
        setSelectedSubBrandId={setSubBrandId}
      />
      <VersionsList
        search={search}
        reloadPage={reloadPage}
        brandId={brandId}
        subBrandId={subBrandId}
      />
    </ContentContainer>
  );
}
