import { DatePicker } from "baseui/datepicker";
import { OnChangeParams } from "baseui/select";
import { useMemo, memo } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { GDL_FILTER_OPTIONS, IVR_FILTER_OPTIONS, ORIGIN_FILTER_OPTIONS } from "../../constants/installations";
import { useStyles } from "../../hooks/useStyles";
import { installationSearchState, installationsStatusState, installationFilterStatusState, installationCampaignState, installationDateRangeState, installationIvrState, sortInstallationState, tableColumnsState, installationFilterGDLState } from "../../storage/Installations";
import { FlexRow } from "../ui/FlexRow";
import { StyledButton } from "../ui/StyledButton";
import { FlexColumn } from "../ui/FlexColumn";
import { Filter } from "baseui/table";
import { EnhancedLabeledSelect } from "../ui/EnhancedLabeledSelect";


function InstallationFilters() {
  const { theme, css } = useStyles();
  const installationsStatusList = useRecoilValue(installationsStatusState);
  const [selectStatus, setSelectStatus] = useRecoilState(installationFilterStatusState);
  const [selectGDLStatus, setSelectGDLStatus] = useRecoilState(installationFilterGDLState);
  const [selectCampaign, setSelectCampaign] = useRecoilState(installationCampaignState);
  const [ivr, setIvr] = useRecoilState(installationIvrState);
  const [installationDateRange, setInstallationDateRange] = useRecoilState(installationDateRangeState);
  const resetSelectStatus = useResetRecoilState(installationFilterStatusState);
  const resetSelectGDLStatus = useResetRecoilState(installationFilterGDLState);
  const resetSelectCampaign = useResetRecoilState(installationCampaignState);
  const resetSearch = useResetRecoilState(installationSearchState);
  const resetIvr = useResetRecoilState(installationIvrState);
  const resetSort = useResetRecoilState(tableColumnsState);
  const resetInstallationDateRange = useResetRecoilState(installationDateRangeState);

  const statusOptions = useMemo(() => {
    if(!installationsStatusList) return;
    return [
      {
        id: '',
        label: 'Todos',
      },
      ...installationsStatusList.map((status) => ({ id: status.clave, label: status.descripcion })),
    ];
  }, [installationsStatusList]);

  const onGDLStatusChange = (params: OnChangeParams) => {
    params.option?.id ? setSelectGDLStatus(params.option.id as string) : setSelectGDLStatus(undefined);
  };

  const onStatusChange = (params: OnChangeParams) => {
    params.option?.id ? setSelectStatus(params.option.id as string) : setSelectStatus('');
  };

  const onOriginChange = (params: OnChangeParams) => {
    params.option?.id ? setSelectCampaign(params.option.id as string) : setSelectCampaign(undefined);
  };

  const onIvrChange = (params: OnChangeParams) => {
    params.option?.id ? setIvr(params.option.id as string) : setIvr(undefined);
  };

  const isActive = useMemo(() => {
    if (installationDateRange && installationDateRange[0] && installationDateRange[1]) {
      return true;
    } else if (selectStatus || selectCampaign || ivr ) {
      return true;
    }
    return false;
  }, [selectStatus, selectCampaign, ivr, installationDateRange])

  const resetFilters = () => {
    resetSearch();
    resetSelectStatus();
    resetSort();
    resetInstallationDateRange();
    resetSelectCampaign();
    resetIvr();
    resetSelectGDLStatus();
  }

  const startDate = installationDateRange && installationDateRange[0] ? new Date(installationDateRange[0]) : undefined;  
  const endDate = installationDateRange && installationDateRange[1] ? new Date(installationDateRange[1]) : undefined;

  return (
    <Filter
      active={isActive}
      overrides={{
        Heading: {
          component: () => <></>
        },
        Footer: {
          component: () => {
            return (
              <FlexRow classNames={css({
                justifyContent: 'end',
                padding: theme.sizing.scale200
              })}>
                <StyledButton
                  onClick={resetFilters}
                  size="mini"
                  kind="tertiary"
                >
                  Limpiar filtros
                </StyledButton>
              </FlexRow>
            )
          }
        },
        Content: {
          style: () => ({
            borderTop: 'none',
            minHeight: '220px'
          })
        }
      }}
    >
      <FlexColumn 
        classNames={css({ 
          paddingTop: theme.sizing.scale500, 
          paddingBottom: theme.sizing.scale500 
        })} 
        gap={theme.sizing.scale200}
      >
        <EnhancedLabeledSelect
          label="Estatus GNP:"
          options={statusOptions}
          value={[{ id: selectStatus }]}
          onChange={onStatusChange}
          disabled={!installationsStatusList?.length}
          inline
          inset
        />
        <EnhancedLabeledSelect
          label="Estatus Gorila:"
          options={GDL_FILTER_OPTIONS}
          value={[{ id: selectGDLStatus }]}
          onChange={onGDLStatusChange}
          inline
          inset
        />
        <EnhancedLabeledSelect
          label="Origen:"
          options={ORIGIN_FILTER_OPTIONS}
          value={[{ id: selectCampaign }]}
          onChange={onOriginChange}
          inline
          inset
        />
        <EnhancedLabeledSelect
          label="IVR:"
          options={IVR_FILTER_OPTIONS}
          value={[{ id: ivr }]}
          onChange={onIvrChange}
          inline
          inset
        />
        <DatePicker
          placeholder="Fecha inicio - Fecha fin"
          maxDate={new Date()}
          range
          size="compact"
          value={[startDate, endDate]}
          onChange={({ date: dates }) => {
            if (dates instanceof Array) {
              if (dates[0] && dates[1]) {
                const endDate = dates[1];
                endDate.setHours(23, 59, 59);
                setInstallationDateRange([dates[0].getTime(), endDate.getTime()]);
              } else if (dates[0]) {
                setInstallationDateRange([dates[0].getTime(), null]);
              }
            }
          }}
        />
      </FlexColumn>
    </Filter>
  );
}

export default memo(InstallationFilters);