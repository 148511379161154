import { Banner, ActionContent } from 'baseui/banner';
import { StyledActionIcon } from './StyledActionIcon';

type Props = {
  actionButton?: boolean;
  full?: boolean;
};

function ActionIcon({ size }: { size: string }) {
  return <StyledActionIcon size={size} />;
}

export function StyledBanner({
  overrides,
  children,
  actionButton = true,
  full,
  ...props
}: React.ComponentProps<typeof Banner> & Props) {
  let action: ActionContent | undefined;
  if (props.action) {
    action = {
      ...props.action,
      icon: actionButton ? ActionIcon : undefined,
    };
  }
  return (
    <Banner
      {...props}
      title={props.title || ''}
      hierarchy={props.hierarchy || 'low'}
      kind={props.kind || 'info'}
      overrides={{
        ...overrides,
        Root: {
          style: {
            ...overrides?.Root?.style,
            width: full ? '100%' : undefined,
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
          },
        },
      }}
      action={action}
    >
      {children}
    </Banner>
  );
}
