import { SnackbarProvider } from 'baseui/snackbar';
import { ToasterContainer } from 'baseui/toast';
import { MapProvider } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import { useStyles } from '../../hooks/useStyles';
import { isLoggedInState, sidenavIsPinnedState } from '../../storage/app';
import { AppProvider } from '../providers/AppProvider';
import AssetsTypesProvider from '../providers/AssetsTypesProvider';
import { AuthProvider } from '../providers/AuthProvider';
import AvailableCommandsProvider from '../providers/AvailableCommandsProvider';
import { HeadTitle } from '../shared/HeadTitle';
import { Navbar } from '../shared/navbar/Navbar';
import { Sidenav } from '../shared/navbar/Sidenav';
import { FlexColumn } from '../ui/FlexColumn';
import { FullContainer } from '../ui/FullContainer';
import PageLayout from './PageLayout';
import { EXPANDED_SIDENAV_WIDTH, SIDENAV_WIDTH } from '../../constants/app';
import CampaignProvider from '../providers/CampaignsProvider';
import InsatallationStatusProvider from '../providers/InstallationStatusProvider';
import LogsTypesProvider from '../providers/LogsTypesProvider';

export function CrmLayout() {
  const { css, theme } = useStyles();
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const sidenavIsPinned = useRecoilValue(sidenavIsPinnedState);

  const marginLeft = (() => {
    if (sidenavIsPinned) {
      return `${EXPANDED_SIDENAV_WIDTH}px`;
    }
    return `${SIDENAV_WIDTH}px`;
  })();

  return (
    <MapProvider>
      <SnackbarProvider placement="top">
        <AuthProvider />
        <HeadTitle />
        {isLoggedIn && (
          <>
            <AppProvider />
            <AssetsTypesProvider />
            <AvailableCommandsProvider />
            <LogsTypesProvider />
            <InsatallationStatusProvider />
            <CampaignProvider />
            <FullContainer flexible>
              <Sidenav />
              <FlexColumn
                classNames={css({
                  flex: 1,
                  overflowX: 'hidden',
                  marginLeft: marginLeft,
                  transition: 'margin-left 0.3s ease-in-out',
                })}
                gap={0}
              >
                <Navbar />
                <PageLayout />
              </FlexColumn>
            </FullContainer>
            <ToasterContainer
              autoHideDuration={2500}
              placement="topRight"
              resetAutoHideTimerOnUpdate
              usePortal={false}
              closeable={true}
              overrides={{
                Root: {
                  style: {
                    marginTop: '64px',
                    marginRight: theme.sizing.scale400,
                  },
                },
              }}
            />
          </>
        )}
      </SnackbarProvider>
    </MapProvider>
  );
}
