import { FormControl } from 'baseui/form-control';
import { OnChangeParams, Options, Select, Value } from 'baseui/select';
import { useStyles } from '../../hooks/useStyles';
import { FlexColumn } from './FlexColumn';

type Props = {
  label: string;
  options: Options;
  value?: Value;
  searchable?: boolean;
  multi?: boolean;
  disabled?: boolean;
  labelKey?: string;
  valueKey?: string;
  onChange: (params: OnChangeParams) => void;
  maxWidth?: number;
  placeholder?: string;
  selectMaxWidth?: number;
  required?: boolean;
  maxDropdownHeight?: string
};

export function LabeledSelect({
  value,
  onChange,
  options,
  label,
  searchable = false,
  multi = false,
  disabled = false,
  labelKey,
  valueKey,
  maxWidth,
  placeholder,
  selectMaxWidth,
  required = false,
  maxDropdownHeight = '150px'
}: Props) {
  const { theme, css } = useStyles();

  return (
    <FlexColumn
      gap={theme.sizing.scale200}
      classNames={css({ width: '100%', maxWidth: maxWidth && `${maxWidth}px` })}
    >
      <FormControl
        label={`${label} ${required ? '*' : ''}`}
        disabled={disabled}
        overrides={{
          LabelContainer: {
            style: {
              marginTop: 0,
              marginBottom: 0,
            },
          },
          ControlContainer: {
            style: { marginBottom: 0 },
          },
        }}
      >
        <Select
          closeOnSelect
          openOnClick
          clearable={false}
          onChange={onChange}
          options={options}
          searchable={searchable}
          multi={multi}
          size={'compact'}
          value={value}
          labelKey={labelKey}
          valueKey={valueKey}
          placeholder={placeholder}
          maxDropdownHeight={maxDropdownHeight}
          overrides={{
            Root: {
              style: {
                maxWidth: selectMaxWidth ? `${selectMaxWidth}px` : undefined,
              },
            },
          }}
        />
      </FormControl>
    </FlexColumn>
  );
}
