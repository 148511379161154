import { useState } from 'react';
import { DeviceModelsFiltersBar } from '../devicesModels/DevicesModelsFiltersBar';
import { DeviceModelsList } from '../devicesModels/DevicesModelsList';
import { ContentContainer } from '../ui/ContentContainer';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';

export function DevicesModelsPage() {
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [reloadPage, setReloadPage] = useState(false);
  const [brandId, setBrandId] = useState<string>();

  return (
    <ContentContainer>
      <DeviceModelsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
        selectedDeviceBrandId={brandId}
        setSelectedDeviceBrandId={setBrandId}
      />
      <DeviceModelsList
        search={search}
        reloadPage={reloadPage}
        deviceBrandId={brandId}
      />
    </ContentContainer>
  );
}
