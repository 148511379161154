import { FullContainer } from "../ui/FullContainer";
import { useStyles } from "../../hooks/useStyles";
import PhoneTableFilters from "../blacklist/PhoneTableFilters";
import { PhoneBlacklistTable } from "../blacklist/PhoneBlacklistTable";

export function PhonesBlacklistPage() {
  const { css, theme } = useStyles();

  return (
    <FullContainer
      verticalFlex
      classNames={`${css({
        padding: theme.sizing.scale400,
        gap: theme.sizing.scale500,
        boxSizing: 'border-box',
      })}`}
    > 
    <PhoneTableFilters />
    <PhoneBlacklistTable />
    </FullContainer>
  );
}
