import { LabelMedium, ParagraphMedium } from 'baseui/typography';
import { useStyles } from '../../hooks/useStyles';
import { MaterialIconName } from '../../types/components';
import { EllipsedText } from './EllipsedText';
import { FlexRow } from './FlexRow';
import { MaterialIcon } from './MaterialIcon';

type Props = {
  labelWidth?: string;
  data: string | string[];
  label: string;
  icon?: MaterialIconName;
};
export function LabeledIconData({ labelWidth = '140px', data, label, icon }: Props) {
  const { css, theme } = useStyles();

  return (
    <div
      className={css({
        alignItems: typeof data === 'string' ? 'center' : 'initial',
        display: 'flex',
        flexDirection: typeof data === 'string' ? 'row' : 'column',
        gap: '8px',
      })}
    >
      <FlexRow
        gap={'8px'}
        classNames={css({
          alignItems: 'center',
          width: typeof data === 'string' ? labelWidth : '100%',
        })}
      >
        {icon && <MaterialIcon name={icon} />}
        <LabelMedium>{label}</LabelMedium>
      </FlexRow>
      {typeof data === 'string' && (
        <ParagraphMedium
          margin={0}
          color={theme.colors.contentSecondary}
        >
          <EllipsedText inline>{data}</EllipsedText>
        </ParagraphMedium>
      )}
      {typeof data !== 'string' && (
        <ParagraphMedium
          margin={'0px 0px 8px 1rem'}
          color={theme.colors.contentSecondary}
        >
          <EllipsedText inline>{`• ${data.toString().replaceAll(',', ', ')}`}</EllipsedText>
        </ParagraphMedium>
      )}
    </div>
  );
}
