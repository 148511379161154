import { useStyles } from '../../hooks/useStyles';
import { GenericComponent } from '../../types/components';
import { FlexColumn } from '../ui/FlexColumn';
import { FlexRow } from '../ui/FlexRow';

export function LoginContainer({ children }: GenericComponent) {
  const { css, classes, theme } = useStyles();

  return (
    <FlexRow
      gap={0}
      classNames={`${classes.fillParent}`}
    >
      <FlexColumn
        centered
        classNames={`${classes.fillParent}`}
      >
        {children}
      </FlexColumn>
      <FlexColumn
        centered
        classNames={`${classes.fillParent} ${css({
          backgroundColor: theme.colors.black,
          overflow: "hidden",
          position: "relative",
          boxSizing: "border-box",
          display: "none",
          [theme.mediaQuery.large]: {
            display: "flex"
          },
        })}`}
      >
        <img src='img/logo_circulo.svg' width={"400px"}></img>
      </FlexColumn>
    </FlexRow>
  );
}
