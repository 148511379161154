import { Button } from 'baseui/button';
import { FlexRow } from '../ui/FlexRow';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledSearchBar } from '../ui/StyledSearchBar';
import { StyledTooltip } from '../ui/StyledTooltip';
import { useStyles } from '../../hooks/useStyles';
import { StyledButton } from '../ui/StyledButton';
import DeviceDetailModal from './modal/DeviceDetailModal';
import { useModal } from '../../hooks/useModal';
import { ClientSelect } from '../shared/selects/ClientSelect';
import { SubClientSelect } from '../shared/selects/SubClientSelect';

type Props = {
  search?: string;
  onChangeSearch: (search?: string) => void;
  onReloadPage: () => void;
  selectedClientId?: string;
  setSelectedClientId: (clientId?: string) => void;
  selectedSubClientId?: string;
  setSelectedSubClientId: (subClientId?: string) => void;
};
export function DevicesFiltersBar({
  search,
  onChangeSearch,
  onReloadPage,
  selectedClientId,
  setSelectedClientId,
  selectedSubClientId,
  setSelectedSubClientId,
}: Props) {
  const { css, theme } = useStyles();
  const { openModal, closeModal, showModal } = useModal();

  const resetFilters = () => {
    onChangeSearch(undefined);
    setSelectedClientId(undefined);
  };

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '50%',
        })}
      >
        <StyledSearchBar
          value={search ?? ''}
          onChange={onChangeSearch}
          clearable
        />
        <ClientSelect
          selectedClientId={selectedClientId}
          onChangeClientId={setSelectedClientId}
          inline
        />
        <SubClientSelect
          selectedClientId={selectedClientId}
          selectedSubClientId={selectedSubClientId}
          onChangeSubClientId={setSelectedSubClientId}
          disabled={!selectedClientId}
          inline
        />
        <StyledTooltip
          content={'Restaurar filtros'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={resetFilters}
            kind="tertiary"
          >
            <MaterialIcon name={'autorenew'} />
          </Button>
        </StyledTooltip>
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
        <StyledButton
          onClick={openModal}
          startEnhancer={
            <MaterialIcon
              name="add"
              size="xs-mini"
              color={theme.colors.contentInversePrimary}
            />
          }
        >
          Nuevo
        </StyledButton>
      </FlexRow>
      {showModal && <DeviceDetailModal
        onClose={(updateTable) => {
          if (updateTable) {
            onReloadPage();
          }
          closeModal();
        }}
        isOpen={showModal}
      />}
    </FlexRow>
  );
}
