import { Button } from 'baseui/button';
import { HeadingXSmall } from 'baseui/typography';
import { useState } from 'react';
import { useStyles } from '../../../hooks/useStyles';
import { SubClient } from '../../../types/subClient';
import { ContentContainer } from '../../ui/ContentContainer';
import { FlexColumn } from '../../ui/FlexColumn';
import { FlexRow } from '../../ui/FlexRow';
import { MaterialIcon } from '../../ui/MaterialIcon';
import { StyledTooltip } from '../../ui/StyledTooltip';
import { SubClientForm } from '../SubClientForm';
import { SubClientInfo } from '../SubClientInfo';

type Props = {
  subClient: SubClient;
  onReloadPage: () => void;
};

export function DetailTab({ subClient, onReloadPage }: Readonly<Props>) {
  const [editingMode, setEditingMode] = useState(false);
  const { css, theme } = useStyles();

  return (
    <ContentContainer>
      <FlexColumn
        classNames={css({
          width: '50vw',
          maxWidth: '800px',
          boxSizing: 'border-box',
        })}
      >
        <FlexRow
          classNames={`${css({
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: theme.sizing.scale950,
          })}`}
        >
          <HeadingXSmall margin={0}>Generales</HeadingXSmall>
          {!editingMode && (
            <StyledTooltip
              content={'Editar'}
              showArrow={false}
            >
              <Button
                size="compact"
                shape="square"
                onClick={() => setEditingMode(true)}
                kind="tertiary"
              >
                <MaterialIcon
                  name={'edit'}
                  size="xs-mini"
                />
              </Button>
            </StyledTooltip>
          )}
        </FlexRow>
        {!editingMode && <SubClientInfo subClient={subClient} />}
        {editingMode && (
          <SubClientForm
            onCancel={() => setEditingMode(false)}
            afterSave={() => {
              setEditingMode(false);
              onReloadPage();
            }}
            subClient={subClient}
          />
        )}
      </FlexColumn>
    </ContentContainer>
  );
}
