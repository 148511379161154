import { Button } from 'baseui/button';
import { useMemo, useState } from 'react';
import { VEHICLE_TYPES_OPTIONS } from '../../constants/vehicle';
import { useStyles } from '../../hooks/useStyles';
import { Asset, AssetMinimal, AssetType } from '../../types/asset';
import { Container } from '../../types/container';
import { Vehicle } from '../../types/vehicle';
import { BrandForm } from '../brands/BrandForm';
import { ColorSelect } from '../shared/ColorSelect';
import { BrandSelect } from '../shared/selects/BrandSelect';
import { SubBrandSelect } from '../shared/selects/SubBrandSelect';
import { VersionSelect } from '../shared/selects/VersionSelect';
import { SubBrandForm } from '../subBrands/SubBrandForm';
import { FlexColumn } from '../ui/FlexColumn';
import { FlexRow } from '../ui/FlexRow';
import { LabeledInput } from '../ui/LabeledInput';
import { LabeledSelect } from '../ui/LabeledSelect';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledTooltip } from '../ui/StyledTooltip';
import { VersionForm } from '../versions/VersionForm';

type Props = {
  assetType: Omit<AssetType, ''>;
  asset?: Asset | AssetMinimal;
  editingAsset: AssetMinimal;
  onChange: React.Dispatch<React.SetStateAction<AssetMinimal>>;
};

export function AssetTypeFormFields({ asset, assetType, editingAsset, onChange }: Readonly<Props>) {
  const { css } = useStyles();
  const [createBrand, setCreateBrand] = useState(false);
  const [createSubBrand, setCreateSubBrand] = useState(false);
  const [createVersion, setCreateVersion] = useState(false);

  const vehicleTypesOptions = useMemo(() => {
    if (!VEHICLE_TYPES_OPTIONS) return;
    return VEHICLE_TYPES_OPTIONS.map((type) => ({
      id: type,
      label: type.charAt(0).toUpperCase() + type.slice(1),
    }));
  }, []);

  const onVehicleChange = (value: string | boolean | number | undefined | null | {}, field: keyof Vehicle) => {
    onChange((prev) => {
      const vehicle = { ...prev!.vehicle!, [field]: value };
      return {
        ...prev!,
        vehicle,
      };
    });
  };

  const onContainerChange = (value: string, field: keyof Container) => {
    onChange((prev) => {
      const container = { ...prev!.container!, [field]: value };
      return {
        ...prev!,
        container,
      };
    });
  };

  return (
    <>
      {assetType === 'vehicle' && (
        <FlexColumn>
          <FlexRow breakPoint="isXSmall">
            {createBrand && (
              <FlexRow classNames={css({ width: '100%', alignItems: 'end' })}>
                <BrandForm
                  afterSave={() => {
                    setCreateBrand(false);
                    setCreateSubBrand(false);
                    setCreateVersion(false);
                    onVehicleChange({ _id: undefined }, 'brand');
                    onVehicleChange({ _id: undefined }, 'subBrand');
                    onVehicleChange({ _id: undefined }, 'version');
                  }}
                  onCancel={() => {
                    setCreateBrand(false);
                    onVehicleChange({ _id: editingAsset?.vehicle?.brand._id }, 'brand');
                  }}
                  inline
                />
              </FlexRow>
            )}
            {!createBrand && (
              <FlexRow
                classNames={css({ width: '100%', alignItems: 'end' })}
                gap={'8px'}
              >
                <BrandSelect
                  brand={editingAsset?.vehicle?.brand?.name ? editingAsset?.vehicle?.brand : undefined}
                  selectedBrandId={editingAsset?.vehicle?.brand?._id}
                  onChangeBrand={(brand) => {
                    if (createSubBrand) {
                      setCreateSubBrand(false);
                      onVehicleChange({ _id: undefined }, 'subBrand');
                    }
                    if (createVersion) {
                      setCreateVersion(false);
                      onVehicleChange({ _id: undefined }, 'version');
                    }
                    onVehicleChange(brand, 'brand');
                  }}
                  isFilter={false}
                  required
                />
                <StyledTooltip
                  content={'Nueva'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => setCreateBrand(true)}
                    kind="tertiary"
                  >
                    <MaterialIcon
                      name={'add'}
                      size="compact"
                    />
                  </Button>
                </StyledTooltip>
              </FlexRow>
            )}
            {createSubBrand && (
              <FlexRow classNames={css({ width: '100%', alignItems: 'end' })}>
                <SubBrandForm
                  brandId={editingAsset.vehicle?.brand._id}
                  afterSave={() => {
                    setCreateSubBrand(false);
                    setCreateVersion(false);
                    onVehicleChange({ _id: undefined }, 'subBrand');
                    onVehicleChange({ _id: undefined }, 'version');
                  }}
                  onCancel={() => {
                    setCreateSubBrand(false);
                    onVehicleChange({ _id: editingAsset?.vehicle?.subBrand?._id }, 'subBrand');
                  }}
                  inline
                />
              </FlexRow>
            )}
            {!createSubBrand && (
              <FlexRow
                classNames={css({ width: '100%', alignItems: 'end' })}
                gap={'8px'}
              >
                <SubBrandSelect
                  subBrand={editingAsset?.vehicle?.subBrand?.name ? editingAsset?.vehicle?.subBrand : undefined}
                  selectedBrandId={editingAsset?.vehicle?.brand?._id}
                  selectedSubBrandId={editingAsset?.vehicle?.subBrand?._id}
                  onChangeSubBrand={(subBrandId) => {
                    if (createVersion) {
                      setCreateVersion(false);
                      onVehicleChange({ _id: undefined }, 'version');
                    }
                    onVehicleChange({ _id: subBrandId }, 'subBrand');
                  }}
                  isFilter={false}
                  disabled={!editingAsset.vehicle?.brand?._id}
                  required
                />
                <StyledTooltip
                  content={'Nueva'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => setCreateSubBrand(true)}
                    kind="tertiary"
                    disabled={!editingAsset.vehicle?.brand?._id}
                  >
                    <MaterialIcon
                      name={'add'}
                      size="compact"
                    />
                  </Button>
                </StyledTooltip>
              </FlexRow>
            )}
          </FlexRow>
          <FlexRow breakPoint="isXSmall">
            {createVersion && (
              <FlexRow classNames={css({ width: '100%', alignItems: 'end' })}>
                <VersionForm
                  brandId={editingAsset.vehicle?.brand?._id}
                  subBrandId={editingAsset.vehicle?.subBrand?._id}
                  afterSave={() => {
                    setCreateVersion(false);
                    onVehicleChange({ _id: asset?.vehicle?.version?._id }, 'version');
                  }}
                  onCancel={() => {
                    setCreateVersion(false);
                    onVehicleChange({ _id: asset?.vehicle?.version?._id }, 'version');
                  }}
                  inline
                />
              </FlexRow>
            )}
            {!createVersion && (
              <FlexRow
                classNames={css({ width: '100%', alignItems: 'end' })}
                gap={'8px'}
              >
                <VersionSelect
                  version={editingAsset?.vehicle?.version?.name ? editingAsset?.vehicle?.version : undefined}
                  selectedBrandId={editingAsset?.vehicle?.brand?._id}
                  selectedSubBrandId={editingAsset?.vehicle?.subBrand?._id}
                  selectedVersionId={editingAsset?.vehicle?.version?._id}
                  onChangeVersion={(versionId) => onVehicleChange({ _id: versionId }, 'version')}
                  isFilter={false}
                  disabled={!editingAsset.vehicle?.subBrand?._id}
                />
                <StyledTooltip
                  content={'Nueva'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => setCreateVersion(true)}
                    kind="tertiary"
                    disabled={!editingAsset.vehicle?.subBrand?._id}
                  >
                    <MaterialIcon
                      name={'add'}
                      size="compact"
                    />
                  </Button>
                </StyledTooltip>
              </FlexRow>
            )}
            <LabeledInput
              label="Modelo"
              type="number"
              value={editingAsset?.vehicle?.year}
              onChange={(year) => onVehicleChange(year, 'year')}
              required
            />
          </FlexRow>
          <FlexRow>
            <ColorSelect
              selectedColor={editingAsset?.vehicle?.color}
              onChangeColor={(color) => onVehicleChange(color, 'color')}
              required
            />
            <LabeledInput
              label="Placa"
              value={editingAsset?.vehicle?.licensePlate}
              onChange={(plate) => onVehicleChange(plate, 'licensePlate')}
              required
            />
          </FlexRow>
          <FlexRow>
            <LabeledSelect
              label="Tipo de vehículo"
              options={vehicleTypesOptions}
              value={[{ id: editingAsset?.vehicle?.vehicleType }]}
              onChange={(params) => onVehicleChange(params.option?.id, 'vehicleType')}
              required
            />
            <LabeledInput
              label="No. Serie"
              value={editingAsset?.vehicle?.vin}
              onChange={(vin) => onVehicleChange(vin, 'vin')}
              required
            />
          </FlexRow>
        </FlexColumn>
      )}
      {editingAsset?.assetType === 'container' && (
        <FlexColumn>
          <FlexRow>
            <LabeledInput
              label="Identificador"
              value={editingAsset?.container?.identifier}
              onChange={(identifier) => onContainerChange(identifier, 'identifier')}
              required
            />
          </FlexRow>
        </FlexColumn>
      )}
    </>
  );
}
