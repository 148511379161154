import { SubAccount } from '../../../types/subAccount';
import { isArrayOfAssets } from '../../../utils/assets';
import { SearchableAssetList } from '../../shared/SearchableAssetList';
import { EmptyState } from '../../ui/EmptyState';

type Props = {
  subAccount: SubAccount;
};
export function SubAccountInfo({ subAccount }: Readonly<Props>) {
  return subAccount.assets.length ? (
    <SearchableAssetList
      assetIds={isArrayOfAssets(subAccount.assets) ? subAccount.assets.map((asset) => asset._id) : subAccount.assets}
      subClientId={subAccount.subClient?._id}
    />
  ) : (
    <EmptyState description="No tiene activos asignados" />
  );
}
