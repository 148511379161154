import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useLoading } from '../../hooks/useLoading';
import { useStyles } from '../../hooks/useStyles';
import { FlexRow } from '../ui/FlexRow';

type Props = {
  isOpen: boolean;
  description: ReactNode;
  confirmText?: ReactNode;
  cancelText?: ReactNode;
  canConfirm?: boolean;
  onConfirm: () => Promise<void> | void;
  onCancel?: () => void;
};

export function ConfirmModal({
  isOpen,
  description,
  confirmText = 'Aceptar',
  cancelText = 'Cancelar',
  onConfirm,
  onCancel,
  canConfirm = true,
}: Props) {
  const { theme, classes } = useStyles();
  const { loading, startLoading, stopLoading } = useLoading();

  return createPortal(
    <Modal
      closeable={false}
      isOpen={isOpen}
    >
      <ModalHeader>Confirmación</ModalHeader>
      <ModalBody>{description}</ModalBody>
      <ModalFooter>
        <FlexRow
          gap={theme.sizing.scale300}
          classNames={classes.centeredEnd}
        >
          {onCancel && (
            <Button
              kind="tertiary"
              size="compact"
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          )}
          <Button
            onClick={async () => {
              startLoading();
              await onConfirm();
              stopLoading();
            }}
            size="compact"
            isLoading={loading}
            disabled={!canConfirm}
          >
            {confirmText}
          </Button>
        </FlexRow>
      </ModalFooter>
    </Modal>,
    document.body
  );
}
