import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ADMIN_USERS_TABLE_COLUMNS_NAME } from '../../constants/users';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import { deleteAdminUser, getAdminUsers } from '../../services/userService';
import { AdminUser } from '../../types/user';
import { ListWrapper } from '../shared/ListWrapper';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledTooltip } from '../ui/StyledTooltip';
import UserDetailModal from './modal/UserDetailModal';
import { ParagraphMedium, ParagraphXSmall } from 'baseui/typography';
import { EllipsedText } from '../ui/EllipsedText';
import { usersSearchState } from '../../storage/adminUsers';
import { FlexRow } from '../ui/FlexRow';
import { ConfirmModal } from '../shared/ConfirmModal';
import { useFeedback } from '../../hooks/useFeedback';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import useUpdateEffect from '../../hooks/useUpdateEffect';

type Props = {
  reloadPage?: boolean;
};

export function UserList({ reloadPage }: Readonly<Props>) {
  const [users, setUsers] = useState<AdminUser[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedUser, setSelectedUser] = useState<AdminUser>();
  const { openModal, closeModal, showModal } = useModal();
  const { openModal: openConfirmModal, closeModal: closeConfirmModal, showModal: showConfirmModal } = useModal();
  const search = useRecoilValue(usersSearchState);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadUsersTableData = async () => {
      const response = await getAdminUsers({
        page,
        q: search,
      });
      if (response?.error) {
        setUsers(undefined);
        setTotalItems(0);
      } else {
        setUsers(response?.users);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadUsersTableData();
  }, [page]);

  const openUserModal = (user: AdminUser) => {
    setSelectedUser(user);
    openModal();
  };

  const closeUserModal = (updateTable?: boolean) => {
    setSelectedUser(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  const openDeleteModal = (user: AdminUser) => {
    setSelectedUser(user);
    openConfirmModal();
  }; 

  const closeDeleteModal = (updateTable?: boolean) => {
    setSelectedUser(undefined);
    closeConfirmModal();
    if (updateTable) setPage(0);
  };

  const onDeleteUser = async () => {
    if (!selectedUser) return;
    const response = await deleteAdminUser(selectedUser._id);
    if (response?.error) {
      showFailFeedback(response.error || FEEDBACK.deleted(FEEDBACK_PREFIXES.user, selectedUser.username));
    } else {
      showPositiveFeedback(FEEDBACK.deleted(FEEDBACK_PREFIXES.user, selectedUser.username));
    }
    closeDeleteModal(true);
  };

  return (
    <>
      <ListWrapper
        columns={ADMIN_USERS_TABLE_COLUMNS_NAME}
        data={users?.map((user, i) => {
          return {
            items: [
              <ParagraphXSmall margin={0} key={`user-username-${i}`}>
                <EllipsedText>{user.username ?? '-'}</EllipsedText>
              </ParagraphXSmall>,
              <ParagraphXSmall margin={0} key={`user-name-${i}`}>
                <EllipsedText>{user.name ?? '-'}</EllipsedText>
              </ParagraphXSmall>,
              <ParagraphXSmall margin={0} key={`user-email-${i}`}>
                <EllipsedText>{user.email ?? '-'}</EllipsedText>
              </ParagraphXSmall>,
              <FlexRow key={`user-action-${i}`} gap={0}>
                <StyledTooltip
                  content={'Ver detalles'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openUserModal(user)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'visibility'} />
                  </Button>
                </StyledTooltip>
                <StyledTooltip
                  content={'Eliminar'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openDeleteModal(user)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'delete'} />
                  </Button>
                </StyledTooltip>
              </FlexRow>,
            ],
          };
        })}
        hasData={users !== undefined}
        isEmpty={!users?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun usuario"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && <UserDetailModal
        onClose={(updateTable) => closeUserModal(updateTable)}
        isOpen={showModal}
        selectedUser={selectedUser}
      />}
      <ConfirmModal
        isOpen={showConfirmModal}
        onCancel={closeDeleteModal}
        onConfirm={onDeleteUser}
        confirmText="Eliminar"
        description={<ParagraphMedium>¿Desea eliminar el usuario {selectedUser?.username}?</ParagraphMedium>}
      />
    </>
  );
}
