import { useState } from 'react';
import { ClientsFiltersBar } from '../clients/ClientsFiltersBar';
import { ClientsList } from '../clients/ClientsList';
import { ContentContainer } from '../ui/ContentContainer';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';

export function ClientsPage() {
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <ClientsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <ClientsList
        search={search}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
