import { useStyles } from '../../hooks/useStyles';
import { MaterialIconName } from '../../types/components';
import {
  AccountCircle,
  Add,
  AltRoute,
  AlternateEmail,
  Analytics,
  ArrowBackIos,
  Article,
  AssistantDirection,
  Atr,
  Autorenew,
  BarChart,
  Battery0Bar,
  Battery2Bar,
  Battery3Bar,
  BatteryFull,
  Beenhere,
  Block,
  BusinessCenter,
  CalendarClock,
  Cancel,
  CarRepair,
  CenterFocusWeak,
  CheckCircle,
  Circle,
  Clear,
  Close,
  CloudDownload,
  Commute,
  DateRange,
  Delete,
  Description,
  DeviceHub,
  Devices,
  DevicesOther,
  DirectionsCar,
  DoNotDisturbOn,
  Done,
  Download,
  DownloadForOffline,
  Edit,
  EventAvailable,
  FileCopy,
  FilePresent,
  FileUpload,
  FilterList,
  Group,
  GroupWork,
  Handyman,
  HomePin,
  HomeRepairService,
  Hotel,
  Info,
  InsertDriveFile,
  Keep,
  KeepOff,
  Key,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  Label,
  Layers,
  LinearScale,
  Link,
  LocalGasStation,
  LocalShipping,
  LocationOn,
  LocationSearching,
  Lock,
  Logout,
  Map,
  Menu,
  Mood,
  MoreHoriz,
  MoreVert,
  Moving,
  MyLocation,
  NavigateNext,
  NestCamOutdoor,
  Notifications,
  OpenInNew,
  Percent,
  Person,
  PhoneIphone,
  PinDrop,
  Place,
  PlaylistAdd,
  PlaylistRemove,
  Polyline,
  PowerSettingsNew,
  Recycling,
  Refresh,
  Remove,
  RemoveCircle,
  Search,
  Settings,
  SettingsRemote,
  SimCard,
  Square,
  Summarize,
  SupportAgent,
  Sync,
  Tablet,
  TaskAlt,
  Timeline,
  Transportation,
  Upload,
  Verified,
  Visibility,
  VpnLock,
  Warning,
  Workspaces,
  ZonePersonAlert,
  ZoomInMap,
  ZoomOutMap,
} from '../shared/MaterialIcons';

type Props = {
  name: MaterialIconName;
  warn?: boolean;
  success?: boolean;
  size?: 'default' | 'compact' | 'mini' | string;
  color?: string;
};

export function MaterialIcon({ name, warn = false, success = false, size = 'default', color }: Props) {
  const { theme } = useStyles();
  const fontSize = (() => {
    if (size === 'mini') return theme.sizing.scale700;
    if (size === 'compact') return theme.sizing.scale750;
    if (size === 'default') return theme.sizing.scale800;
    if (size === 'xs-mini') return theme.sizing.scale600;
    return size;
  })();
  const iconColor = warn ? theme.colors.negative : success ? theme.colors.positive : color ? color : '';

  const icon = (() => {
    switch (name) {
      case 'account_circle':
        return (
          <AccountCircle
            color={iconColor}
            size={fontSize}
          />
        );
      case 'add':
        return (
          <Add
            color={iconColor}
            size={fontSize}
          />
        );
      case 'alt_route':
        return (
          <AltRoute
            color={iconColor}
            size={fontSize}
          />
        );
      case 'alternate_email':
        return (
          <AlternateEmail
            color={iconColor}
            size={fontSize}
          />
        );
      case 'analytics':
        return (
          <Analytics
            color={iconColor}
            size={fontSize}
          />
        );
      case 'arrow_back_ios':
        return (
          <ArrowBackIos
            color={iconColor}
            size={fontSize}
          />
        );
      case 'article':
        return (
          <Article
            color={iconColor}
            size={fontSize}
          />
        );
      case 'assistant_direction':
        return (
          <AssistantDirection
            color={iconColor}
            size={fontSize}
          />
        );
      case 'atr':
        return (
          <Atr
            color={iconColor}
            size={fontSize}
          />
        );
      case 'autorenew':
        return (
          <Autorenew
            color={iconColor}
            size={fontSize}
          />
        );
      case 'bar_chart':
        return (
          <BarChart
            color={iconColor}
            size={fontSize}
          />
        );
      case 'battery_0_bar':
        return (
          <Battery0Bar
            color={iconColor}
            size={fontSize}
          />
        );
      case 'battery_2_bar':
        return (
          <Battery2Bar
            color={iconColor}
            size={fontSize}
          />
        );
      case 'battery_3_bar':
        return (
          <Battery3Bar
            color={iconColor}
            size={fontSize}
          />
        );
      case 'battery_full':
        return (
          <BatteryFull
            color={iconColor}
            size={fontSize}
          />
        );
      case 'beenhere':
        return (
          <Beenhere
            color={iconColor}
            size={fontSize}
          />
        );
      case 'block':
        return (
          <Block
            color={iconColor}
            size={fontSize}
          />
        );
      case 'business_center':
        return (
          <BusinessCenter
            color={iconColor}
            size={fontSize}
          />
        );
      case 'calendar_clock':
        return (
          <CalendarClock
            color={iconColor}
            size={fontSize}
          />
        );
      case 'cancel':
        return (
          <Cancel
            color={iconColor}
            size={fontSize}
          />
        );
      case 'car_repair':
        return (
          <CarRepair
            color={iconColor}
            size={fontSize}
          />
        );
      case 'center_focus_weak':
        return (
          <CenterFocusWeak
            color={iconColor}
            size={fontSize}
          />
        );
      case 'check_circle':
        return (
          <CheckCircle
            color={iconColor}
            size={fontSize}
          />
        );
      case 'circle':
        return (
          <Circle
            color={iconColor}
            size={fontSize}
          />
        );
      case 'clear':
        return (
          <Clear
            color={iconColor}
            size={fontSize}
          />
        );
      case 'close':
        return (
          <Close
            color={iconColor}
            size={fontSize}
          />
        );
      case 'cloud_download':
        return (
          <CloudDownload
            color={iconColor}
            size={fontSize}
          />
        );
      case 'commute':
        return (
          <Commute
            color={iconColor}
            size={fontSize}
          />
        );
      case 'date_range':
        return (
          <DateRange
            color={iconColor}
            size={fontSize}
          />
        );
      case 'delete':
        return (
          <Delete
            color={iconColor}
            size={fontSize}
          />
        );
      case 'description':
        return (
          <Description
            color={iconColor}
            size={fontSize}
          />
        );
      case 'device_hub':
        return (
          <DeviceHub
            color={iconColor}
            size={fontSize}
          />
        );
      case 'devices':
        return (
          <Devices
            color={iconColor}
            size={fontSize}
          />
        );
      case 'devices_other':
        return (
          <DevicesOther
            color={iconColor}
            size={fontSize}
          />
        );
      case 'directions_car':
        return (
          <DirectionsCar
            color={iconColor}
            size={fontSize}
          />
        );
      case 'do_not_disturb_on':
        return (
          <DoNotDisturbOn
            color={iconColor}
            size={fontSize}
          />
        );
      case 'done':
        return (
          <Done
            color={iconColor}
            size={fontSize}
          />
        );
      case 'download':
        return (
          <Download
            color={iconColor}
            size={fontSize}
          />
        );
      case 'download_for_offline':
        return (
          <DownloadForOffline
            color={iconColor}
            size={fontSize}
          />
        );
      case 'edit':
        return (
          <Edit
            color={iconColor}
            size={fontSize}
          />
        );
      case 'event_available':
        return (
          <EventAvailable
            color={iconColor}
            size={fontSize}
          />
        );
      case 'file_copy':
        return (
          <FileCopy
            color={iconColor}
            size={fontSize}
          />
        );
      case 'file_present':
        return (
          <FilePresent
            color={iconColor}
            size={fontSize}
          />
        );
      case 'file_upload':
        return (
          <FileUpload
            color={iconColor}
            size={fontSize}
          />
        );
      case 'filter_list':
        return (
          <FilterList
            color={iconColor}
            size={fontSize}
          />
        );
      case 'group':
        return (
          <Group
            color={iconColor}
            size={fontSize}
          />
        );
      case 'group_work':
        return (
          <GroupWork
            color={iconColor}
            size={fontSize}
          />
        );
      case 'handyman':
        return (
          <Handyman
            color={iconColor}
            size={fontSize}
          />
        );
      case 'home_pin':
        return (
          <HomePin
            color={iconColor}
            size={fontSize}
          />
        );
      case 'home_repair_service':
        return (
          <HomeRepairService
            color={iconColor}
            size={fontSize}
          />
        );
      case 'hotel':
        return (
          <Hotel
            color={iconColor}
            size={fontSize}
          />
        );
      case 'info':
        return (
          <Info
            color={iconColor}
            size={fontSize}
          />
        );
      case 'insert_drive_file':
        return (
          <InsertDriveFile
            color={iconColor}
            size={fontSize}
          />
        );
      case 'keep':
        return (
          <Keep
            color={iconColor}
            size={fontSize}
          />
        );
      case 'keep_off':
        return (
          <KeepOff
            color={iconColor}
            size={fontSize}
          />
        );
      case 'key':
        return (
          <Key
            color={iconColor}
            size={fontSize}
          />
        );
      case 'keyboard_double_arrow_down':
        return (
          <KeyboardDoubleArrowDown
            color={iconColor}
            size={fontSize}
          />
        );
      case 'keyboard_double_arrow_up':
        return (
          <KeyboardDoubleArrowUp
            color={iconColor}
            size={fontSize}
          />
        );
      case 'label':
        return (
          <Label
            color={iconColor}
            size={fontSize}
          />
        );  
      case 'layers':
        return (
          <Layers
            color={iconColor}
            size={fontSize}
          />
        );
      case 'linear_scale':
        return (
          <LinearScale
            color={iconColor}
            size={fontSize}
          />
        );
      case 'link':
        return (
          <Link
            color={iconColor}
            size={fontSize}
          />
        );
      case 'local_gas_station':
        return (
          <LocalGasStation
            color={iconColor}
            size={fontSize}
          />
        );
      case 'local_shipping':
        return (
          <LocalShipping
            color={iconColor}
            size={fontSize}
          />
        );
      case 'location_on':
        return (
          <LocationOn
            color={iconColor}
            size={fontSize}
          />
        );
      case 'location_searching':
        return (
          <LocationSearching
            color={iconColor}
            size={fontSize}
          />
        );
      case 'lock':
        return (
          <Lock
            color={iconColor}
            size={fontSize}
          />
        );
      case 'logout':
        return (
          <Logout
            color={iconColor}
            size={fontSize}
          />
        );
      case 'map':
        return (
          <Map
            color={iconColor}
            size={fontSize}
          />
        );
      case 'menu':
        return (
          <Menu
            color={iconColor}
            size={fontSize}
          />
        );
      case 'mood':
        return (
          <Mood
            color={iconColor}
            size={fontSize}
          />
        );
      case 'more_horiz':
        return (
          <MoreHoriz
            color={iconColor}
            size={fontSize}
          />
        );
      case 'more_vert':
        return (
          <MoreVert
            color={iconColor}
            size={fontSize}
          />
        );
      case 'moving':
        return (
          <Moving
            color={iconColor}
            size={fontSize}
          />
        );
      case 'my_location':
        return (
          <MyLocation
            color={iconColor}
            size={fontSize}
          />
        );
      case 'navigate_next':
        return (
          <NavigateNext
            color={iconColor}
            size={fontSize}
          />
        );
      case 'nest_cam_outdoor':
        return (
          <NestCamOutdoor
            color={iconColor}
            size={fontSize}
          />
        );
      case 'notifications':
        return (
          <Notifications
            color={iconColor}
            size={fontSize}
          />
        );
      case 'open_in_new':
        return (
          <OpenInNew
            color={iconColor}
            size={fontSize}
          />
        );
      case 'percent':
        return (
          <Percent
            color={iconColor}
            size={fontSize}
          />
        );
      case 'person':
        return (
          <Person
            color={iconColor}
            size={fontSize}
          />
        );
      case 'phone_iphone':
        return (
          <PhoneIphone
            color={iconColor}
            size={fontSize}
          />
        );
      case 'pin_drop':
        return (
          <PinDrop
            color={iconColor}
            size={fontSize}
          />
        );
      case 'place':
        return (
          <Place
            color={iconColor}
            size={fontSize}
          />
        );
      case 'playlist_add':
        return (
          <PlaylistAdd
            color={iconColor}
            size={fontSize}
          />
        );
      case 'playlist_remove':
        return (
          <PlaylistRemove
            color={iconColor}
            size={fontSize}
          />
        );
      case 'polyline':
        return (
          <Polyline
            color={iconColor}
            size={fontSize}
          />
        );
      case 'power_settings_new':
        return (
          <PowerSettingsNew
            color={iconColor}
            size={fontSize}
          />
        );
      case 'recycling':
        return (
          <Recycling
            color={iconColor}
            size={fontSize}
          />
        );
      case 'refresh':
        return (
          <Refresh
            color={iconColor}
            size={fontSize}
          />
        );
      case 'remove':
        return (
          <Remove
            color={iconColor}
            size={fontSize}
          />
        );
      case 'remove_circle':
        return (
          <RemoveCircle
            color={iconColor}
            size={fontSize}
          />
        );
      case 'search':
        return (
          <Search
            color={iconColor}
            size={fontSize}
          />
        );
      case 'settings':
        return (
          <Settings
            color={iconColor}
            size={fontSize}
          />
        );
      case 'settings_remote':
        return (
          <SettingsRemote
            color={iconColor}
            size={fontSize}
          />
        );
      case 'sim_card':
        return (
          <SimCard
            color={iconColor}
            size={fontSize}
          />
        );
      case 'square':
        return (
          <Square
            color={iconColor}
            size={fontSize}
          />
        );
      case 'summarize':
        return (
          <Summarize
            color={iconColor}
            size={fontSize}
          />
        );     
      case 'support_agent':
        return (
          <SupportAgent
            color={iconColor}
            size={fontSize}
          />
        );
      case 'sync':
        return (
          <Sync
            color={iconColor}
            size={fontSize}
          />
        );
      case 'tablet':
        return (
          <Tablet
            color={iconColor}
            size={fontSize}
          />
        ); 
      case 'task_alt':
        return (
          <TaskAlt
            color={iconColor}
            size={fontSize}
          />
        );
      case 'timeline':
        return (
          <Timeline
            color={iconColor}
            size={fontSize}
          />
        );
      case 'transportation':
        return (
          <Transportation
            color={iconColor}
            size={fontSize}
          />
        );  
      case 'upload':
        return (
          <Upload
            color={iconColor}
            size={fontSize}
          />
        );
      case 'verified':
        return (
          <Verified
            color={iconColor}
            size={fontSize}
          />
        );
      case 'visibility':
        return (
          <Visibility
            color={iconColor}
            size={fontSize}
          />
        );
      case 'vpn_lock':
        return (
          <VpnLock
            color={iconColor}
            size={fontSize}
          />
        );
      case 'warning':
        return (
          <Warning
            color={iconColor}
            size={fontSize}
          />
        );
      case 'workspaces':
        return (
          <Workspaces
            color={iconColor}
            size={fontSize}
          />
        );
      case 'zone_person_alert':
        return (
          <ZonePersonAlert
            color={iconColor}
            size={fontSize}
          />
        );
      case 'zoom_in_map':
        return (
          <ZoomInMap
            color={iconColor}
            size={fontSize}
          />
        );
      case 'zoom_out_map':
        return (
          <ZoomOutMap
            color={iconColor}
            size={fontSize}
          />
        );
      default:
        return <span></span>;
    }
  })();

  return icon;
}
