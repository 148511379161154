import { useState } from 'react';
import { DevicesBrandsFiltersBar } from '../devicesBrands/DevicesBrandsFiltersBar';
import { DevicesBrandsList } from '../devicesBrands/DevicesBrandsList';
import { ContentContainer } from '../ui/ContentContainer';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';

export function DevicesBrandsPage() {
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <DevicesBrandsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <DevicesBrandsList
        search={search}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
