import { useState } from 'react';
import { SubClient } from '../../../types/subClient';
import { ContentContainer } from '../../ui/ContentContainer';
import { SubClientAssetFiltersBar } from './SubClientAssetFiltersBar';
import { SubClientAssetList } from './SubClientAssetList';

type Props = {
  subClient: SubClient;
};

export function AssetsTab({ subClient }: Readonly<Props>) {
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <SubClientAssetFiltersBar
        clientId={subClient.client._id}
        subClientId={subClient._id}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <SubClientAssetList
        clientId={subClient.client._id}
        subClientId={subClient._id}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
