import { useState, useEffect, useMemo } from "react";
import { useStyles } from "../../hooks/useStyles";
import { useLoading } from "../../hooks/useLoading";
import { FlexColumn } from "../ui/FlexColumn";
import { EmptyState } from "../ui/EmptyState";
import { Loading } from "../ui/Loading";
import { StyledPaginatedTable } from "../shared/StyledPaginatedTable";
import { FlexRow } from "../ui/FlexRow";
import { Button } from "baseui/button";
import { MaterialIcon } from "../ui/MaterialIcon";
import { StyledTooltip } from "../ui/StyledTooltip";
import { useFeedback } from "../../hooks/useFeedback";
import { useRecoilValue } from "recoil";
import { phoneBlReloadListState, phoneBlSearchState } from "../../storage/PhoneBlacklist";
import { addRemovePhoneToBl, getBlPhones } from "../../services/blackListService";
import { PHONES_BLACKLIST_TABLE_COLUMNS_NAME } from "../../constants/blacklist";
import { FEEDBACK, FEEDBACK_PREFIXES } from "../../constants/app";

export function PhoneBlacklistTable() {
  const { css, classes  } = useStyles();
  const [phones, setPhones] = useState<string[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [initialLoad, setInitialLoad] = useState(true);
  const { showPositiveFeedback, showFailFeedback } = useFeedback();
  const reloadPage = useRecoilValue(phoneBlReloadListState);
  const search = useRecoilValue(phoneBlSearchState);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (initialLoad) return;
    setPage(0);
  }, [reloadPage, search]);

  const loadPhones = async () => {
    startLoading();
    const { phones, error } = await getBlPhones(search);
    if (!error) {
      setPhones(phones);
    } else {
      showFailFeedback(error);
      setPhones(undefined);
    }
    stopLoading();
  };

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      loadPhones();
      return;
    }
    setInitialLoad(false);
  }, [page]);

  const phoneList = useMemo(() => {
    if (!phones?.length || page === 0) return [];
    else if (phones.length <= 50) return phones
    else if (phones.length > 50) return phones?.slice((50*page)-50, (50*page))
  }, [page, phones])

  const removePhoneToBl = async (phone: string) => {
    const { error } = await addRemovePhoneToBl(phone.trim(), false);
    if (!error) {
      showPositiveFeedback(FEEDBACK.remove(FEEDBACK_PREFIXES.phone, phone))
      setPage(0);
    } else {
      showFailFeedback(error);
    }
  }

  return (
    <FlexColumn classNames={`${classes.flexFill} ${css({ overflow: 'hidden' })}`}>
      {!phones && !loading && <EmptyState title="Todavía no tienes ningun teléfono en la lista negra" />}
      {!phones && loading && <Loading />}
      {phones && (
        <>
          {!!phoneList?.length && (
            <StyledPaginatedTable 
              columns={PHONES_BLACKLIST_TABLE_COLUMNS_NAME}
              data={phoneList.map((phone) => {
                return {
                  items: [
                    phone ?? '-',
                    <FlexRow key={`details-${phone}`}>
                      <StyledTooltip
                        content={'Remover'}
                        showArrow={false}
                      >
                        <Button
                          size="compact"
                          shape="square"
                          onClick={() => {
                            removePhoneToBl(phone)
                          }}
                          kind="tertiary"
                        >
                          <MaterialIcon name={'delete'} />
                        </Button>
                      </StyledTooltip>
                    </FlexRow>
                  ],
                }
              })}
              totalItems={phones.length}
              page={page}
              onPageChange={setPage}
              loading={loading}
            />
          )}
          {!phones.length && (
            <EmptyState
              title="No se encontraron resultados"
              description="Intenta con otra busqueda"
            />
          )}
        </>
      )}
    </FlexColumn>
  );
}
