import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { COMMS_REPORT_TABLE_COLUMNS_NAME } from '../../constants/reports';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import { useMoment } from '../../hooks/useMoment';
import { useStyles } from '../../hooks/useStyles';
import { getReportingDevices } from '../../services/reportsService';
import { commsDateRangeState, commsDownloadState } from '../../storage/reports';
import { CommunicationsReport } from '../../types/report';
import { StyledPaginatedTable } from '../shared/StyledPaginatedTable';
import { EllipsedText } from '../ui/EllipsedText';
import { EmptyState } from '../ui/EmptyState';
import { FlexColumn } from '../ui/FlexColumn';
import { Loading } from '../ui/Loading';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledTooltip } from '../ui/StyledTooltip';
import CommsReportDetailModal from './modal/CommsReportDetailModal';
import { useFeedback } from '../../hooks/useFeedback';

type Props = {
  reloadPage?: boolean;
};
export function CommsReportList({ reloadPage }: Readonly<Props>) {
  const { css, classes } = useStyles();
  const [communicationsReports, setCommunicationsReports] = useState<CommunicationsReport[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedCommunicationsReport, setSelectedCommunicationsReport] = useState<CommunicationsReport>();
  const { openModal, closeModal, showModal } = useModal();
  const { formatDateFromTs } = useMoment();
  const commsDateRange = useRecoilValue(commsDateRangeState);
  const [commsDownload, setCommsDownload] = useRecoilState(commsDownloadState);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  const validCommsDateRange = !!commsDateRange && !!commsDateRange[0] && !!commsDateRange[1];

  useEffect(() => {
    if (!validCommsDateRange) return;
    setPage(0);
  }, [reloadPage, validCommsDateRange]);

  useEffect(() => {
    if (commsDownload) {
      const downloadReport = async () => {
        if (!validCommsDateRange) return;
        const response = await getReportingDevices(commsDateRange[0]!.toString(), commsDateRange[1]!.toString(), 1, commsDownload);
        if (response?.error) {
          showFailFeedback(response.error);
        } else {
          showPositiveFeedback('Se esta generando el archivo...')
        }
        setCommsDownload(false);
      }
      downloadReport();
      return
    } 
  }, [commsDownload])

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadCommunicationsReportTableData = async () => {
      if (!validCommsDateRange) return;
      const response = await getReportingDevices(commsDateRange[0]!.toString(), commsDateRange[1]!.toString(), page, commsDownload);
      if (response?.error) {
        setCommunicationsReports(undefined);
        setTotalItems(0);
      } else {
        setCommunicationsReports(response?.communicationsReports);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadCommunicationsReportTableData();
  }, [page]);

  const openCommunicationsReportModal = (communicationsReport: CommunicationsReport) => {
    setSelectedCommunicationsReport(communicationsReport);
    openModal();
  };

  const closeCommunicationsReportModal = (updateTable?: boolean) => {
    setSelectedCommunicationsReport(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <FlexColumn classNames={`${classes.flexFill} ${css({ overflow: 'hidden' })}`}>
      {!validCommsDateRange && <EmptyState title="Selecciona un rango de fechas válido para generar el reporte" />}
      {validCommsDateRange && (
        <>
          {!communicationsReports && loading && <Loading />}
          {communicationsReports && (
            <>
              {!!communicationsReports?.length && (
                <StyledPaginatedTable
                  columns={COMMS_REPORT_TABLE_COLUMNS_NAME}
                  data={communicationsReports.map((communicationsReport, i) => {
                    return {
                      items: [
                        <EllipsedText key={`comm-ident-${i}`}>
                          {communicationsReport.asset.device?.ident ?? '-'}
                        </EllipsedText>,
                        communicationsReport.deviceModel ? communicationsReport.deviceModel : '-',
                        communicationsReport.positions_count,
                        formatDateFromTs(+communicationsReport.lastTimestamp),
                        communicationsReport.asset.client?.name ?? '-',
                        communicationsReport.asset.subClient?.name ?? '-',
                        <StyledTooltip
                          key={`ws-action-${i}`}
                          content={'Ver detalles'}
                          showArrow={false}
                        >
                          <Button
                            size="compact"
                            shape="square"
                            onClick={() => openCommunicationsReportModal(communicationsReport)}
                            kind="tertiary"
                          >
                            <MaterialIcon name={'visibility'} />
                          </Button>
                        </StyledTooltip>,
                      ],
                    };
                  })}
                  totalItems={totalItems}
                  page={page}
                  onPageChange={setPage}
                  loading={loading}
                />
              )}
              {!communicationsReports.length && (
                <EmptyState
                  title="No se encontraron resultados"
                  description="Intenta con otra busqueda"
                />
              )}
              {selectedCommunicationsReport && showModal &&(
                <CommsReportDetailModal
                  onClose={(updateTable) => closeCommunicationsReportModal(updateTable)}
                  isOpen={showModal}
                  communicationsReport={selectedCommunicationsReport}
                />
              )}
            </>
          )}
        </>
      )}
    </FlexColumn>
  );
}
