import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { StyledDivider } from 'baseui/divider';
import { StatefulPopover } from 'baseui/popover';
import { LabelSmall } from 'baseui/typography';
import { useRecoilState } from 'recoil';
import { ROAD_MAP_TYPE, SATELLITE_MAP_TYPE } from '../../constants/map';
import { useStyles } from '../../hooks/useStyles';
import { mapTypeState, showTrafficLayerState } from '../../storage/map';
import { MaterialIcon } from '../ui/MaterialIcon';

export function MapOptions() {
  const { theme, css } = useStyles();
  const [mapType, setMapType] = useRecoilState(mapTypeState);
  const [showTrafficLayer, setShowTrafficLayer] = useRecoilState(showTrafficLayerState);

  const getSelectedMapType = () => {
    if (mapType === ROAD_MAP_TYPE) {
      return 0;
    }
    if (mapType === SATELLITE_MAP_TYPE) {
      return 1;
    }
  };

  const getTrafficLayers = () => {
    const selectedLayers = [];
    if (showTrafficLayer) selectedLayers.push(0);
    else selectedLayers.push(1);
    return selectedLayers;
  };

  const onTrafficLayerChange = (_event: any, index: number) => {
    if (index === 0) {
      setShowTrafficLayer(true);
    } else if (index === 1) {
      setShowTrafficLayer(false);
    }
  };

  const onMapTypeChange = (_event: any, index: number) => {
    if (index === 0) {
      setMapType(ROAD_MAP_TYPE);
    } else if (index === 1) {
      setMapType(SATELLITE_MAP_TYPE);
    }
  };

  return (
    <StatefulPopover
      content={({ close }) => (
        <Block padding={theme.sizing.scale600}>
          <LabelSmall marginBottom={theme.sizing.scale300}>Tipo de mapa</LabelSmall>
          <ButtonGroup
            mode={'radio'}
            selected={getSelectedMapType()}
            size="compact"
            onClick={onMapTypeChange}
          >
            <Button>Road map</Button>
            <Button>Satellite</Button>
          </ButtonGroup>
          <StyledDivider
            $size="cell"
            $style={{ marginTop: theme.sizing.scale600, marginBottom: theme.sizing.scale600 }}
          />
          <LabelSmall marginBottom={theme.sizing.scale300}>Tráfico</LabelSmall>
          <ButtonGroup
            mode={'radio'}
            selected={getTrafficLayers()}
            size="compact"
            onClick={onTrafficLayerChange}
          >
            <Button>Mostrar</Button>
            <Button>Ocultar</Button>
          </ButtonGroup>
        </Block>
      )}
      placement={'topRight'}
      triggerType={'click'}
      overrides={{
        Inner: {
          style: {
            backgroundColor: theme.colors.backgroundPrimary,
          },
        },
      }}
    >
      <span
        className={css({
          position: 'absolute',
          bottom: theme.sizing.scale500,
          right: theme.sizing.scale500,
          background: theme.colors.backgroundPrimary,
          boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
          borderRadius: theme.borders.buttonBorderRadius,
        })}
      >
        <Button
          shape="square"
          kind="tertiary"
        >
          <MaterialIcon name="layers" />
        </Button>
      </span>
    </StatefulPopover>
  );
}
