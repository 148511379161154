export interface JobSchedule {
  appointmentDate: string;
  jobTypeId: string;
  userId: number;
  name?: string;
  description?: string;
  expenses?: number;
}

export interface Worker {
  full_name: string;
  user_id: number;
}

export enum JobTypeCategory {
  installation = 1,
  uninstall,
  revision,
}
