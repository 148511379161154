import { ApiError } from '../types/apiResponseError';
import { AppPermission } from '../types/appPermission';
import { getRequest } from './api';
import { URLS } from './urls/urls';

export const getAppPermissions = async (): Promise<{ appPermissions?: AppPermission; error?: ApiError }> => {
  const response = await getRequest(URLS.permissions.all);

  return {
    appPermissions: response?.data?.permissions,
    error: response?.error,
  };
};

