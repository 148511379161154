import { WS_TYPE } from '../../constants/webService';
import { useMoment } from '../../hooks/useMoment';
import { WebServiceLog } from '../../types/webServiceLogs';
import { CodeBlock } from '../shared/CodeBlock';
import { LabeledIconData } from '../ui/LabeledIconData';

type Props = {
  webService: WebServiceLog;
};
export function WebServiceLogInfo({ webService }: Readonly<Props>) {
  const { formatDateFromTs } = useMoment();
  return (
    <>
      <LabeledIconData
        labelWidth="80px"
        label="Tipo:"
        data={WS_TYPE[webService.wsType]}
        icon="article"
      />
      <CodeBlock
        label="Activo:"
        code={webService.asset}
      />
      <CodeBlock
        label="Data:"
        code={webService.data}
      />
      <CodeBlock
        label="Response:"
        code={webService.response}
      />
      <LabeledIconData
        labelWidth="190px"
        label="Fecha de creación:"
        data={formatDateFromTs(+webService.created)}
        icon="calendar_clock"
      />
    </>
  );
}
