import { Button } from 'baseui/button';
import { FlexRow } from '../ui/FlexRow';
import { MaterialIcon } from '../ui/MaterialIcon';
import { StyledSearchBar } from '../ui/StyledSearchBar';
import { StyledTooltip } from '../ui/StyledTooltip';
import { useStyles } from '../../hooks/useStyles';
import { BrandSelect } from '../shared/selects/BrandSelect';
import { SubBrandSelect } from '../shared/selects/SubBrandSelect';
import { StyledButton } from '../ui/StyledButton';
import VersionDetailModal from './modal/VersionDetailModal';
import { useModal } from '../../hooks/useModal';

type Props = {
  search?: string;
  onChangeSearch: (search?: string) => void;
  onReloadPage: () => void;
  selectedBrandId?: string;
  setSelectedBrandId: (brandId?: string) => void;
  selectedSubBrandId?: string;
  setSelectedSubBrandId: (brandId?: string) => void;
};
export function VersionsFiltersBar({
  search,
  onChangeSearch,
  onReloadPage,
  selectedBrandId,
  setSelectedBrandId,
  selectedSubBrandId,
  setSelectedSubBrandId,
}: Props) {
  const { css, theme } = useStyles();
  const { openModal, closeModal, showModal } = useModal();

  const resetFilters = () => {
    onChangeSearch(undefined);
    setSelectedBrandId(undefined);
  };

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '50%',
        })}
      >
        <StyledSearchBar
          value={search ?? ''}
          onChange={onChangeSearch}
          clearable
        />
        <BrandSelect
          selectedBrandId={selectedBrandId}
          onChangeBrandId={setSelectedBrandId}
        />
        <SubBrandSelect
          selectedBrandId={selectedBrandId}
          selectedSubBrandId={selectedSubBrandId}
          onChangeSubBrand={setSelectedSubBrandId}
          disabled={!selectedBrandId}
        />
        <StyledTooltip
          content={'Restaurar filtros'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={resetFilters}
            kind="tertiary"
          >
            <MaterialIcon name={'autorenew'} />
          </Button>
        </StyledTooltip>
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
        <StyledButton
          onClick={openModal}
          startEnhancer={
            <MaterialIcon
              name="add"
              size="xs-mini"
              color={theme.colors.contentInversePrimary}
            />
          }
        >
          Nueva
        </StyledButton>
      </FlexRow>
      {showModal && <VersionDetailModal
        onClose={(updateTable) => {
          if (updateTable) {
            onReloadPage();
          }
          closeModal();
        }}
        isOpen={showModal}
      />}
    </FlexRow>
  );
}
