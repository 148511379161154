import { PaginatedColumn } from '../types/app';
import { BulkEmpty } from './../types/bulk';
export const BULK_PENDING_STATUS = 1;
export const BULK_ERROR_STATUS = 2;
export const BULK_VALID_STATUS = 3;
export const BULK_PARTIALLY_PROCESSED_STATUS = 4;
export const BULK_PROCESSED_STATUS = 5;

export const BULK_STATUSES = {
  [BULK_PENDING_STATUS]: 'PENDIENTE',
  [BULK_ERROR_STATUS]: 'ERROR EN PRE PROCESO',
  [BULK_VALID_STATUS]: 'ARCHIVO VÁLIDO',
  [BULK_PARTIALLY_PROCESSED_STATUS]: 'ARCHIVO PARCIALMENTE PROCESADO',
  [BULK_PROCESSED_STATUS]: 'ARCHIVO COMPLETAMENTE PROCESADO',
};

export const BULK_LINK_DEVICE_ASSETS_FILE = 'linkDeviceAssets';
export const BULK_ASSETS_FILE = 'assets';
export const BULK_DEVICES_FILE = 'devices';
export const BULK_DISABLE_MONITORING_FILE = 'disableMonitoring';

export const BULK_FILE_TYPES = {
  [BULK_LINK_DEVICE_ASSETS_FILE]: 'Vincular Equipo con Activos',
  [BULK_ASSETS_FILE]: 'Activos',
  [BULK_DEVICES_FILE]: 'Equipos',
  [BULK_DISABLE_MONITORING_FILE]: 'Deshabilitar monitoreo',
};

export const BULK_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Tipo de Archivo',
    key: 'fileType',
  },
  {
    label: 'Creado',
    key: 'created',
  },
  {
    label: 'Errores',
    key: 'errors',
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];

export const BULK_LINK_DEVICE_ASSETS_TABLE_COLUMNS = [
  {
    label: 'Etiqueta',
    key: 'label',
  },
  {
    label: 'Identificador',
    key: 'identifier',
  },
  {
    label: 'Error',
    key: 'error',
  },
];
export const BULK_VEHICLE_ASSETS_TABLE_COLUMNS = [
  {
    label: 'Placa',
    key: 'licensePlate',
  },
  {
    label: 'Etiqueta',
    key: 'label',
  },
  {
    label: 'Marca',
    key: 'brand',
  },
  {
    label: 'Submarca',
    key: 'subBrand',
  },
  {
    label: 'Año',
    key: 'year',
  },
  {
    label: 'Color',
    key: 'color',
  },
  {
    label: 'Versión',
    key: 'version',
  },
  {
    label: 'Vin',
    key: 'vin',
  },
  {
    label: 'Tipo de vehículo',
    key: 'vehicleType',
  },
  {
    label: 'Error',
    key: 'error',
  },
];
export const BULK_CONTAINER_ASSETS_TABLE_COLUMNS = [
  {
    label: 'Identificador',
    key: 'identifier',
  },
  {
    label: 'Etiqueta',
    key: 'label',
  },
  {
    label: 'Ident',
    key: 'ident',
  },
  {
    label: 'Error',
    key: 'error',
  },
];
export const BULK_DEVICES_TABLE_COLUMNS = [
  {
    label: 'Ident',
    key: 'ident',
  },
  {
    label: 'Imei',
    key: 'imei',
  },
  {
    label: 'Sim',
    key: 'sim',
  },
  {
    label: 'Carrier',
    key: 'carrier',
  },
  {
    label: 'Error',
    key: 'error',
  },
];
export const BULK_DISABLE_MONITORING_TABLE_COLUMNS = [
  {
    label: 'Ident',
    key: 'ident',
  },
  {
    label: 'Error',
    key: 'error',
  },
];

export const BULK_FILE_EXAMPLES = [
  {
    label: 'Vincular Equipo con Activos',
    url: 'bulkExamples/link-assets-devices.csv'
  },
  {
    label: 'Activos Vehículos',
    url: 'bulkExamples/assets-vehicles.csv',
  },
  {
    label: 'Activos Contenedores',
    url: 'bulkExamples/assets-containers.csv',
  },
  {
    label: 'Equipos',
    url: 'bulkExamples/equipments.csv',
  },
  {
    label: 'Deshabilitar monitoreo',
    url: 'bulkExamples/monitoring.csv',
  },
];

export const EMPTY_BULK: BulkEmpty = {
  fileType: undefined,
  client: undefined,
  subClient: undefined,
  device: undefined,
  asset: undefined,
  file: undefined,
  assetType: undefined,
  brand: undefined,
};
